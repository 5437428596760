import React, { useEffect, useState } from "react";
import "./SideBarTabContent.scss";
import JobDetails from "./JobDetails/JobDetails";
import AssessmentsDetails from "./Assessments/AssessmentsDetails";
import TabsFilter from "./TabsFilter/TabsFilter";
import { useTranslation } from "react-i18next";
import UploadFilePopup from "../../../UploadFilePopup/UploadFilePopup";
import {
  companyGetJobDetails,
  cvList,
  getSourcesList,
  jobAssessmentList,
  refreshSystemCVs,
  scanCVs,
  systemCVRefreshStatus,
  uploadedCVCount,
} from "../../../../../service/CvScanning/CvScanning";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DetailsPageWrapper from "../DetailsPageWrapper";
import { AssessmentButton, showToast } from "ui-components";
import {
  capitalizeFirstLetter,
  foundUniqueId,
} from "../../../../../utils/Common/Constant";
import CvJobDetailsIcon from "../../../../../utils/Images/CvJobDetailsIcon";
import CvAssessmentTabIcon from "../../../../../utils/Images/CvAssessmentTabIcon";
import CvSourceIcon from "../../../../../utils/Images/CvSourceIcon";
// import CVSidebarAddMore from "../../../../../utils/Images/CVSidebarAddMore";
import CvIcon from "../../../../../utils/Images/CvIcon";
// import Invited from "./TabsFilter/Invited/Invited";
import SourceList from "./TabsFilter/Sourcing/SourceList/SourceList";
import useDebounce from "../../../../../utils/Hooks/useDebounce";
import { setSessionStorageItem } from "../../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { getSessionStorageItem } from "../../../../../utils/SessionStorageMethod/getSessionStorageItem";
// import Sourcing from "./TabsFilter/Sourcing/Sourcing";

type FileProgress = {
  file: File;
  progress: number;
  status: "uploading" | "completed" | "failed";
};

const SideBarTabContent: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { jobId }: any = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [cvScanData, setCvScanData] = useState([]);
  const [fileProgressList, setFileProgressList] = useState<FileProgress[]>([]);
  const [activeAssessment, setActiveAssessment] = useState<string | null>(null);
  const [activeAssessmentData, setActiveAssessmentData] = useState<any>({});
  const [assessmentDetailsData, setAssessmentDetailsData] = useState([]);
  const [isConfirmScanCvs, setIsConfirmScanCvs] = useState(false);
  const [jobDetailsData, setJobDetailsData] = useState<any>({});
  const [matchedSkills, setMatchedSkills] = useState<any>([]);
  const [searchCv, setSearchCv] = useState(searchParams.get("search") || "");
  const searchCvDelay = useDebounce(searchCv.trim(), 700);
  const [currentPage, setCurrentPage] = useState(1);
  const [cvListData, setCvListData] = useState([]);
  const [totalCvListData, setTotalCvListData] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [jobDetailsTab, setJobDetailsTab] = useState<string>(
    searchParams.get("at") || "JobDetails",
  );
  const [selectedSourceType, setSelectedSourceType] = useState<string>(
    searchParams.get("filter") || "All",
  );
  const [isfilterColumns, setIsFilterColumns] = useState<any>(
    searchParams.get("sort") || "overAll",
  );
  const [isDirectionArrow, setIsDirectionArrow] = useState<any>(
    searchParams.get("order") || "desc",
  );
  const [cvstabs, setCvsTabs] = useState<any>([]);
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sourceType, setSourceType] = useState<any>([]);
  const [referenceSourceType, setReferenceSourceType] = useState<any>([]);
  const [databaSesourceRefreshing, setDatabaseSourceRefreshing] =
    useState(false);
  const assessmentDetailsFuncData = async (jobId: string) => {
    const assessRes = await jobAssessmentList(jobId);
    if (assessRes?.status === 200) {
      setAssessmentDetailsData(assessRes?.data);
    }
  };

  // Handle URL updates when filters or tabs change
  useEffect(() => {
    // Tabs where sourceType should be added
    const tabsWithSourceType = ["scannedcv", "unscannedcv", "invited"];
    const params: Record<string, string> = { at: jobDetailsTab };

    if (tabsWithSourceType.includes(jobDetailsTab)) {
      params.search = searchCvDelay;
    }
    // Add sourceType only for specific tabs
    if (tabsWithSourceType.includes(jobDetailsTab)) {
      params.filter = selectedSourceType;
    }
    // Add sourceType only for specific tabs
    if (tabsWithSourceType.includes(jobDetailsTab)) {
      const updateFilterColumns =
        jobDetailsTab === "unscannedcv" ? "uploadedDate" : isfilterColumns;
      params.sort = updateFilterColumns;
    }
    if (tabsWithSourceType.includes(jobDetailsTab)) {
      params.order = isDirectionArrow;
    }

    setSearchParams(params);
  }, [
    jobDetailsTab,
    selectedSourceType,
    isfilterColumns,
    isDirectionArrow,
    searchCvDelay,
  ]);

  const getCVCounts = async () => {
    const updateCountRes = await uploadedCVCount(jobId);
    if (updateCountRes?.status === 200) {
      setCvsTabs([
        {
          label: t("RESUME.ASSESSMENT_UNSCANNED_CVS"),
          value: "unscannedcv",
          count: updateCountRes?.data?.unscannedCVCount,
        },
        {
          label: t("RESUME.ASSESSMENT_SCANNED_CVS"),
          value: "scannedcv",
          count: updateCountRes?.data?.scannedCVCount,
        },
        {
          label: t("GENERAL.INVITED"),
          value: "invited",
          count: updateCountRes?.data?.invitedCVCount,
        },
      ]);
    }
  };
  // Initialize filters from URL when component loads
  useEffect(() => {
    const sourceTypeFromUrl = searchParams.get("filter");
    const searchFromUrl = searchParams.get("search");
    if (sourceTypeFromUrl) {
      setSelectedSourceType(sourceTypeFromUrl);
    }
    if (searchFromUrl) {
      setSearchCv(searchFromUrl);
    }
  }, [searchParams]);

  useEffect(() => {
    const getJobDetails = async () => {
      const jobDetailsRes = await companyGetJobDetails(jobId);
      if (jobDetailsRes?.status === 200) {
        setJobDetailsData(jobDetailsRes?.data);
        assessmentDetailsFuncData(jobDetailsRes?.data?.jobId);

        const updatedSkills = jobDetailsRes?.data?.jobSkills
          .filter((skill: any) => skill.skillId !== null)
          .map((skill: any, ind: number) => ({
            ...skill,
            questionType: "Video",
            totalQuestions: 2,
            questionNo: 2,
            difficultyLevels: "Intermediate",
            type: "template",
            selectedDifficultyLevels: { Intermediate: 2 },
            questionCount: 2,
            id: Math.random() + ind,
          }));
        setMatchedSkills(updatedSkills);
      }
      getCVCounts();
    };
    getJobDetails();
    handleSystemCvRefresh();
    getSourcesListApi();
  }, []);
  // get source list
  const getSourcesListApi = async () => {
    try {
      if (!jobId) {
        console.error("jobId is required to fetch the sources list.");
        return;
      }
      const res = await getSourcesList(jobId);
      const defaultData = [
        { label: "All", value: "All", sourceId: "" },
        { label: "Database", value: "Database", sourceId: "" },
        { label: "Job Link", value: "JobLink", sourceId: "" },
      ];

      const updatedData = [
        ...defaultData,
        ...(Array.isArray(res.data.data)
          ? res.data.data.map((item: any) => ({
              label: item?.name,
              value: item?.jobIntegrationId,
              sourceId: item?.jobIntegrationId,
            }))
          : []),
      ];
      setSessionStorageItem("sourceList", res?.data?.data);
      setReferenceSourceType(res?.data?.data ?? []);
      setSourceType(updatedData);
    } catch (error) {
      console.error("Error fetching sources list:", error);
    }
  };
  // Get the system cv refresh status
  const handleSystemCvRefresh = async () => {
    const systemCvRefreshRes = await systemCVRefreshStatus(jobId);
    if (systemCvRefreshRes?.status === 200) {
      setRefreshRequired(systemCvRefreshRes?.data?.refreshRequired);
    }
  };
  // to get refreshSystemCVs
  const handleRefreshSystemCVs = async () => {
    setDatabaseSourceRefreshing(true);
    const cvRefreshRes = await refreshSystemCVs(jobId);
    if (cvRefreshRes?.status === 200) {
      setRefreshRequired(false);
      setDatabaseSourceRefreshing(false);
      cvListFunData();
      getCVCounts();
      showToast(cvRefreshRes?.customMessage, "success");
    } else {
      setDatabaseSourceRefreshing(false);
    }
  };
  // file uploading data function
  const handleFilesUploaded = async (files: any, fileNames: any) => {
    const result = files?.map((item: any, index: number) => ({
      fileName: fileNames[index]?.name,
      fileType: fileNames[index]?.name?.split(".")[1],
      fileSize: fileNames[index]?.size,
      filePath: item?.fileName || "",
    }));
    const fileRes = await scanCVs({
      jobId: jobId,
      CVs: result,
    });
    if (fileRes?.status === 200) {
      setCvScanData(fileRes?.data);
      setFileProgressList([]);
    }
  };
  // function for list of cvs
  const cvListFunData = async () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds a smooth scroll effect
    });
    const getSourceListFromSession = getSessionStorageItem("sourceList") ?? [];
    let updateSourceList: any = [];
    if (getSourceListFromSession?.length) {
      updateSourceList = getSourceListFromSession?.filter(
        (item: any) => item?.jobIntegrationId === selectedSourceType,
      );
    } else {
      const res = await getSourcesList(jobId);
      updateSourceList = res?.data?.data?.filter(
        (item: any) => item?.jobIntegrationId === selectedSourceType,
      );
      setSessionStorageItem("sourceList", res?.data?.data ?? []);
    }

    const sourceType = updateSourceList?.length
      ? capitalizeFirstLetter(updateSourceList[0]?.type)
      : capitalizeFirstLetter(selectedSourceType);
    const sourceId = updateSourceList[0]?.jobIntegrationId;
    const filterColumns =
      jobDetailsTab === "unscannedcv" ? "uploadedDate" : isfilterColumns;
    const isScanned = jobDetailsTab === "scannedcv" ? true : false;
    const isInvited = jobDetailsTab === "invited" ? true : false;
    const cvListRes = await cvList(
      jobId,
      filterColumns,
      isDirectionArrow,
      searchCvDelay,
      currentPage,
      pageSize,
      sourceType,
      isScanned,
      isInvited,
      sourceId,
    );
    if (cvListRes?.status === 200) {
      if (jobDetailsTab === "scannedcv" || jobDetailsTab === "unscannedcv") {
        const updateData = cvListRes?.data?.data?.filter((item: any) => {
          return item?.isInvited !== true;
        });
        setCvListData(updateData);
      } else {
        setCvListData(cvListRes?.data?.data);
      }

      setTotalCvListData(cvListRes?.data?.totalCount);
    }
  };

  const renderContent = (activeTabs?: string) => {
    if (
      jobDetailsTab === "Assessments" &&
      assessmentDetailsData?.length === 0
    ) {
      return (
        <div className="assessmentNotAvailable">
          <AssessmentButton
            onClick={() => {
              navigate(
                `/${currentLanguage}/assessment/build/${foundUniqueId()}?jobId=${jobId}`,
                {
                  state: {
                    jobSkillData: matchedSkills,
                    jobTitle: jobDetailsData?.title,
                  },
                },
              );
            }}
          >
            {t("CREATE_ASSESSMENT.TITLE")}
          </AssessmentButton>
        </div>
      );
    }

    if (activeAssessment) {
      return (
        <AssessmentsDetails
          jobId={jobId}
          assessmentData={activeAssessmentData}
        />
      );
    }

    switch (activeTabs) {
      case "scannedcv":
      case "unscannedcv":
      case "invited":
        return (
          <TabsFilter
            tabActive={jobDetailsTab}
            jobId={jobId}
            handleFilesUploaded={handleFilesUploaded}
            fileUploadData={cvScanData}
            setFileProgressList={setFileProgressList}
            fileProgressList={fileProgressList}
            isConfirmScanCvs={isConfirmScanCvs}
            isAssessmentAvailable={assessmentDetailsData}
            selectedSourceType={selectedSourceType}
            setSelectedSourceType={setSelectedSourceType}
            isfilterColumns={isfilterColumns}
            setIsFilterColumns={setIsFilterColumns}
            isDirectionArrow={isDirectionArrow}
            setIsDirectionArrow={setIsDirectionArrow}
            setSearchCv={setSearchCv}
            searchCv={searchCv}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setCvsTabs={setCvsTabs}
            searchCvDelay={searchCvDelay}
            cvListData={cvListData}
            totalCvListData={totalCvListData}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setLoading={setLoading}
            loading={loading}
            setSourceType={setSourceType}
            sourceType={sourceType}
            cvListFunData={cvListFunData}
            referenceSourceType={referenceSourceType}
            updateCountFunc={getCVCounts}
          />
        );
      case "JobDetails":
        return <JobDetails jobData={jobDetailsData} />;
      case "source":
        return (
          <SourceList
            handleRefreshSystemCVs={handleRefreshSystemCVs}
            refreshRequired={refreshRequired}
            databaSesourceRefreshing={databaSesourceRefreshing}
          />
        );
      case "Assessments":
        return <AssessmentsDetails jobId={jobId} />;
      default:
        return null;
    }
  };
  return (
    <DetailsPageWrapper
      jobDetails={jobDetailsData}
      onFilesUploaded={handleFilesUploaded}
      fileUploadData={cvScanData}
      setFileProgressList={setFileProgressList}
      fileProgressList={fileProgressList}
    >
      <div className="row g-5">
        <div className="col-sm-12 col-md-3 col-lg-2 sideBarContent">
          {/* Sidebar */}
          <div className="sidebar">
            {/* job details */}
            <div
              className={`tab ${jobDetailsTab === "JobDetails" ? "active" : ""}`}
              onClick={() => {
                setJobDetailsTab("JobDetails");
                setActiveAssessment(null);
              }}
            >
              <div className="iconJobText">
                <CvJobDetailsIcon />
                <h6>{t("RESUME.SCANNING_JOB_DETAILS")}</h6>
              </div>
            </div>
            {/* assessment */}
            <div className="assessmentList">
              <div
                className={`tab ${assessmentDetailsData.length === 0 && jobDetailsTab === "Assessments" ? "active clickable" : "clickable"}`}
                onClick={() => {
                  if (assessmentDetailsData.length === 0) {
                    setJobDetailsTab("Assessments");
                    setActiveAssessment(null);
                    setActiveAssessmentData({});
                  }
                }}
              >
                <div className="iconText">
                  <CvAssessmentTabIcon />
                  <h6>{t("RESUME.SCANNING_ASSESSMENTS")}</h6>
                </div>
              </div>
              {assessmentDetailsData.length > 0 &&
                assessmentDetailsData?.map((assessment: any, ind: number) => (
                  <div
                    key={ind}
                    className={`tab ${activeAssessment === assessment?.title ? "active" : ""}`}
                    onClick={() => {
                      setActiveAssessment(assessment?.title);
                      setActiveAssessmentData(assessment);
                      setJobDetailsTab("");
                    }}
                  >
                    <div className="subAsessment">
                      <div className="assessmentTitle">
                        <h6>{assessment?.title}</h6>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {/* source */}
            <div className="assessmentList">
              <div
                className={`tab ${jobDetailsTab === "source" ? "active" : ""}`}
                onClick={() => {
                  setJobDetailsTab("source");
                  setActiveAssessment(null);
                  setActiveAssessmentData({});
                }}
              >
                <div className="iconText">
                  <CvSourceIcon />
                  <h6>{t("RESUME.SCANNING_SOURCING")}</h6>
                </div>
              </div>
            </div>
            {/* cvs  */}
            <div className="sourceMore">
              <div className="sourceSec">
                <div className="iconText">
                  <CvIcon />
                  <h6>{t("RESUME.SCANNING_CVS")}</h6>
                </div>
              </div>
            </div>
            <div className="tabs">
              {(cvstabs ?? []).map((tab: any, ind: number) => (
                <div
                  key={ind}
                  className={`tab ${jobDetailsTab === tab?.value ? "active" : ""}`}
                  onClick={() => {
                    setJobDetailsTab(tab?.value);
                    setSelectedSourceType("All");
                    setIsFilterColumns("overAll");
                    setIsDirectionArrow("desc");
                    setSearchCv("");
                    setCurrentPage(1);
                    setActiveAssessment(null);
                  }}
                >
                  <div className="subTabs">
                    <div className="sourceSubTab">
                      <h6>{`${tab?.label}`}</h6>
                      <span className="countCircle">
                        {tab?.count > 99 ? "99+" : tab?.count}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-9 col-lg-10">
          {/* Content Area */}
          <div className="content-area">{renderContent(jobDetailsTab)}</div>
        </div>
        {(cvScanData || fileProgressList) &&
        (cvScanData?.length || fileProgressList?.length) ? (
          <UploadFilePopup
            jobId={jobId}
            fileUploadData={cvScanData}
            setCvScanData={setCvScanData}
            fileProgressList={fileProgressList}
            setIsConfirmScanCvs={setIsConfirmScanCvs}
          />
        ) : null}
      </div>
    </DetailsPageWrapper>
  );
};

export default SideBarTabContent;
