import {
  ApproxTimeHead,
  AssessmentButton,
  ReviewSteps,
  TitleHead,
} from "ui-components";
import { useTranslation } from "react-i18next";
import "./Head.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { setSessionStorageItem } from "../../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { getSessionStorageItem } from "../../../../../utils/SessionStorageMethod/getSessionStorageItem";
import { AssessmentJsonContext } from "../../../../../context/JsonContext/JsonContext";
import ComponentIds from "../../../../../utils/Json/componentIds.json";
import {
  deleteAssessmentTempData,
  getCompanyPackageDetails,
  getFrontEndSystemPreference,
  getRoleCentricRender,
} from "../../../../../service/Scope/Scope";
import { Field, useFormikContext } from "formik";
import {
  finalDiscountCredit,
  findValuesForKey,
  foundUniqueId,
  isDisable,
  isHide,
} from "../../../../../utils/Common/Constant";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import CreditCoin from "../../../../../utils/Images/CreditCoin";
export interface HeadProps {
  handlePrevious: (count: number) => void;
  currentStep: number;
  errors?: any;
  touched?: any;
  handleNext?: ((data: any) => void) | undefined;
}
// counted credit based on question type
const countCreditForQuestion = (questionData: any, paymentDetails: any) => {
  let totalCredit = 0;
  questionData?.map((ele: any) => {
    if (ele?.questionType === "Video") {
      totalCredit =
        totalCredit +
        ele?.questionNo * paymentDetails?.creditForManualAnalysisVideoQuestion;
    } else if (ele?.questionType === "Coding") {
      totalCredit =
        totalCredit + ele?.questionNo * paymentDetails?.creditForCodingQuestion;
    } else if (ele?.questionType === "MCQ" || ele?.questionType === "Essay") {
      totalCredit =
        totalCredit + ele?.questionNo * paymentDetails?.creditForMCQ;
    } else if (ele?.type === "Single") {
      totalCredit = totalCredit + 1 * paymentDetails?.creditForCustomQuestion;
    } else if (ele?.type === "Multiple") {
      totalCredit = totalCredit + 1 * paymentDetails?.creditForCustomQuestion;
    } else if (ele?.type === "Essay") {
      totalCredit = totalCredit + 1 * paymentDetails?.creditForCustomQuestion;
    } else if (ele?.type === "Video") {
      totalCredit =
        totalCredit + 1 * paymentDetails?.creditForCustomVideoQuestion;
    }
  });
  const videoType = questionData
    ?.filter((ele: any) => ele?.questionType === "Video") // filter for "Video" question type
    .reduce((total: number, ele: any) => total + (ele?.questionNo || 0), 0);

  if (getSessionStorageItem("isAiEvaluation")) {
    const evaluationAiChecks = getSessionStorageItem("evaluation");
    // transform evaluation types
    const transformedData = (evaluationAiChecks ?? []).reduce(
      (acc: any, obj: any) => {
        if (obj.type === "smartAi") {
          if (!acc.smartAi) {
            acc.smartAi = { type: "smartAi", attributes: {} };
          }
          const { ...attributes } = obj; // Extract `type` and the rest as attributes
          acc.smartAi.attributes = { ...acc.smartAi.attributes, ...attributes };
        } else if (obj.type === "manual") {
          acc.manual = obj;
        }
        return acc;
      },
      {},
    );
    const transformEvaluationResult: any = Object.values(transformedData);

    // Delete the `type` attribute from `smartAi` attributes if `smartAi` exists
    if (transformEvaluationResult[0]?.type === "smartAi") {
      delete transformEvaluationResult[0].attributes.type;
    }
    if (transformEvaluationResult[0]?.attributes?.aiAnalysis) {
      totalCredit =
        totalCredit +
        videoType * paymentDetails?.creditForAIAnalysisVideoQuestion;
    }
    if (transformEvaluationResult[0]?.attributes?.audioAnalysis) {
      totalCredit =
        totalCredit +
        videoType * paymentDetails?.creditForVoiceAnalysisVideoQuestion;
    }
    if (transformEvaluationResult[0]?.attributes?.visualAnalysis) {
      totalCredit =
        totalCredit +
        videoType * paymentDetails?.creditForVisualAnalysisVideoQuestion;
    }
  }
  const updateTotalCredits = totalCredit === 1 ? 0 : totalCredit;
  return updateTotalCredits;
};

const Head: React.FunctionComponent<HeadProps> = ({
  handlePrevious,
  currentStep,
  errors = null,
  touched = null,
  handleNext = () => {},
}) => {
  const formikContext = useFormikContext();
  const { setFieldValue } = formikContext || {};
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const contentRef = useRef<HTMLDivElement>(null);
  const renderRouteName = "template";
  const [titleHeadContent, setTitleHeadContent] = useState(
    getSessionStorageItem("titleHeadContent"),
  );
  const [resetDraft, setResetDraft] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [countQuestion, setCountQuestion] = useState<number>(
    getSessionStorageItem("totalQuestionCount"),
  );

  const [totalTime, setTotalTime] = useState<number>(
    getSessionStorageItem("totalQuestionTime"),
  );
  const [countedCredit, setCountedCredit] = useState(
    getSessionStorageItem("totalCountedCredit") || 0,
  );
  const [countedCreditAfterDiscount, setCountedCreditAfterDiscoun] = useState(
    getSessionStorageItem("totalCountedCreditAfterDiscount") || 0,
  );

  const { state, dispatch } = useContext(AssessmentJsonContext) ?? {
    state: { renderJson: {} },
    dispatch: () => {},
  };

  const reviewStepData = [
    { stepCount: 1, stepLabel: t("GENERAL.TEMPLATE") },
    { stepCount: 2, stepLabel: t("GENERAL.BUILD") },
    { stepCount: 3, stepLabel: t("GENERAL.CUSTOM") },
    { stepCount: 4, stepLabel: t("GENERAL.SETTINGS") },
    { stepCount: 5, stepLabel: t("GENERAL.REVIEW") },
  ];

  useEffect(() => {
    (async () => {
      const companyId = localStorage.getItem("companyId");
      const branchId = localStorage.getItem("branchId");
      const data: any = await getCompanyPackageDetails(companyId, branchId);
      setSessionStorageItem("paymentData", data);
      const getJsonData = await getRoleCentricRender(renderRouteName);
      dispatch({
        type: "UPDATE_CENTRIC_RENDER_JSON",
        payload: getJsonData,
      });
    })();
  }, [resetDraft]);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const data: any = await getFrontEndSystemPreference();
        setSessionStorageItem("systemPreference", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [resetDraft]);

  useEffect(() => {
    const handleScroll = () => {
      const stickyOffset = -160;
      if (
        contentRef.current &&
        contentRef?.current?.getBoundingClientRect().top <= stickyOffset
      ) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      // observer.disconnect(); // Disconnect the observer on unmount
    };
  }, []);

  // Run this effect whenever the session storage is updated
  useEffect(() => {
    // setType(getSessionStorageItem("Type") ?? "Hiring");
    setTitleHeadContent(getSessionStorageItem("titleHeadContent"));
    if (setFieldValue) {
      setFieldValue("type", getSessionStorageItem("Type") || "");
      setFieldValue(
        "titleHeadContent",
        getSessionStorageItem("titleHeadContent") || "",
      );
    }
  }, [
    getSessionStorageItem("Type"),
    getSessionStorageItem("titleHeadContent"),
  ]);

  // Your existing code...
  useEffect(() => {
    const intervalId = setInterval(() => {
      const {
        countQuestion,
        totalTime,
        totalCredit,
        totalCreditAfterDiscount,
      } = totalQuestionCount();
      setCountQuestion(countQuestion);
      setTotalTime(totalTime);
      setCountedCredit(totalCredit);
      setCountedCreditAfterDiscoun(totalCreditAfterDiscount);
    }, 100);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  const titleHeadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const capitalizedValue =
      inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    setTitleHeadContent(capitalizedValue);
    setSessionStorageItem("titleHeadContent", capitalizedValue.trim() ?? "");

    if (setFieldValue) {
      setFieldValue("titleHeadContent", capitalizedValue);
    }
  };
  // count number of question in our assessment
  const totalQuestionCount = () => {
    const totalQuestion = (assessmentSkills: any) => {
      if (assessmentSkills[0]?.questionNo) {
        const totalQuestions = assessmentSkills.reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + currentValue?.questionNo,
          0,
        );
        return totalQuestions;
      } else {
        const totalQuestions = assessmentSkills.reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + currentValue?.questionsCount,
          0,
        );
        return totalQuestions;
      }
    };
    // calculate time for mcq questions
    const totalTimeForMCQ = (assessmentSkills: any) => {
      const systemPreferenceQuestionTime =
        getSessionStorageItem("systemPreference");
      let totalTime: number = 0;
      assessmentSkills?.map((item: any) => {
        if (item?.questionType === "Video") {
          totalTime =
            totalTime +
            systemPreferenceQuestionTime?.questionTime?.Video *
              item?.questionNo;
        } else if (item?.questionType === "MCQ") {
          totalTime =
            totalTime +
            systemPreferenceQuestionTime?.questionTime?.MCQ * item?.questionNo;
        } else if (item?.questionType === "Essay") {
          totalTime =
            totalTime +
            systemPreferenceQuestionTime?.questionTime?.Essay *
              item?.questionNo;
        } else if (item?.questionType === "Coding") {
          totalTime =
            totalTime +
            systemPreferenceQuestionTime?.questionTime?.Coding *
              item?.questionNo;
        }
      });
      return totalTime;
    };
    let mergeData: any = [];
    const skill = getSessionStorageItem("ownPreview") ?? [];
    const existing =
      getSessionStorageItem("existingPreview")?.subjectFieldSet ?? [];
    if (skill?.length) {
      mergeData = [...skill];
    } else {
      mergeData = [...existing];
    }
    const customQuestionSet = getSessionStorageItem("createQuestionData");
    const customQuestionTime = customQuestionSet?.reduce(
      (accumulator: any, currentValue: any) =>
        accumulator + currentValue?.timeLimit,
      0,
    );
    const countCustomQuestion =
      getSessionStorageItem("createQuestionData")?.length ?? 0;
    const countSkill = totalQuestion(mergeData) ?? 0;
    totalTimeForMCQ(mergeData);

    const countedStorageData = countCreditForQuestion(
      [...mergeData, ...(getSessionStorageItem("createQuestionData") ?? [])],
      getSessionStorageItem("paymentData")?.packageDetails ?? {},
    );
    setSessionStorageItem(
      "totalQuestionCount",
      countSkill + countCustomQuestion,
    );
    {
      /* // Comment for future use  */
    }
    // const isMaximumResponse =
    //   getSessionStorageItem("maximumResponse") === null ||
    //     getSessionStorageItem("maximumResponse") === 0
    //     ? 1
    //     : getSessionStorageItem("maximumResponse");
    // setSessionStorageItem(
    //   "totalCountedCredit",
    //   countedStorageData * isMaximumResponse,
    // );
    setSessionStorageItem("totalCountedCredit", countedStorageData);
    setSessionStorageItem(
      "totalCountedCreditAfterDiscount",
      finalDiscountCredit(),
    );
    const totalQuestionTimeForMCQ = totalTimeForMCQ(mergeData);
    setSessionStorageItem(
      "totalQuestionTime",
      totalQuestionTimeForMCQ +
        (isNaN(customQuestionTime) ? 0 : customQuestionTime),
    );
    return {
      countQuestion: countSkill + countCustomQuestion,
      totalTime:
        totalQuestionTimeForMCQ +
        (isNaN(customQuestionTime) ? 0 : customQuestionTime),
      // totalCredit: countedStorageData * isMaximumResponse,
      totalCredit: countedStorageData,
      totalCreditAfterDiscount: finalDiscountCredit(),
    };
  };
  const handleCreateNewAssessment = async () => {
    const deleteData = await deleteAssessmentTempData(
      localStorage.getItem("companyId") ?? "",
      localStorage.getItem("tempUniqueId") ?? "",
    );
    setResetDraft(false);
    if (deleteData?.data?.status === 200) {
      sessionStorage.clear();
      localStorage.removeItem("tempUniqueId");
      navigate(`/${currentLanguage}/assessment/template/${foundUniqueId()}`);
    } else {
      sessionStorage.clear();
      localStorage.removeItem("tempUniqueId");
      navigate(`/${currentLanguage}/assessment/template/${foundUniqueId()}`);
    }
  };
  return (
    <div className="headContentSec" ref={contentRef}>
      <div className="assessmentHeading">
        <h2>{t("CREATE_ASSESSMENT.TITLE")}</h2>
        <AssessmentButton onClick={() => setResetDraft(true)}>
          {t("CREATE_ASSESSMENT.RESET")}
        </AssessmentButton>
      </div>
      <div className="saveDraftSection">
        {isHide(
          findValuesForKey(
            state?.renderJson,
            renderRouteName,
            ComponentIds.scope.typeSelectKey,
          ),
        ) && (
          <>
            <Field name="type">
              {() => (
                <div className="assessmentHiring">
                  {/* <h2>{t("GENERAL.HIRING")}</h2> */}
                </div>
              )}
            </Field>
            {errors?.type && touched?.type ? (
              <div className="errorMessage">{errors?.type}</div>
            ) : null}
          </>
        )}
        <table className="assessmenDuration">
          {/* for total question */}
          <tr>
            <td>{t("GENERAL.TOTAL_QUESTION")}</td>
            <td>&nbsp;:&nbsp;</td>
            {isHide(
              findValuesForKey(
                state?.renderJson,
                renderRouteName,
                ComponentIds.scope.deleteBtnKey,
              ),
            ) && (
              <td className="timeValue">
                {isNaN(countQuestion) ? 0 : countQuestion}
              </td>
            )}
          </tr>
          {/* for total assessment time */}
          <tr>
            <td>{t("CREATE_ASSESSMENT.DURATION")}</td>
            <td>&nbsp;:&nbsp;</td>
            {isHide(
              findValuesForKey(
                state?.renderJson,
                renderRouteName,
                ComponentIds.scope.deleteBtnKey,
              ),
            ) && (
              <td
                className="timeValue"
                style={{
                  color: totalTime >= 30 ? "" : "var(--red)",
                }}
              >
                {`${isNaN(totalTime) ? 0 : totalTime}`} {t("GENERAL.MIN(S)")}
              </td>
            )}
          </tr>
        </table>
      </div>
      <div className={`${isSticky && "fixedHeader"}`}>
        <div className={isSticky ? "sticky" : ""}>
          <div className="headTitleSec">
            {isHide(
              findValuesForKey(
                state?.renderJson,
                renderRouteName,
                ComponentIds.scope.assessmentTitleKey,
              ),
            ) && (
              <>
                <TitleHead
                  disable={isDisable(
                    findValuesForKey(
                      state?.renderJson,
                      renderRouteName,
                      ComponentIds.scope.assessmentTitleKey,
                    ),
                  )}
                  headTitle={titleHeadContent}
                  titleHeadChange={titleHeadChange}
                  placeHolder={t("ASSESSMENT_TITLE_PLACEHOLDER")}
                />
                {errors?.titleHeadContent && touched?.titleHeadContent ? (
                  <div className="errorMessage">{errors?.titleHeadContent}</div>
                ) : null}
              </>
            )}
            <div className="d-flex gap-2 stickyTabs">
              <div
                className="stickyTimeAdd"
                title={`${t("GENERAL.TOTAL_QUESTION")} : ${
                  isNaN(countQuestion) ? 0 : countQuestion
                }`}
              >
                <ApproxTimeHead
                  approxTimeLabel={t("GENERAL.TOTAL_QUESTION")}
                  approxTimeValue={`${isNaN(countQuestion) ? 0 : countQuestion}`}
                />
              </div>
              <div
                className={`stickyTimeAdd ${totalTime < 30 && "redBorder"}`}
                title={`${t("CREATE_ASSESSMENT.DURATION")} : ${
                  isNaN(totalTime) ? 0 : totalTime
                } ${t("GENERAL.MINS")}`}
              >
                <ApproxTimeHead
                  isTimerBelow={totalTime >= 30 ? false : true}
                  approxTimeLabel={t("CREATE_ASSESSMENT.DURATION")}
                  approxTimeValue={`${
                    isNaN(totalTime) ? 0 : totalTime
                  } ${t("GENERAL.MINS")}`}
                />
              </div>
              <div
                className="timeMinWid"
                title={`${t("GENERAL.CREDITS")}${
                  isNaN(countedCredit) ? 0 : countedCredit
                }`}
              >
                <ApproxTimeHead
                  approxTimeLabel={<CreditCoin width={35} height={35} />}
                  approxTimeValue={isNaN(countedCredit) ? 0 : countedCredit}
                  isCreditCoin={true}
                  discountValue={countedCreditAfterDiscount}
                />
              </div>
            </div>
          </div>
        </div>
        <ReviewSteps
          data={reviewStepData}
          handlePrevious={handlePrevious}
          currentStep={currentStep}
          handleNext={handleNext}
        />
      </div>
      {resetDraft && (
        <CustomModal
          show={resetDraft}
          isHeaderVisible={false}
          isFooterVisible={false}
          handleClose={() => setResetDraft(false)}
          isCrossBtn={true}
          className="reset-draft-Modal"
        >
          <div className="restDraft">
            <span>
              {t("CREATED_ASSESSMENT_DO_YOU_REALLY_WANT_TO_DISCARD_CHANGES")}
            </span>
            <div className="yesNoBtn">
              <AssessmentButton
                className={"yesBtn lightBtn"}
                onClick={() => handleCreateNewAssessment()}
              >
                <span>{t("GENERAL.YES")}</span>
              </AssessmentButton>
              <AssessmentButton
                className={"NoBtn lightBtn"}
                onClick={() => setResetDraft(false)}
              >
                <span>{t("GENERAL.NO")}</span>
              </AssessmentButton>
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default Head;
