import { AssessmentButton, Bullets } from "ui-components";
import "./AssessmentsDetails.scss";
import { manageDuplicateSkills } from "../../../../../../utils/Common/Constant";
import CreditCoin from "../../../../../../utils/Images/CreditCoin";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AssessmentsDetails = ({ assessmentData }: any) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <div className="assessmentDetails">
      <div className="headerBtn">
        <h3>{assessmentData?.title}</h3>
        <AssessmentButton
          onClick={() =>
            navigate(
              `/${currentLanguage}/assessment/reviewlist/${assessmentData?.assessmentId}`,
            )
          }
          className="lightBtn"
        >
          {t("RESUME.ASSESSMENT_VIEW")}
        </AssessmentButton>
      </div>
      <div className="skillData"></div>
      <div className="bulletSkill">
        {(manageDuplicateSkills(assessmentData?.assessmentSkills) || []).map(
          (skillList: any) => (
            <Bullets
              key={Math.random()}
              skillName={skillList?.skillName ?? ""}
            />
          ),
        )}
      </div>
      <div className="assessmentDetailsData">
        <div className="firstRow">
          <div>
            <div className="common mb-4">
              <p>Industry</p>
              <h5>Technology</h5>
            </div>
            <div className="common">
              <p>{t("RESUME.ASSESSMENT_DURATION")}</p>
              <h5>{assessmentData?.totalTime ?? "0"} mins</h5>
            </div>
          </div>
          <div>
            <div className="common mb-4">
              <p>{t("RESUME.ASSESSMENT_TOTAL_SKILLS")}</p>
              <h5>{assessmentData?.assessmentSkills?.length ?? "0"}</h5>
            </div>
            <div className="common">
              <p>{t("RESUME.ASSESSMENT_CUTT_OFF")}</p>
              <h5>{assessmentData?.cutOff ?? "0"}%</h5>
            </div>
          </div>
          <div>
            <div className="common mb-4">
              <p>{t("RESUME.ASSESSMENT_QUESTIONS")}</p>
              <h5>{assessmentData?.totalQuestions ?? "0"}</h5>
            </div>
            <div className="common">
              <p>{t("RESUME.ASSESSMENT_CREDITS")}</p>
              <div className="creditPoints">
                <CreditCoin width={25} height={25} />
                <span>{assessmentData?.credits ?? "0"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="horizontalLine"></div>
      <div className="description">
        <h5>{t("RESUME.ASSESSMENT_DESCRIPTION")}</h5>
        <span>
          {assessmentData?.description?.length
            ? assessmentData?.description
            : "--"}
        </span>
      </div>
      <div className="horizontalLine"></div>

      <div className="instruction">
        <h5>{t("RESUME.ASSESSMENT_INSTRUCTION")}</h5>
        <span>
          <p
            dangerouslySetInnerHTML={{
              __html: `${assessmentData?.instruction?.length ? assessmentData?.instruction : "--"}`,
            }}
          ></p>
        </span>
      </div>
    </div>
  );
};

export default AssessmentsDetails;
