import "./TabsFilter.scss";
import {
  AssessmentButton,
  Bullets,
  Pagination,
  showToast,
  TemplateSearch,
  TypeSelect,
} from "ui-components";
import "./TabsFilter.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { cvCompleteScan } from "../../../../../../service/CvScanning/CvScanning";
import FileUpload from "../FileUpload/FileUpload";
import CustomModal from "../../../../../../components/CustomModal/CustomModal";
import CreditCoin from "../../../../../../utils/Images/CreditCoin";
import {
  capitalizeFirstLetter,
  checkTimeDifference,
  convertDate,
  copyToClipboard,
  foundUniqueId,
  getTimeDifference,
} from "../../../../../../utils/Common/Constant";
import { sendInvitationApi } from "../../../../../../service/Invite/Invite";
import Content from "./Content";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../../../components/Loader/Loader";
import UpArrowIcon from "../../../../../../utils/Images/UpArrowIcon";
import DownArrowIcon from "../../../../../../utils/Images/DownArrowIcon";
import ComingSoon from "../../../../Common/ComingSoon/ComingSoon";
import UserIcon from "../../../../../../utils/Images/AddSource/UserIcon";
import EmailIcon from "../../../../../../utils/Images/AddSource/EmailIcon";

const TabsFilter = ({
  tabActive,
  jobId,
  handleFilesUploaded,
  fileUploadData,
  setFileProgressList,
  fileProgressList,
  isConfirmScanCvs,
  isAssessmentAvailable,
  selectedSourceType,
  setSelectedSourceType,
  isfilterColumns,
  setIsFilterColumns,
  isDirectionArrow,
  setIsDirectionArrow,
  setSearchCv,
  searchCv,
  setCurrentPage,
  currentPage,
  updateCountFunc,
  searchCvDelay,
  cvListData,
  totalCvListData,
  setPageSize,
  pageSize,
  setLoading,
  loading,
  sourceType,
  cvListFunData,
}: any) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const [isInvited, setIsInvited] = useState(false);
  const [scanningCvId, setScanningCvId] = useState<string | null>(null);
  const emailRef = useRef(null);
  const [invitedEmal, setInvitedEmail] = useState<any>({
    email: "",
    firstName: "",
    lastName: "",
  });

  // static filterColumns data
  const filterColumns = [
    { label: t("RESUME.SCANNING_OVERALL"), value: "overAll" },
    { label: t("RESUME.SCANNING_EXPERIENCE"), value: "experience" },
    { label: t("GENERAL.SKILLS"), value: "skill" },
    { label: t("RESUME.SCANNING_PROJECTS"), value: "project" },
    { label: t("RESUME.SCANNING_KEYWORDS"), value: "keywords" },
    {
      label: t("RESUME.SCANNING_RELEVANT_PROJECTS"),
      value: "relevantProject",
    },
    {
      label: t("RESUME.SCANNING_UPLOAD_DATE"),
      value: "uploadedDate",
    },
    {
      label: t("RESUME.SCANNING_SCANNED_DATE"),
      value: "scannedDate",
    },
  ];
  useEffect(() => {
    setSearchCv("");
  }, [tabActive]);

  useEffect(() => {
    cvListFunData();
    updateCountFunc();
  }, [
    isDirectionArrow,
    isfilterColumns,
    searchCvDelay,
    selectedSourceType,
    isConfirmScanCvs,
    currentPage,
    tabActive,
  ]);

  // handle bulk scan
  const handleTopScanCV = async (
    jobId: string,
    totalCount: any,
    cvId?: string,
  ) => {
    setLoading(true);
    const scancvRes = await cvCompleteScan(jobId, totalCount, cvId);
    if (scancvRes?.status === 200) {
      showToast(t("RESUME.SCANNING_10_CVS_SCANNED_SUCCESSFULLY"), "success");
      setLoading(false);
      cvListFunData();
      updateCountFunc();
    } else {
      setLoading(false);
    }
  };
  // handle single scan
  const handleScanCV = async (
    jobId: string,
    totalCount: any,
    cvId?: string,
  ) => {
    if (!cvId) return;
    setScanningCvId(cvId);
    const scancvRes = await cvCompleteScan(jobId, totalCount, cvId);
    if (scancvRes?.status === 200) {
      showToast(t("RESUME.SCANNING_CVS_SCANNED_SUCCESSFULLY"), "success");
      cvListFunData();
      updateCountFunc();
    }
    setScanningCvId(null);
  };
  // handle invited email functionality
  const handleInvitedEmail = async (assessId: string) => {
    setLoading(true);
    const data = {
      assessmentId: assessId,
      emailId: invitedEmal?.email,
      firstName: invitedEmal?.firstName,
      lastName: invitedEmal?.lastName,
    };
    const sendInvitationRes = await sendInvitationApi(data);
    if (sendInvitationRes?.data?.status === 200) {
      showToast(sendInvitationRes?.data?.customMessage, "success");
      setLoading(false);
      setIsInvited(false);
      updateCountFunc();
      cvListFunData();
    } else {
      setIsInvited(false);
      setLoading(false);
    }
  };
  // change the order desc/asc
  const handleDirectionArrow = () => {
    if (isDirectionArrow === "desc") {
      setIsDirectionArrow("asc");
      setCurrentPage(1);
    } else {
      setIsDirectionArrow("desc");
      setCurrentPage(1);
    }
  };
  return (
    <div>
      <div>
        {!(
          cvListData?.length === 0 &&
          searchCv === "" &&
          selectedSourceType === "All"
        ) ? (
          <>
            <div className="filters">
              <TemplateSearch
                onChange={(e) => {
                  setSearchCv(e);
                  setCurrentPage(1);
                }}
                placeholder={t("RESUME.SCANNING_SEARCH_CVS")}
                value={searchCv}
              />
              <div className="sourceTypeFilter">
                <div className="source">
                  <span>{t("RESUME.SCANNING_FILTER")}</span>
                  <TypeSelect
                    options={sourceType}
                    loadingText=" "
                    value={selectedSourceType}
                    onChange={(value: any) => {
                      setSelectedSourceType(value);
                      setCurrentPage(1);
                    }}
                  />
                  <div className="scannedUnscannedCvs">
                    <span>
                      {t("RESUME.ASSESSMENT_TOTAL_CVS")} : {totalCvListData}
                    </span>
                  </div>
                </div>
                <div className="filter">
                  {(tabActive === "scannedcv" || tabActive === "invited") && (
                    <div className="filterCoulmn">
                      <span>Sort By</span>
                      <TypeSelect
                        options={filterColumns}
                        loadingText=" "
                        value={isfilterColumns}
                        onChange={(value: any) => {
                          setIsFilterColumns(value);
                          setIsDirectionArrow("desc");
                          setCurrentPage(1);
                        }}
                      />
                    </div>
                  )}
                  {tabActive === "unscannedcv" && (
                    <span>{t("RESUME.SCANNING_UPLOAD_DATE")}</span>
                  )}

                  <h6
                    onClick={() => handleDirectionArrow()}
                    className="DownUpArrow"
                  >
                    {isDirectionArrow === "asc" ? (
                      <UpArrowIcon />
                    ) : isDirectionArrow === "desc" ? (
                      <DownArrowIcon />
                    ) : (
                      <DownArrowIcon />
                    )}
                  </h6>
                  {tabActive === "unscannedcv" && (
                    <div>
                      <div className="scanTopCvs">
                        <AssessmentButton
                          disabled={loading}
                          onClick={() =>
                            handleTopScanCV(
                              jobId,
                              totalCvListData > 10 ? 10 : totalCvListData,
                              "",
                            )
                          }
                        >
                          {loading ? (
                            <div className="scanJobLoader">
                              <span>{t("GENERAL.PROCESSING")}</span>
                              <Loader />
                            </div>
                          ) : (
                            `${t("RESUME.ASSESSMENT_SCAN_TOP_CVS")} ${totalCvListData > 10 ? 10 : totalCvListData} ${t("RESUME.SCANNING_CVS")}`
                          )}
                        </AssessmentButton>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* </div> */}
            {cvListData?.length ? (
              <>
                {cvListData?.map((item: any, ind: number) => {
                  return (
                    <div key={ind} className="existingJDList mb-3">
                      <div className="nameLocation">
                        <div className="nameSurname">
                          <h5>{`${item?.cv?.firstName || item?.cv?.lastName ? `${item?.cv?.firstName ?? ""} ${item?.cv?.lastName ?? ""}` : "-"}`}</h5>
                          {/* <span>
                            {`${item?.cv?.firstName ?? "-"} ${item?.cv?.lastName ?? "-"}`}
                          </span> */}
                          <div className="emailVector">
                            <p
                              onClick={() =>
                                copyToClipboard(
                                  emailRef,
                                  item?.cv?.email,
                                  t("RESUME.EMAIL_COPIED"),
                                )
                              }
                              ref={emailRef}
                            >
                              {item?.cv?.email}
                            </p>
                          </div>
                        </div>
                        <div className="dateLocation">
                          <span>
                            {(() => {
                              const dateToCheck =
                                item?.matchedDate || item?.createdAt;
                              const isMoreThan24Hours =
                                checkTimeDifference(dateToCheck);

                              return isMoreThan24Hours
                                ? item?.matchedDate
                                  ? convertDate(item?.matchedDate, false, true)
                                  : convertDate(item?.createdAt, false, true)
                                : getTimeDifference(
                                    dateToCheck,
                                    t("GENERAL.MIN(S)"),
                                    t("GENERAL.HOUR(S)"),
                                    t("GENERAL.DAY(S)"),
                                    t("GENERAL.MONTH(S)"),
                                    t("GENERAL.YEAR(S)"),
                                    t("GENERAL.AGO"),
                                    t("GENERAL.JUST_NOW"),
                                  );
                            })()}
                          </span>
                          <p className="text-end">
                            {[item?.city, item?.country]
                              .filter(Boolean)
                              .join(", ")}
                          </p>
                        </div>
                      </div>
                      <div className="skillWithSource mt-2">
                        <div className="skillList">
                          {item?.cv?.cvSkill?.map((item: any, ind: number) => {
                            return (
                              <div key={ind}>
                                <Bullets
                                  skillName={capitalizeFirstLetter(
                                    item?.skillName,
                                  )}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <p className="source">
                          {t("RESUME.SCANNING_SOURCE")} :&nbsp;
                          <span className="sourceName">{item.source}</span>
                        </p>
                      </div>

                      <div className="horizontalLine"></div>
                      <Content
                        item={item}
                        handleScanCV={handleScanCV}
                        isAssessmentAvailable={isAssessmentAvailable}
                        setInvitedEmail={setInvitedEmail}
                        setIsInvited={setIsInvited}
                        jobId={jobId}
                        loading={scanningCvId}
                        tabActive={tabActive}
                      />
                    </div>
                  );
                })}

                <div className="row">
                  <div className="col-md-12">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={totalCvListData || 0}
                      pageSize={pageSize}
                      onPageChange={(page: number) => setCurrentPage(page)}
                      ofLabel={t("GENERAL.OF")}
                      resultLabel={t("GENERAL.RESULTS")}
                      setPageSize={setPageSize}
                    />
                  </div>
                </div>
              </>
            ) : (
              <ComingSoon text={t("RESUME.ASSESSMENT_NO_CVS_FOUND")} />
            )}
          </>
        ) : tabActive === "unscannedcv" ? (
          <div className="scanExistingAndFile ">
            <FileUpload
              onFilesUploaded={handleFilesUploaded}
              fileUploadData={fileUploadData}
              setFileProgressList={setFileProgressList}
              fileProgressList={fileProgressList}
            />
          </div>
        ) : (
          <>
            <ComingSoon text={t("RESUME.ASSESSMENT_NO_CVS_FOUND")} />
          </>
        )}
        <CustomModal
          show={isInvited}
          isCrossBtn={true}
          isHeaderVisible={false}
          handleClose={() => {
            setIsInvited(false);
          }}
          className=""
          size="lg"
          fullscreen="md-down"
          isFooterVisible={false}
        >
          <div className="invitedEmal ">
            <div className="invitedTitle">
              <h3
                className={
                  isAssessmentAvailable?.length ? "haveData" : "haveNotData"
                }
              >
                {isAssessmentAvailable?.length
                  ? t("RESUME.ASSESSMENT_INVITE_APPLICANT_FOR")
                  : t(
                      "RESUME.ASSESSMENT_CREATE_ASSESSMENT_TO_INVITE_AN_APPLICANT",
                    )}
              </h3>
            </div>
            {isAssessmentAvailable?.length ? (
              <>
                <span className="titleName">
                  {isAssessmentAvailable[0]?.title}
                </span>
                <div className="durationQuestions">
                  <p>
                    {t("RESUME.ASSESSMENT_DURATION")} :
                    <span>{isAssessmentAvailable[0]?.totalTime} Mins</span>
                  </p>
                  <div className="divider"></div>
                  <p>
                    {t("GENERAL.NUMBER_OF_QUESTION")} :
                    <span>{isAssessmentAvailable[0]?.totalQuestions}</span>
                  </p>
                </div>
                <div className="horizontalLine w-25 mt-5"></div>
                <div className="nameEmail mt-5 mb-5">
                  <div className="firstAndLastName">
                    <UserIcon />
                    <h5>{`${invitedEmal?.firstName || invitedEmal?.lastName ? `${invitedEmal?.firstName ?? ""} ${invitedEmal?.lastName ?? ""}` : "-"}`}</h5>
                  </div>
                  <div className="email">
                    <div>
                      <EmailIcon />
                    </div>
                    <div>
                      <h5>{invitedEmal?.email ? invitedEmal?.email : "--"}</h5>
                    </div>
                  </div>
                </div>
                <div className="creditPayBtn">
                  <div className="creditCoin">
                    <CreditCoin width={25} height={25} />
                    <span>
                      {isAssessmentAvailable[0]?.credits
                        ? isAssessmentAvailable[0]?.credits
                        : 0}
                    </span>
                  </div>
                  <AssessmentButton
                    disabled={loading}
                    onClick={() =>
                      handleInvitedEmail(isAssessmentAvailable[0]?.assessmentId)
                    }
                    className="whiteBtn"
                  >
                    {loading ? (
                      <div className="scanJobLoader">
                        <p>{t("GENERAL.LOADING")}</p>
                        <Loader />
                      </div>
                    ) : (
                      t("RESUME.ASSESSMENT_PAY_AND_INVITE")
                    )}
                  </AssessmentButton>
                </div>
              </>
            ) : (
              <div className="assessNotAvailable">
                <AssessmentButton
                  onClick={() => {
                    navigate(
                      `/${currentLanguage}/assessment/build/${foundUniqueId()}?jobId=${jobId}`,
                    );
                  }}
                >
                  {t("CREATE_ASSESSMENT.TITLE")}
                </AssessmentButton>
              </div>
            )}
          </div>
        </CustomModal>
      </div>
      {/* )} */}
    </div>
  );
};

export default TabsFilter;
