import "./AddCreditForCv.scss";
import { useContext, useEffect, useState } from "react";
import { AssessmentButton } from "ui-components";
import { systemPreferenceContext } from "../../../../context/SystemPreference/SystemPreference";
import { useTranslation } from "react-i18next";
interface AddCreditForCv {
  isBtnShow?: boolean;
  handleSelectedCredit: any;
  handleAddCreditFUnc?: any;
}
const AddCreditForCv: React.FC<AddCreditForCv> = ({
  isBtnShow,
  handleSelectedCredit,
  handleAddCreditFUnc,
}) => {
  const [selectedCredit, setSelectedCredit] = useState<any>(null);
  const { preferenceData } = useContext(systemPreferenceContext);
  const [creditData, setCreditData] = useState<any>([]);
  const { t } = useTranslation();
  useEffect(() => {
    const result = Object.entries(preferenceData?.cvPackage).map(
      ([key, value]) => ({
        label: key,
        value: Number(value),
      }),
    );
    setCreditData(result);
  }, [preferenceData]);

  const handleSelect = (value: any) => {
    setSelectedCredit(value);
    handleSelectedCredit(value);
  };

  return (
    <div className="addMorePackage">
      {creditData?.map((item: any, ind: any) => (
        <div
          key={ind}
          className={`creditRecharge ${
            selectedCredit?.label === item?.label ? "active" : ""
          }`}
          onClick={() => handleSelect(item)}
        >
          <span>{`${item.label} ${t("GENERAL.CREDITS")}`}</span>
          <span>{`${item.value} ${t("RESUME.CV")}`}</span>
        </div>
      ))}
      {isBtnShow && (
        <div className="payment mt-3 mb-3">
          <AssessmentButton onClick={() => handleAddCreditFUnc()}>
            Add
          </AssessmentButton>
        </div>
      )}
    </div>
  );
};

export default AddCreditForCv;
