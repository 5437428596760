// import { useMemo } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import "./MapComponent.scss";
interface MapComponentProps {
  lat: any;
  lng: any;
}
const MapComponent = ({ lat, lng }: MapComponentProps) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || "",
  });
  const center = { lat: lat, lng: lng };
  const customPinMarker = {
    path: "M12,0 C18,0 18,6 18,6 C18,6 14,12 12,16 C10,12 6,6 6,6 C6,6 6,0 12,0 M12,16 C14,20 16,22 16,24 C16,26 14,28 12,28 C10,28 8,26 8,24 C8,22 10,20 12,16 Z",
    fillColor: "red",
    fillOpacity: 1,
    strokeColor: "white", // Optional: Add stroke color to make it stand out
    strokeWeight: 2,
    rotation: 0,
    scale: 1.5, // Adjust the scale to change the size of the icon
  };

  return (
    <div className="map-container">
      {!isLoaded ? (
        <p>Loading...</p>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          center={center}
          zoom={10}
        >
          <Marker position={center} icon={customPinMarker} />
        </GoogleMap>
      )}
    </div>
  );
};

export default MapComponent;
