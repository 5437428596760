// import React, { useState, useRef, useEffect } from "react";
import { useEffect, useState } from "react";
import "./FloatingWindow.scss";
import InviteApplicant from "../../pages/Invite/InviteApplicant";
import { showToast } from "ui-components";
import {
  getInvitedApplicantList,
  sendInvitationApi,
} from "../../service/Invite/Invite";
import useDebounce from "../../utils/Hooks/useDebounce";
interface IassessmentIdFromSelect {
  value?: string | number | undefined;
  label?: string;
  assessmentId: any;
  credit: any;
}
const FloatingWindow: React.FC<IassessmentIdFromSelect> = ({
  assessmentId,
  credit,
}) => {
  const assessmentIdParam = assessmentId;
  const defaultSelectAssess = { value: assessmentIdParam, label: "" };
  const [assessmentIdFromSelect] = useState(defaultSelectAssess);
  const defaultSelectApplicant = { value: "", label: "" };
  const [applicantIdFromSelect] = useState(defaultSelectApplicant); // setApplicantIdFromSelect
  const applicantDebounceValue = useDebounce(
    applicantIdFromSelect?.label.trim(),
    400,
  );
  const [applicantListForSearch, setApplicantListForSearch] = useState([]);
  const [inviteAppTotalCount, setInviteAppTotalCount] = useState(0);
  const [inviteCurrentPage, setInviteCurrentPage] = useState(1);
  const [invitePageSize, setInvitePageSize] = useState(10);

  useEffect(() => {
    getApplicantListForSearch();
  }, [assessmentIdParam, inviteCurrentPage, invitePageSize]);

  useEffect(() => {
    if (applicantDebounceValue) {
      getApplicantListForSearch();
    }
  }, [applicantDebounceValue]);
  async function getApplicantListForSearch() {
    const getApplicantList = await getInvitedApplicantList(
      assessmentIdParam,
      inviteCurrentPage,
      invitePageSize,
      applicantDebounceValue ?? "",
    );
    if (getApplicantList?.status === 200) {
      setInviteAppTotalCount(getApplicantList?.data?.totalCount);
      let updateData;
      if (getApplicantList?.data?.data?.length > 0) {
        updateData = (getApplicantList?.data?.data || []).map((item: any) => ({
          ...item,
          value: item?.email,
          label: item?.displayName,
          isOpen: false,
        }));
      } else {
        updateData = [];
      }

      setApplicantListForSearch(updateData);
    } else {
      showToast(getApplicantList?.customMessage, "error");
    }
  }

  // send invitation
  const sendInvitation = async (
    emailId: string | number,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    const data = {
      assessmentId: assessmentIdFromSelect?.value,
      emailId,
    };
    try {
      // Set isSubmitting to true to indicate that form submission is in progress
      setSubmitting && setSubmitting(true);
      if (emailId) {
        const sendInvitationRes = await sendInvitationApi(data);
        if (sendInvitationRes?.data?.status === 200) {
          showToast(sendInvitationRes?.data?.customMessage, "success");
          getApplicantListForSearch();
          resetForm && resetForm();
        } else {
          showToast(sendInvitationRes?.data?.customMessage, "error");
        }
      } else {
        // Handle the case where sendInvitation is undefined
        console.error("sendInvitation function is not defined");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      // Ensure isSubmitting is set to false, even if an error occurs during submission
      setSubmitting && setSubmitting(false);
    }
  };
  // const [isPopoverVisible,] = useState(true);
  // const popoverRef: any = useRef(null);
  return (
    <>
      {/* {isPopoverVisible && (
                <div className="floatingInviteScreen" ref={popoverRef}>
                    <div className="divisionSecond"> */}
      <div className="floatingInviteScreen">
        <div className="divisionSecond">
          <InviteApplicant
            sendInvitation={sendInvitation}
            applicantListForSearch={applicantListForSearch}
            setApplicantListForSearch={setApplicantListForSearch}
            inviteAppTotalCount={inviteAppTotalCount}
            inviteCurrentPage={inviteCurrentPage}
            setInviteCurrentPage={setInviteCurrentPage}
            invitePageSize={invitePageSize}
            getApplicantListForSearch={getApplicantListForSearch}
            setInvitePageSize={setInvitePageSize}
            assessmentId={assessmentId}
            credit={credit}
          />
        </div>
      </div>
      {/* </div>
                </div>
            )} */}
    </>
  );
};

export default FloatingWindow;
