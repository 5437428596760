import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  determinePageType,
  getAssessmentPath,
  initialScopeData,
  processData,
} from "../../../../utils/Common/Constant";
import Head from "../AssessmentScope/Head/Head";
import { setSessionStorageItem } from "../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { Form, Formik } from "formik";
import { createAssessmentDraftJson } from "../../../../service/DraftJson/DraftJson";
import { assessmentDraftJson } from "../../../../context/CreateAssessmentDraftJson/CreateAssessmentDraftJson";
import SidebarCategory from "./SidebarCategory";
import { useEffect } from "react";

const AssessmentBuild = () => {
  const { tempUniqueId } = useParams();
  const location = useLocation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const editedParams = new URLSearchParams(location.search).get("edited");
  const jobId = new URLSearchParams(location.search).get("jobId");
  // Get current language
  const currentLanguage = i18n.language;
  const activeStep = determinePageType(location?.pathname);
  useEffect(() => {
    if (location?.state?.jobSkillData) {
      setSessionStorageItem(
        "ownPreview",
        processData(location.state.jobSkillData),
      );
    }

    if (location?.state?.jobTitle) {
      setSessionStorageItem("titleHeadContent", location.state.jobTitle);
    }
  }, [location?.state?.jobSkillData, location?.state?.jobTitle]);
  const handleNext = async (count: number) => {
    let nextStep;
    if (count != null && count != undefined && typeof count === "number") {
      nextStep = activeStep < 5 ? count : activeStep;
    } else {
      nextStep = activeStep < 5 ? activeStep + 1 : activeStep;
    }
    if (activeStep < count) {
      await createAssessmentDraftJson(assessmentDraftJson(tempUniqueId));
    }
    setSessionStorageItem("currentStep", nextStep);
    navigate(
      getAssessmentPath(
        nextStep,
        tempUniqueId,
        currentLanguage,
        editedParams,
        jobId,
      ),
    );
  };

  // to move to previous page
  const handlePrevious = (count: number) => {
    if (count != null && count != undefined && typeof count === "number") {
      setSessionStorageItem("currentStep", count);
      navigate(
        getAssessmentPath(
          count,
          tempUniqueId,
          currentLanguage,
          editedParams,
          jobId,
        ),
      );
    } else {
      const prevStep = activeStep > 1 ? activeStep - 1 : activeStep;
      setSessionStorageItem("currentStep", prevStep);
      navigate(
        getAssessmentPath(
          prevStep,
          tempUniqueId,
          currentLanguage,
          editedParams,
          jobId,
        ),
      );
    }
  };
  return (
    <>
      <Formik
        initialValues={initialScopeData}
        onSubmit={() => {
          // handleNext();
        }}
      >
        {({ errors, touched }) => {
          return (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <Head
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    currentStep={activeStep}
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <SidebarCategory
                  jobSkillData={location?.state?.jobSkillData}
                  jobTitle={location?.state?.jobTitle}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AssessmentBuild;
