const SettingIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.996 10.8838C10.3209 10.8838 11.3948 9.81662 11.3948 8.50007C11.3948 7.18352 10.3209 6.11631 8.996 6.11631C7.67114 6.11631 6.5972 7.18352 6.5972 8.50007C6.5972 9.81662 7.67114 10.8838 8.996 10.8838Z"
        stroke="#4D4D4D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 9.19922V7.80075C1 6.97438 1.67966 6.29107 2.51924 6.29107C3.96652 6.29107 4.55822 5.274 3.83058 4.0265C3.41479 3.31137 3.66267 2.38171 4.3903 1.96852L5.77361 1.18188C6.4053 0.808426 7.22089 1.03091 7.5967 1.65863L7.68466 1.8096C8.4043 3.0571 9.58771 3.0571 10.3153 1.8096L10.4033 1.65863C10.7791 1.03091 11.5947 0.808426 12.2264 1.18188L13.6097 1.96852C14.3373 2.38171 14.5852 3.31137 14.1694 4.0265C13.4418 5.274 14.0335 6.29107 15.4808 6.29107C16.3123 6.29107 17 6.96643 17 7.80075V9.19922C17 10.0256 16.3203 10.7089 15.4808 10.7089C14.0335 10.7089 13.4418 11.726 14.1694 12.9735C14.5852 13.6966 14.3373 14.6183 13.6097 15.0315L12.2264 15.8181C11.5947 16.1916 10.7791 15.9691 10.4033 15.3414L10.3153 15.1904C9.5957 13.9429 8.41229 13.9429 7.68466 15.1904L7.5967 15.3414C7.22089 15.9691 6.4053 16.1916 5.77361 15.8181L4.3903 15.0315C3.66267 14.6183 3.41479 13.6886 3.83058 12.9735C4.55822 11.726 3.96652 10.7089 2.51924 10.7089C1.67966 10.7089 1 10.0256 1 9.19922Z"
        stroke="#4D4D4D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingIcon;
