import * as Yup from "yup";
import { useTranslation } from "react-i18next";
const urlRegex =
  /^(https?:\/\/)?(www\.)?((([0-9]{1,3}\.){3}[0-9]{1,3})|([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+))(:[0-9]{1,5})?(\/[^\s]*)?$/;
export const signupUserSchema = (t: ReturnType<typeof useTranslation>["t"]) =>
  Yup.object().shape({
    logo: Yup.string().required(t("ERROR.LOGO_REQUIRED")),
    companyName: Yup.string().required(t("ERROR.COMPANY_NAME_REQUIRED")),
    industryType: Yup.string().required(t("ERROR.INDUSTRY_TYPE_REQUIRED")),
    displayName: Yup.string().required(t("ERROR.DISPLAY_NAME_REQUIRED")),
    websiteUrl: Yup.string()
      .matches(urlRegex, t("ERROR.INVALID_URL"))
      .required(t("ERROR.WEBSITE_URL_REQUIRED")),
    mobile: Yup.string()
      .matches(/^[0-9]{5,20}$/, t("ERROR.INVALID_MOBILE_NUMBER"))
      .required(t("ERROR.MOBILE_REQUIRED")),
    // address: Yup.string().required(t("ERROR.ADDRESS_REQUIRED")),
    city: Yup.string().required(t("ERROR.CITY_REQUIRED")),
    country: Yup.string().required(t("ERROR.COUNTRY_REQUIRED")),
    state: Yup.string().required(t("ERROR.STATE_REQUIRED")),
    zipcode: Yup.string().required(t("ERROR.ZIPCODE_REQUIRED")),
    linkedin: Yup.string().matches(urlRegex, t("ERROR.INVALID_URL")),
    twitter: Yup.string().matches(urlRegex, t("ERROR.INVALID_URL")),
    facebook: Yup.string().matches(urlRegex, t("ERROR.INVALID_URL")),
    instagram: Yup.string().matches(urlRegex, t("ERROR.INVALID_URL")),
    ownerEmail: Yup.string().matches(
      /^(?!.*\.{2})([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*)(?:\.[a-zA-Z]{2,})+$/,
      t("ERROR.INVALID_EMAIL_ADDRESS"),
    ),
    ownerMobile: Yup.string().matches(
      /^[0-9]{5,20}$/,
      t("ERROR.INVALID_MOBILE_NUMBER"),
    ),
  });
