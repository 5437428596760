import "./index.scss";
import Footer from "../../components/Footer/Footer";
import AssessmentList from "./AssessmentList/AsessmentList";
import Header from "../../components/Assessment/Header/Header";
const CvScanning = () => {
  return (
    <>
      <Header />
      <div className="cvScanning">
        <AssessmentList />
      </div>
      <Footer />
    </>
  );
};

export default CvScanning;
