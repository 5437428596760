import {
  AssessmentButton,
  AssessmentCheckboxRadio,
  Bullets,
  Pagination,
  TemplateSearch,
} from "ui-components";
import "./AssessmentList.scss";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AssesmentListingIcon from "../../../utils/Images/AssesmentListingIcon";
import {
  capitalizeFirstLetter,
  convertDate,
  typesKeyPair,
} from "../../../utils/Common/Constant";
import CopyVector from "../../../utils/Images/CopyVector";
import { useNavigate } from "react-router-dom";
import { companyGetJobList } from "../../../service/CvScanning/CvScanning";
import useDebounce from "../../../utils/Hooks/useDebounce";
const AssessmentList = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const [checkboxFilter, setCheckboxFilter] = useState<string>(
    t("RESUME.SCANNING_ACTIVE"),
  );
  const [jobListData, setJobListData] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const searchTextDelay = useDebounce(searchText.trim(), 700);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const jobListPaginaitonRef = useRef<HTMLDivElement>(null);
  const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
  const handleFilter = (checkItem: string) => {
    setCheckboxFilter(checkItem);
    setCurrentPage(1);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (jobListPaginaitonRef.current && managePaginationRef != null) {
      jobListPaginaitonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [managePaginationRef]);
  useEffect(() => {
    getJobList();
  }, [checkboxFilter, searchTextDelay, currentPage]);
  const getJobList = async () => {
    const companyGetJobListRes = await companyGetJobList(
      checkboxFilter,
      searchTextDelay,
      currentPage,
      pageSize,
    );
    if (companyGetJobListRes?.status === 200) {
      setJobListData(companyGetJobListRes?.data?.data);
      setTotalCount(companyGetJobListRes?.data?.totalCount);
    }
  };
  return (
    <div className="resumeScanningLeftSide">
      <h2 className="mb-4">{t("RESUME.YOUR_JOB_BOARD")}</h2>
      <div className="filterWork">
        <div
          className="checkboxWithLabel"
          onClick={() => {
            handleFilter(t("RESUME.SCANNING_ACTIVE"));
          }}
        >
          <AssessmentCheckboxRadio
            type="radio"
            checked={checkboxFilter === t("RESUME.SCANNING_ACTIVE")}
          />
          <p className="radioCheckLabel">{t("RESUME.SCANNING_ACTIVE")}</p>
        </div>
        <div
          className="checkboxWithLabel"
          onClick={() => {
            handleFilter(t("RESUME.SCANNING_DRAFT"));
          }}
        >
          <AssessmentCheckboxRadio
            type="radio"
            checked={checkboxFilter === t("RESUME.SCANNING_DRAFT")}
          />
          <p className="radioCheckLabel">{t("RESUME.SCANNING_DRAFT")}</p>
        </div>
        <div
          className="checkboxWithLabel"
          onClick={() => {
            handleFilter(t("RESUME.SCANNING_CLOSED"));
          }}
        >
          <AssessmentCheckboxRadio
            type="radio"
            checked={checkboxFilter === t("RESUME.SCANNING_CLOSED")}
          />
          <p className="radioCheckLabel">{t("RESUME.SCANNING_CLOSED")}</p>
        </div>
      </div>
      <div className="searchWithNew" ref={jobListPaginaitonRef}>
        <TemplateSearch
          onChange={(e) => {
            setSearchText(e);
            setCurrentPage(1);
          }}
          placeholder={t("GENERAL.SEARCH_EVENT")}
          value={searchText}
        />
        {/* <AssessmentButton
          onClick={() => {
            navigate(`/${currentLanguage}/cv-assessment/create-job`);
          }}
        >
          {t("RESUME.SCANNING_ADD_NEW")}
        </AssessmentButton> */}
      </div>
      <div className="AssessmentListContentSec">
        <p className="totalContent mt-3">
          {t("GENERAL.TOTAL_JOBS")} : {totalCount}
        </p>
        {(jobListData ?? []).length > 0 ? (
          (jobListData ?? [])?.map((list: any, index: any) => {
            return (
              <div
                className="AssessmentListCardSec"
                key={Math.random() + index}
              >
                <div className="AssessmentListCard">
                  <div className="AssessmentListCardImg cursorPointer">
                    <AssesmentListingIcon />
                    <h4>{list?.status ?? "Active"}</h4>
                    <h6>{convertDate(list?.createdAt, false, true) ?? "-"}</h6>
                  </div>
                  <div className="AssessmentListCardDetails">
                    <div className="ListCardDetailsTitleSec">
                      <div className="ListCardDetailsTitle">
                        <div className="listItemSec">
                          <div className="ListCardTitleHeading">
                            <div>
                              <div className="clipBoard">
                                <h3
                                  title={list?.title ?? ""}
                                  className="cursorPointer"
                                  onClick={() => {}}
                                >
                                  {list?.title
                                    ? capitalizeFirstLetter(list?.title)
                                    : "-"}
                                </h3>
                                {list?.slugUrl && (
                                  <div
                                    className="listCopyIcon"
                                    onClick={() => {}}
                                    title={t("GENERAL_PUBLIC_URL")}
                                  >
                                    <CopyVector color="var(--primary-green)" />
                                  </div>
                                )}
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="link_wrap">
                                  <p className="acc_style04">
                                    {list?.visibility
                                      ? capitalizeFirstLetter(list?.visibility)
                                      : "-"}
                                  </p>
                                </div>
                                <span>{list?.assessmentType}</span>
                              </div>
                            </div>
                          </div>
                          <div className="bulletSec">
                            {list?.jobSkills?.map((item: any, ind: number) => {
                              return (
                                <div key={ind}>
                                  <Bullets
                                    skillName={capitalizeFirstLetter(
                                      item?.skillName,
                                    )}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ListContentSkillsSec">
                      <div className="ListContentSkills decreaseGap">
                        <div className="w-100">
                          <div className="w-100 mb-3">
                            <p>{t("RESUME.SCANNING_EXPERIENCE")}</p>
                            <h5>{`${list?.minimumExperience ?? "3"} - ${list?.maximumExperience ?? "5"} years`}</h5>
                          </div>
                          <div>
                            <p>{t("GENERAL.ENGAGEMENT")}</p>
                            <h5>{typesKeyPair[list?.jobType] ?? "-"}</h5>
                          </div>
                        </div>
                        <div className="w-100">
                          <div className="w-100 mb-3">
                            <p>{t("RESUME.SCANNING_OPEN_POSITIONS")}</p>
                            <h5>{list?.noOfPositions ?? 0}</h5>
                          </div>
                          <div>
                            <p>{t("RESUME.SCANNING_COUNTRY")}</p>
                            <h5>{list?.country ?? "-"}</h5>
                          </div>
                        </div>
                        <div className="w-100">
                          <div className="w-100 mb-3">
                            <p>{t("GENERAL.JOB_TYPE")}</p>
                            <h5>{typesKeyPair[list?.engagementType] ?? "-"}</h5>
                          </div>
                          <div>
                            <p>{t("RESUME.SCANNING_UPLOADED_CVS")}</p>
                            <h5>{list?.uploadedCVs ?? "0"}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="RightSideArrowSec">
                        <AssessmentButton
                          onClick={() => {
                            if (list?.status === "Draft") {
                              navigate(
                                `/${currentLanguage}/job/create-job?jobId=${list?.jobId}`,
                              );
                            } else {
                              navigate(
                                `/${currentLanguage}/job/${list?.jobId}`,
                              );
                            }
                          }}
                          className="whiteBtn"
                        >
                          {list?.status == "Draft"
                            ? t("GENERAL.EDIT")
                            : t("GENERAL.VIEW")}
                        </AssessmentButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="noListFound">
            <div className="text-center">
              <h1>{t("RESUME.SCANNING_NO_JOB_FOUND")}</h1>
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-12">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalCount || 0}
            pageSize={pageSize}
            onPageChange={(page: number) => {
              setCurrentPage(page);
              setManagePaginationRef(Math.random());
            }}
            ofLabel={t("GENERAL.OF")}
            resultLabel={t("GENERAL.RESULTS")}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default AssessmentList;
