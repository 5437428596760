import "./JobDescription.scss";
import { AssessmentButton, showToast, TextEditor } from "ui-components";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CreditCoin from "../../../utils/Images/CreditCoin";
import Header from "../../../components/Assessment/Header/Header";
import Footer from "../../../components/Footer/Footer";
import {
  companyGetJobDetails,
  scanJob,
  updateJob,
} from "../../../service/CvScanning/CvScanning";
import { useNavigate } from "react-router-dom";
import DownArrow from "../../../utils/Images/DownArrow";
import Loader from "../../../components/Loader/Loader";
import AddCreditForCv from "../Common/AddCreditForCv/AddCreditForCv";
import ScanningDetails from "./ScanningDetails/ScanningDetails";
import { setSessionStorageItem } from "../../../utils/SessionStorageMethod/setSessionStorageItem";
import { getSessionStorageItem } from "../../../utils/SessionStorageMethod/getSessionStorageItem";
import { calculateMinusAmount } from "../../../utils/Common/Constant";
import { removeSessionStorageItem } from "../../../utils/SessionStorageMethod/removeSessionStorageItem";

const JobDescription = () => {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const jobId = new URLSearchParams(location.search).get("jobId");
  const navigate = useNavigate();
  const scanningDetailsRef = useRef<any>();
  const [description, setDescription] = useState("");
  const [isJobScanning, setIsJobScanning] = useState(false);
  const [isRequiredCredit, setIsRequiredCredit] = useState(false);
  const [jobDetailsData, setJobDetailsData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const scrollToNextSec = useRef<any>(null);
  const textEditorLimit = 5001;
  const [countTextInEditor, setCountTextInEditor] =
    useState<number>(textEditorLimit);
  const [countManageFlag, setCountManageFlag] = useState(false);
  const [addMoreCredit, setAddMoreCredit] = useState<any>({
    label: "0",
    value: 0,
  });
  useEffect(() => {
    setDescription(getSessionStorageItem("jdText"));
    setJobDetailsData(getSessionStorageItem("jobDetailsData"));
    setIsJobScanning(getSessionStorageItem("isJobScanning"));
  }, []);
  // invoke when edit jobs
  useEffect(() => {
    if (jobId) {
      const getJobDetails = async () => {
        const jobDetailsRes = await companyGetJobDetails(jobId);
        if (jobDetailsRes?.status === 200) {
          setDescription(jobDetailsRes?.data?.description);
          setJobDetailsData(jobDetailsRes?.data);
          setIsJobScanning(true);
          setIsRequiredCredit(false);
        }
      };
      getJobDetails();
    }
  }, []);

  // function to scan and extracted data
  const handleJobScanning = async () => {
    const jobsData = {
      cvPackage: addMoreCredit?.label,
      jobDescription: description,
    };
    try {
      setLoading(true);
      const scanJobsRes = await scanJob(jobsData);
      if (scanJobsRes?.status === 200) {
        const jobDetailsRes = await companyGetJobDetails(
          scanJobsRes?.data?.jobId,
        );
        if (jobDetailsRes?.status === 200) {
          setLoading(false);
          setJobDetailsData(jobDetailsRes?.data);
          setIsJobScanning(true);
          setSessionStorageItem("isJobScanning", true);
          setSessionStorageItem("jobDetailsData", jobDetailsRes?.data);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error in scanning jobs", error);
      setLoading(false);
    }
  };
  // function to create job
  const handleCreateJob = async () => {
    setLoading(true);
    const updatedData = scanningDetailsRef?.current?.getUpdatedData();
    const updateJobsRes = await updateJob(updatedData);
    if (updateJobsRes?.status === 200) {
      showToast(updateJobsRes?.customMessage, "success");
      setLoading(false);
      removeSessionStorageItem("jobDetailsData");
      removeSessionStorageItem("job-keywords");
      removeSessionStorageItem("isJobScanning");
      removeSessionStorageItem("jdText");
      removeSessionStorageItem("maximumExperience");
      removeSessionStorageItem("minimumExperience");
      removeSessionStorageItem("noOfPositions");
      removeSessionStorageItem("selectedJobCountry");
      removeSessionStorageItem("selectedJobState");
      removeSessionStorageItem("jobAddress1");
      removeSessionStorageItem("jobCoords");
      removeSessionStorageItem("jobAddress2");
      removeSessionStorageItem("jobCity");
      removeSessionStorageItem("jobZipcode");
      navigate(`/${currentLanguage}/job/${jobDetailsData?.jobId}`);
    } else {
      setLoading(false);
    }
  };

  // added more package
  const handleMoreCredit = (value: any) => {
    setAddMoreCredit(value);
  };
  const isTextareaValid = () => {
    return description?.trim() !== "" && description?.trim() !== "<p><br></p>";
  };
  const textEditorHandleChange = (
    content: any,
    _delta: any,
    _source: any,
    editor: any,
  ) => {
    const currentLength = editor.getLength();
    setCountTextInEditor(currentLength);
    setCountManageFlag(true);
    setDescription(content);
    setSessionStorageItem("jdText", content);
  };
  return (
    <>
      <Header />
      <div className="container-fluid jobDescription">
        <h2 className="mt-5 mb-4">{t("RESUME.SCANNING_CREATE_NEW_JOB")}</h2>
        <div className="jdWork row">
          {!isJobScanning && (
            <div>
              <TextEditor
                value={description}
                onChange={textEditorHandleChange}
                placeholder={t("RESUME.SCANNING_PLEASE_TYPE_YOUR_JD_HERE")}
              />
              <div className="textEditorMsg">
                <p className="errorMessage">
                  {countTextInEditor > textEditorLimit && (
                    <>
                      {t("GENERAL.MUST_BE_LESS_THAN")} {textEditorLimit - 1}{" "}
                      {t("GENERAL.CHARACTERS")}
                    </>
                  )}
                </p>
                {(countManageFlag ? 1 : textEditorLimit) !=
                  countTextInEditor && (
                  <p className="limitMsg">
                    {calculateMinusAmount(countTextInEditor, textEditorLimit)}{" "}
                    {t("CREATE_ASSESSMENT.TEXT_EDITOR_LIMIT")}
                  </p>
                )}
              </div>
            </div>
          )}
          {isJobScanning && (
            <div className="jobScanDetails">
              <ScanningDetails
                scrollingRef={scrollToNextSec}
                ref={scanningDetailsRef}
                jobDetailsData={jobDetailsData}
              />
            </div>
          )}
          <div
            className={`jdPopupFooter mt-3 mb-3 ${isJobScanning && "justify-content-end"}`}
          >
            {!isJobScanning && (
              <div className="availableBalance">
                <div className="balance">
                  <CreditCoin width={30} height={30} />
                  <span>{getSessionStorageItem("accountBalance")}</span>
                </div>
                <span>{t("RESUME.SCANNING_AVAILABLE_BALANCE")}</span>
              </div>
            )}
            <div className="saveBtn ">
              {!isJobScanning && (
                <div
                  onClick={() => setIsRequiredCredit(!isRequiredCredit)}
                  className="requiredCredit "
                >
                  <div>
                    <div className="required">
                      <CreditCoin width={20} height={20} />
                      <span>{addMoreCredit?.label}</span>
                    </div>
                    <span>{`${t("RESUME.AI_SCAN")} - ${addMoreCredit?.value} ${t("RESUME.SCANNING_CVS")}`}</span>
                  </div>
                  <span className={isRequiredCredit ? "upArrow" : "downArrow"}>
                    <DownArrow />
                  </span>
                  {isRequiredCredit && (
                    <div className="addMoreCredit">
                      <AddCreditForCv
                        isBtnShow={false}
                        handleSelectedCredit={handleMoreCredit}
                      />
                    </div>
                  )}
                </div>
              )}
              {!isJobScanning && (
                <AssessmentButton
                  disabled={
                    !isTextareaValid() ||
                    loading ||
                    !(description?.length > 0 && addMoreCredit?.value > 0)
                  }
                  onClick={() => {
                    handleJobScanning();
                  }}
                >
                  {loading ? (
                    <div className="scanJobLoader">
                      <p>{t("GENERAL.PROCESSING")}</p>
                      <Loader />
                    </div>
                  ) : (
                    t("RESUME.SCANNING_PAY_AND_SCAN")
                  )}
                </AssessmentButton>
              )}
              {isJobScanning && (
                <div className="creditAndCreateJob">
                  <div className="haveCvs">
                    <span>
                      You can scan {jobDetailsData?.balance} CVs in this Job
                    </span>
                  </div>
                  <div>
                    <AssessmentButton
                      disabled={loading}
                      onClick={() => {
                        handleCreateJob();
                      }}
                    >
                      {loading ? (
                        <div className="scanJobLoader">
                          <p>{t("GENERAL.PROCESSING")}</p>
                          <Loader />
                        </div>
                      ) : (
                        t("RESUME.SCANNING_CREATE_JOB")
                      )}
                    </AssessmentButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JobDescription;
