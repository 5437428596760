import {
  // Bullets,
  InputField,
  MultiSelector,
  RangeSelector,
  SearchTypeSelect,
  Tooltip,
} from "ui-components";
import "./ScanningDetails.scss";
import { useTranslation } from "react-i18next";
import {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import AddressField from "./AddressField/AddressField";
import { systemPreferenceContext } from "../../../../context/SystemPreference/SystemPreference";
import useDebounce from "../../../../utils/Hooks/useDebounce";
import { getSessionStorageItem } from "../../../../utils/SessionStorageMethod/getSessionStorageItem";
import { getDefaultSkill } from "../../../../service/Scope/Scope";
import { setSessionStorageItem } from "../../../../utils/SessionStorageMethod/setSessionStorageItem";
import CircleCrossIcon from "../../../../utils/Images/CircleCrossIcon";
import VisibilityType from "./VisibilityType/VisibilityType";
import EngagementType from "../../../Assessment/sections/AssessmentSetting/AdvanceSetting/EngagementType";
import JobTypeSelector from "../../../Assessment/sections/AssessmentSetting/AdvanceSetting/JobTypeSelector";
import { forwardRef } from "react";
import {
  capitalizeFirstLetter,
  expandText,
  getTruncatedText,
  updateSessionStorage,
} from "../../../../utils/Common/Constant";
const ScanningDetails = forwardRef(
  ({ jobDetailsData, scrollingRef }: any, ref) => {
    const { t } = useTranslation();
    const { preferenceData } = useContext(systemPreferenceContext);
    const [getSkillList, setGetSkillList] = useState([]);
    const [keywordText, setKeywordText] = useState<string>("");
    const jobKewords = getSessionStorageItem("job-keywords");
    const jobKewordsArr = jobKewords && jobKewords?.split(",");
    const [addedKeywordText, setAddedKeywordText] = useState<any>(
      jobKewordsArr || [],
    );
    const defaultSelectSkills = { value: "", label: "" };
    const [addedSkillList, setAddedSkillList] = useState<any>([]);
    const jdText =
      jobDetailsData.description || getSessionStorageItem("jdText") || "-";
    const [isExpanded, setIsExpanded] = useState(false);
    const descriptionRef = useRef<any>(null);
    const [titleText, setTitleText] = useState<string>(
      jobDetailsData?.title || "",
    );
    const [skillIdFromSelect, setSkillIdFromSelect] =
      useState<any>(defaultSelectSkills);
    const debounceSkillSearchValue = useDebounce(
      skillIdFromSelect?.label?.trim(),
      400,
    );
    const [isAddSkill, setIsAddSkill] = useState(false);
    const [jobType, setJobType] = useState(() => {
      const savedData = sessionStorage.getItem("jobType");
      return savedData
        ? JSON.parse(savedData)
        : {
            jobType: jobDetailsData?.jobType || "fullTime",
            engagementType: jobDetailsData?.engagementType || "onSite",
            visibility: jobDetailsData?.visibility || "public",
          };
    });
    const [experienceMinValue, setExperienceMinValue] = useState<number>(
      preferenceData?.createAssessment?.minimumExperience !== undefined
        ? getSessionStorageItem("minimumExperience") === null
          ? Number(preferenceData.createAssessment.minimumExperience)
          : getSessionStorageItem("minimumExperience")
        : getSessionStorageItem("minimumExperience"),
    );
    const [experienceMaxValue, setExperienceMaxValue] = useState<number>(
      preferenceData?.createAssessment?.maximumExperience !== undefined
        ? getSessionStorageItem("maximumExperience") ||
            Number(preferenceData.createAssessment.maximumExperience)
        : getSessionStorageItem("maximumExperience"),
    );
    const [noOfPositions, setNoOfPositions] = useState<number>(
      preferenceData?.createAssessment?.noOfPositions !== undefined
        ? getSessionStorageItem("noOfPositions") ||
            Number(preferenceData.createAssessment.noOfPositions)
        : getSessionStorageItem("noOfPositions"),
    );
    const [addressField, setAddressField] = useState(() => ({
      addressLine1:
        getSessionStorageItem("jobAddress1") ||
        jobDetailsData?.addressLine1 ||
        "",
      addressLine2:
        getSessionStorageItem("jobAddress2") ||
        jobDetailsData?.addressLine2 ||
        "",
      country:
        getSessionStorageItem("selectedJobCountry")?.value ||
        jobDetailsData?.country ||
        "",
      state:
        getSessionStorageItem("selectedJobState")?.value ||
        jobDetailsData?.state ||
        "",
      city: getSessionStorageItem("jobCity") || jobDetailsData?.city || "",
      zipcode:
        getSessionStorageItem("jobZipcode") || jobDetailsData?.zipcode || "",
    }));

    // Update states if jobDetailsData changes dynamically
    useEffect(() => {
      if (jobDetailsData?.title) setTitleText(jobDetailsData?.title);
      if (jobDetailsData?.location)
        setJobType((prev: any) => ({
          ...prev,
          location: jobDetailsData?.location,
        }));
      if (jobDetailsData?.jobType)
        setJobType((prev: any) => ({
          ...prev,
          jobType: jobDetailsData?.jobType,
        }));
      if (jobDetailsData?.engagementType)
        setJobType((prev: any) => ({
          ...prev,
          engagementType: jobDetailsData?.engagementType,
        }));
      if (jobDetailsData?.visibility)
        setJobType((prev: any) => ({
          ...prev,
          visibility: jobDetailsData?.visibility ?? "public",
        }));

      if (jobDetailsData) {
        setAddressField({
          addressLine1:
            getSessionStorageItem("jobAddress1") ||
            jobDetailsData?.addressLine1 ||
            "",
          addressLine2:
            getSessionStorageItem("jobAddress2") ||
            jobDetailsData?.addressLine2 ||
            "",
          country:
            getSessionStorageItem("selectedJobCountry")?.value ||
            jobDetailsData?.country ||
            "",
          state:
            getSessionStorageItem("selectedJobState")?.value ||
            jobDetailsData?.state ||
            "",
          city: getSessionStorageItem("jobCity") || jobDetailsData?.city || "",
          zipcode:
            getSessionStorageItem("jobZipcode") ||
            jobDetailsData?.zipcode ||
            "",
        });
      }
      if (jobDetailsData?.jobSkills?.length) {
        setAddedSkillList(jobDetailsData?.jobSkills);
      }
    }, [jobDetailsData]);
    // for skills
    useEffect(() => {
      // to get skills
      async function getSkills() {
        const industriesSkillId = localStorage.getItem("industryId") ?? "";
        const industriesSkill = await getDefaultSkill(
          industriesSkillId,
          debounceSkillSearchValue,
          1,
          100000,
        );

        const updateData = (industriesSkill?.data || [])
          .filter((item: any) => item?.questionType === "Video")
          .map((item: any) => ({
            value: item?.skillId,
            label: `${item?.skillName}`,
            ...item,
          }));
        setGetSkillList(updateData);
      }
      getSkills();
    }, [debounceSkillSearchValue]);

    const handleValue = (value: number, setFn: any, name: string) => {
      setFn(value);
      setSessionStorageItem(name, value);
    };
    const handleAddedKeyword = (e: any) => {
      if (e.key === "Enter") {
        e.preventDefault(); // Prevent default form submission on Enter key
        setAddedKeywordText((prev: any) => [...prev, keywordText]);
        setKeywordText("");
      }
    };
    const handleDeleteAddedKeyword = (ind: number) => {
      setAddedKeywordText((prevData: any) => {
        const newData = prevData.filter(
          (_: any, index: number) => index !== ind,
        );
        return newData;
      });
    };
    const handleAddressField = (e: any, name: string) => {
      setAddressField((prev) => ({ ...prev, [name]: e }));
    };
    // Expose methods to the parent through the ref
    useImperativeHandle(ref, () => ({
      getUpdatedData: () => ({
        title: titleText,
        ...addressField,
        ...jobType,
        jobId: jobDetailsData?.jobId,
        description: jobDetailsData?.description,
        noOfPositions: noOfPositions,
        minimumExperience: experienceMinValue,
        maximumExperience: experienceMaxValue,
        status: "Active",
        keywords: addedKeywordText?.join(","),
        zipcode: getSessionStorageItem("jobZipcode"),
        geoCordinates: [
          getSessionStorageItem("jobCoords")?.lat ?? "67637263",
          getSessionStorageItem("jobCoords")?.lng ?? "7836326838",
        ],
        jobSkills: addedSkillList ?? [],
      }),
    }));
    const handleDeleteSkillList = (ind: number) => {
      setAddedSkillList((prevData: any) => {
        const newData = prevData.filter(
          (item: any) => (item?.jobSkillId ?? item?.skillId) !== ind,
        );
        return newData;
      });
    };
    // useEffect to set selected skills in session storage
    useEffect(() => {
      updateSessionStorage("jobSkills", addedSkillList);
    }, [addedSkillList]);
    // useEffect to set selected jobType in session storage
    useEffect(() => {
      updateSessionStorage("visibility", jobType?.visibility);
      updateSessionStorage("engagementType", jobType?.engagementType);
      updateSessionStorage("jobType", jobType?.jobType);
    }, [jobType]);
    useEffect(() => {
      // updateSessionStorage("keywords", addedKeywordText?.join(","))
      setSessionStorageItem("job-keywords", addedKeywordText?.join(","));
    }, [addedKeywordText]);
    return (
      <div className="scanningDetails" ref={scrollingRef}>
        <div className="generateTitleText">
          <label className="titleLabel">{t("RESUME.JOB_TITLE")}</label>
          <InputField
            value={capitalizeFirstLetter(titleText)}
            onChange={(e: any) => {
              setTitleText(e);
              updateSessionStorage("title", e);
            }}
            placeholder={t("GENERAL.TITLE")}
            additionalProps={{
              className: "textInput",
            }}
          />
        </div>
        <div className="horizontalLine mb-3"></div>
        <div className="skillExtracted">
          <span className="extractedSkill">
            {t("RESUME.SCANNING_SCANNED_SKILLS")}
          </span>
          {isAddSkill ? (
            <SearchTypeSelect
              options={getSkillList}
              placeholder={t("GENERAL.SKILLS")}
              value={skillIdFromSelect}
              onChange={(getValue: any) => {
                setSkillIdFromSelect(getValue);
                if (getValue?.value) {
                  setAddedSkillList((prev: any) => {
                    // Check if the skillId already exists in the list
                    const isDuplicate = prev.some(
                      (item: any) => item?.value === getValue?.value,
                    );
                    if (!isDuplicate) {
                      return [...prev, getValue];
                    }
                    return prev; // Return the same list if it"s a duplicate
                  });
                }
              }}
              loadingText={t("GENERAL.LOADING")}
              className="skillAssessList"
              liIconName="icon"
            />
          ) : (
            <div onClick={() => setIsAddSkill(true)} className="addSkill">
              <p> {t("RESUME.SCANNING_ADD_SKILLS")}</p>
            </div>
          )}
        </div>
        <div className="addedSkill">
          {addedSkillList?.map((item: any, ind: number) => {
            return (
              <div key={ind} className="word">
                <span>{item?.skillName}</span>
                <div
                  onClick={() =>
                    handleDeleteSkillList(item?.jobSkillId ?? item?.skillId)
                  }
                  className="deleteKeyword"
                >
                  <CircleCrossIcon
                    color="var(--primary-white)"
                    width="16px"
                    height="16px"
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="horizontalLine"></div>
        <div className="keywords mt-4 mb-5">
          <div className="titleIcon">
            <span>{t("RESUME.SCANNING_ADD_KEYWORDS")}</span>
            <div className="iIcon">
              <Tooltip
                classname="tooltipOverCss"
                text={t(t("RESUME.ASSESSMENT_YOU_CAN_ADD_OWN_KEYWORDS"))}
              />
            </div>
          </div>

          <div className="generateKeywordText mt-4">
            <InputField
              value={keywordText}
              onChange={(e: any) => setKeywordText(e)}
              placeholder={t("RESUME.SCANNING_KEYWORDS")}
              additionalProps={{
                className: "textInput",
              }}
              handleKeyPress={(e: any) => {
                if (e.target.value) {
                  handleAddedKeyword(e);
                }
              }}
            />
          </div>
          <div className="sarchedKeyword mt-3">
            {addedKeywordText?.map((item: any, ind: number) => {
              return (
                <div key={ind} className="word">
                  <span>{item}</span>
                  <div
                    onClick={() => handleDeleteAddedKeyword(ind)}
                    className="deleteKeyword"
                  >
                    <CircleCrossIcon
                      color="var(--primary-white)"
                      width="16px"
                      height="16px"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="horizontalLine"></div>
        <div className="rangeCss">
          <div className="childSection">
            <VisibilityType setJobType={setJobType} jobType={jobType} />
          </div>
          <div className="typeCss">
            <EngagementType setJobType={setJobType} jobType={jobType} />
          </div>
        </div>
        <div className="rangeCss">
          <div className="childSection">
            <MultiSelector
              id="on234dds324ccsfsdf4e"
              label={`${t("RESUME.SCANNING_EXPERIENCE")}`}
              minLabel="0"
              maxLabel="60"
              min={0}
              max={60}
              value={experienceMinValue}
              setValue={(value) => {
                handleValue(value, setExperienceMinValue, "minimumExperience");

                updateSessionStorage("minimumExperience", value);
              }}
              maxValue={experienceMaxValue}
              setMaxValue={(value) => {
                handleValue(value, setExperienceMaxValue, "maximumExperience");
                updateSessionStorage("maximumExperience", value);
              }}
            />
          </div>
          <div className="typeCss">
            <JobTypeSelector setJobType={setJobType} jobType={jobType} />
          </div>
        </div>
        <div className="rangeCss">
          <div className="childSection">
            <RangeSelector
              id="two6547b434dgtrb4564"
              label={`${t("RESUME.SCANNING_OPEN_POSITIONS")}`}
              minLabel={"01"}
              maxLabel={500}
              step={1}
              min={1}
              max={500}
              value={noOfPositions}
              setValue={(value) => {
                handleValue(value, setNoOfPositions, "noOfPositions");
                updateSessionStorage("noOfPositions", value);
              }}
            />
          </div>
          <div className="typeCss"></div>
        </div>
        <div className="horizontalLine"></div>
        <AddressField
          addressField={addressField}
          handleAddressField={handleAddressField}
        />
        <div className="horizontalLine mt-5"></div>
        <div className="description mt-5">
          <p className="descriptionTitle">{t("RESUME.SCANNING_DESCRIPTION")}</p>
          <div
            ref={descriptionRef}
            dangerouslySetInnerHTML={{
              __html: isExpanded ? jdText : getTruncatedText(jdText, 25),
            }}
          />
          {jdText.length > 0 && (
            <span
              onClick={() =>
                expandText(descriptionRef, setIsExpanded, isExpanded)
              }
              className="readMoreBtn"
            >
              {isExpanded ? "Read Less" : "Read More"}
            </span>
          )}
        </div>
      </div>
    );
  },
);
ScanningDetails.displayName = "ScanningDetails";
export default ScanningDetails;
