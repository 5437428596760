const DeleteIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.625 8.55556V14.2222M10.375 8.55556V14.2222M1 4.77778H16M2.875 4.77778V15.1667C2.875 16.7315 4.1342 18 5.6875 18H11.3125C12.8658 18 14.125 16.7315 14.125 15.1667V4.77778H2.875ZM5.6875 2.88889C5.6875 1.84568 6.52697 1 7.5625 1H9.4375C10.4731 1 11.3125 1.84568 11.3125 2.88889V4.77778H5.6875V2.88889Z"
        stroke="#C5221F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeleteIcon;
