const PlusIcon = ({ color }: any) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8276 30V0H17.1724V30H12.8276ZM0 17.1226V12.9481H30V17.1226H0Z"
        fill={color ? color : "#4D4D4D"}
      />
    </svg>
  );
};

export default PlusIcon;
