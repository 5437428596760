import { useContext, useEffect, useState } from "react";
import DeleteIcon from "../../../../../../../../utils/Images/AddSource/DeleteIcon";
import SettingIcon from "../../../../../../../../utils/Images/AddSource/SettingIcon";

import "./SourceList.scss";
import PlusIcon from "../../../../../../../../utils/Images/PlusIcon";
import {
  AssessmentButton,
  InputField,
  showToast,
  SourceCard,
} from "ui-components";
import CustomModal from "../../../../../../../../components/CustomModal/CustomModal";
import {
  capitalizeFirstLetter,
  convertDate,
  setInQueryParams,
} from "../../../../../../../../utils/Common/Constant";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EvluateShortIcon from "../../../../../../../../utils/Images/EvluateShortIcon";
import RefreshIconBorderLess from "../../../../../../../../utils/Images/RefreshIconBorderLess";
import WarningTriangle from "../../../../../../../../utils/Images/WarningTriangle";
import {
  addSource,
  deleteSource,
  getSourcesList,
  refreshJobIntegration,
} from "../../../../../../../../service/CvScanning/CvScanning";
import Loader from "../../../../../../../../components/Loader/Loader";
import { systemPreferenceContext } from "../../../../../../../../context/SystemPreference/SystemPreference";
import getRefreshToken from "../GmailAuth";
const SourceList = ({
  handleRefreshSystemCVs,
  refreshRequired,
  databaSesourceRefreshing,
}: any) => {
  const [sourceList, setSourceList] = useState<any>([]);
  const queryParams = new URLSearchParams(location.search);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isOpenAddSource, setIsOpenAddSource] = useState(
    searchParams.get("isOpenAddSource") === "true" || false,
  );
  const [sourceData, setSourceData] = useState<any>([]);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [selectedSourceCard, setSelectedSourceCard] = useState<any>({});
  const [updateSourceList, setUpdateSourceList] = useState(0);
  const [addSourceLoading, setAddSourceLoading] = useState(false);
  const [sourceRefreshing, setSourceRefreshing] = useState(false);

  const { preferenceData } = useContext(systemPreferenceContext);
  const { t } = useTranslation();
  const [flippedIndex, setFlippedIndex] = useState(null);
  const [aliasInputText, setAliasInputText] = useState("");
  const handleSourceClick = (item: number) => {
    setSelectedSource(item);
  };
  const { jobId } = useParams();
  const addSourceApi = async (addSourceData: any) => {
    setAddSourceLoading(true);
    try {
      const res = await addSource(addSourceData);
      if (res?.status === 200) {
        showToast(res?.customMessage, "success");
        setInQueryParams(queryParams, "isOpenAddSource", false, navigate);
        setIsOpenAddSource(false);
        setUpdateSourceList(Math.random() * 10);
        setAddSourceLoading(false);
        setAliasInputText("");
      } else {
        setAddSourceLoading(false);
      }
    } catch (error) {
      setAddSourceLoading(false);
      console.error(error);
    }
  };
  // Add source card Api
  const handleAddSource = async () => {
    if (selectedSource?.label === "email" || selectedSource?.label === "Gmail") {
      const response: any = await getRefreshToken();
      if (!response?.error) {
        const splitUrl = selectedSource?.icon?.split("/interviews/");
        const trimIconUrl =
          splitUrl?.length > 1 ? `interviews/${splitUrl[1]}` : "";
        const addSourceData = {
          jobId: jobId,
          type: "email",
          meta: {
            access_token: response?.tokenData?.access_token,
            expires_in: response?.tokenData?.expires_in,
            refresh_token: response?.tokenData?.refresh_token,
            scope: response?.tokenData?.scope,
            token_type: response?.tokenData?.token_type,
          },
          name: aliasInputText,
          provider: "Gmail",
          sourceIcon: trimIconUrl,
          emailId: response?.userInfo?.email,
        };
        addSourceApi(addSourceData);
      } else {
        showToast(t("RESUME.TRY_AGAIN"));
      }
    } else {
      showToast(t("RESUME.ASSESSMENT_COMING_SOON"));
    }
  };
  useEffect(() => {
    if (preferenceData?.jobSources) {
      const result = Object.keys(preferenceData?.jobSources).map(
        (key, index) => ({
          id: index,
          label: key,
          icon: preferenceData?.jobSources[key],
        }),
      );
      setSourceData(result);
    }
  }, [preferenceData]);
  const getSourcesListApi = async () => {
    try {
      const res = await getSourcesList(jobId);
      setSourceList(res?.data);
    } catch (error) {
      console.error(error);
    }
  };

  // refresh job integration api
  const refreshJobIntegrationApi = async (item: any) => {
    setSourceRefreshing(true);
    try {
      const res = await refreshJobIntegration(jobId, item?.jobIntegrationId);
      if (res?.status === 200) {
        setSourceRefreshing(false);
        showToast(res?.customMessage, "success");
        setUpdateSourceList(Math.random() * 10);
      } else {
        setSourceRefreshing(false);
      }
    } catch (error) {
      console.error(error);
      setSourceRefreshing(false);
    }
  };
  useEffect(() => {
    getSourcesListApi();
  }, [updateSourceList]);
  const confirmDeleteSource = async () => {
    try {
      const res = await deleteSource(
        jobId,
        selectedSourceCard?.jobIntegrationId,
      );
      if (res?.status === 200) {
        setFlippedIndex(null);
        showToast(res?.customMessage, "success");
        setUpdateSourceList(Math.random() * 10);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeletePress = (item: any, ind: any) => {
    setSelectedSourceCard(item);
    setFlippedIndex(ind === flippedIndex ? null : ind); // Toggle flip state for the card
  };
  return (
    <div className="sourceList">
      <div
        onClick={() => {
          setIsOpenAddSource(true);
          setInQueryParams(queryParams, "isOpenAddSource", true, navigate);
        }}
        className="subSource"
      >
        <PlusIcon color={"var(--light-grey)"} />
        <span>{t("RESUME.ADD_SOURCE")}</span>
      </div>
      <div>
        <SourceCard
          index={0}
          label={t("GENERAL.PROJECT_NAME")}
          icon={<EvluateShortIcon />}
          email={t("RESUME.SCAN_EXISTING_DATABASE")}
          className={`${!refreshRequired ? "" : "isRefreshBtn"} databaseSourceCard`}
          dateTime={""}
          onCardClick={handleRefreshSystemCVs}
          iconWithDate={
            <RefreshIconBorderLess
              isDisable={
                refreshRequired ? "var(--primary-green)" : "var(--light-grey)"
              }
            />
          }
          isLeftIconShouldRotate={refreshRequired && databaSesourceRefreshing}
        />
      </div>
      {sourceList?.data?.map((item: any, ind: number) => {
        return (
          <div key={ind}>
            <SourceCard
              index={ind}
              label={capitalizeFirstLetter(item?.provider)}
              email={item?.identifier ? item?.identifier : "-"}
              alias={item?.name ? capitalizeFirstLetter(item?.name) : "-"}
              icon={item?.icon}
              dateTime={
                item?.lastRefreshed
                  ? convertDate(item?.lastRefreshed, false, true)
                  : "-"
              }
              SettingIcon={<SettingIcon />}
              DeleteIcon={<DeleteIcon />}
              iconWithDate={
                !item?.isActive ? (
                  <WarningTriangle />
                ) : (
                  <RefreshIconBorderLess />
                )
              }
              onDeletePress={() => handleDeletePress(item, ind)}
              className={`${flippedIndex === ind ? "flipped" : ""}`}
              isLeftIconShouldRotate={sourceRefreshing}
              deleteClicked={flippedIndex === ind}
              confirmationText={`${t("GENERAL.COMFIRMATION_REMOVE_SOURCE")}`}
              buttonNameLeft={t("GENERAL.YES")}
              buttonNameRight={t("GENERAL.NO")}
              onConfirmLeftButtonClick={() => setFlippedIndex(null)}
              onConfirmRightButtonClick={confirmDeleteSource}
              onRefreshClick={() => refreshJobIntegrationApi(item)}
            />
          </div>
        );
      })}
      <CustomModal
        show={isOpenAddSource}
        isCrossBtn={true}
        isHeaderVisible={true}
        instructionHeadTitle="Add Source"
        handleClose={() => {
          setInQueryParams(queryParams, "isOpenAddSource", false, navigate);
          setIsOpenAddSource(false);
        }}
        className="addSourceModal"
        size="lg"
        fullscreen="md-down"
        isFooterVisible={false}
      >
        <div className="addSourcePopup">
          <div className="addSourceList">
            {sourceData?.map((item: any) => {
              return (
                <div
                  title={capitalizeFirstLetter(item?.label)}
                  className={`iconSource ${selectedSource?.id === item.id ? "selected" : ""
                    }`}
                  key={item.id}
                  onClick={() => handleSourceClick(item)}
                >
                  <img src={item?.icon} alt="Icon" />
                  <span>{item?.label}</span>
                </div>
              );
            })}
          </div>
          <div className="addSourceButton">
            <div className="aliasInput">
              <InputField
                placeholder="Give name to source"
                onChange={(e: any) => setAliasInputText(e)}
                value={aliasInputText}
              />
            </div>
            <AssessmentButton
              disabled={
                addSourceLoading || selectedSource === null || !aliasInputText
              }
              onClick={() => handleAddSource()}
            >
              {addSourceLoading ? (
                <div className="addSourceLoader">
                  <p>{t("GENERAL.PROCESSING")}</p>
                  <Loader />
                </div>
              ) : (
                t("RESUME.ADD_SOURCE")
              )}
            </AssessmentButton>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};
export default SourceList;
