import { useEffect, useState, ReactNode } from "react";
import "./DetailsPageWrapper.scss";
import BackArrow from "../../../../utils/Images/BackArrow";
import { capitalizeFirstLetter } from "../../../../utils/Common/Constant";
import { useTranslation } from "react-i18next";
import Footer from "../../../../components/Footer/Footer";
import { AssessmentButton, Bullets, showToast } from "ui-components";
import Header from "../../../../components/Assessment/Header/Header";
import FileUpload from "./SidebarTab/FileUpload/FileUpload";
import { updateJobStatus } from "../../../../service/CvScanning/CvScanning";
import { useNavigate } from "react-router-dom";
interface DetailsPagesProps {
  jobDetails: any;
  onFilesUploaded?: any;
  fileUploadData?: any;
  setFileProgressList?: any;
  fileProgressList?: any;
  children?: ReactNode; // Accept children as a prop
}

const DetailsPageWrapper: React.FC<DetailsPagesProps> = ({
  onFilesUploaded,
  fileUploadData,
  setFileProgressList,
  fileProgressList,
  children,
  jobDetails,
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const [assessmentDetail, setAssessmentDetail] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);
  useEffect(() => {
    setAssessmentDetail(jobDetails);
  }, [jobDetails?.jobId]);
  const handleCloseJob = async () => {
    setLoading(true);
    const updateJobRes = await updateJobStatus({
      jobId: jobDetails?.jobId,
      status: "Closed",
    });
    if (updateJobRes?.data?.status === 200) {
      setLoading(false);
      showToast(updateJobRes?.data?.customMessage, "success");
    }
  };
  return (
    <>
      <Header />
      <div className="detailsPages container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="skillsNameSec">
              <div className="arrowTitle">
                <div
                  className="backArrow"
                  onClick={() => navigate(`/${currentLanguage}/jobs`)}
                >
                  <BackArrow />
                </div>
                <h3>{capitalizeFirstLetter(assessmentDetail?.title)}</h3>
              </div>
              <div className="d-flex gap-2">
                <div className="closeJob">
                  <AssessmentButton
                    disabled={loading}
                    className="lightBtn"
                    onClick={() => handleCloseJob()}
                  >
                    {t("RESUME.SCANNING_CLOSE_JOB")}
                  </AssessmentButton>
                </div>
                {assessmentDetail?.status === "Active" ? (
                  <div className="d-flex gap-3 wrapBtns">
                    <FileUpload
                      onFilesUploaded={onFilesUploaded}
                      fileUploadData={fileUploadData}
                      setFileProgressList={setFileProgressList}
                      fileProgressList={fileProgressList}
                      isChildrenUsed={true}
                    >
                      <AssessmentButton className="lightBtn">
                        {t("RESUME.SCANNING_UPLOAD_CV")}
                      </AssessmentButton>
                    </FileUpload>
                  </div>
                ) : assessmentDetail?.notAssessment ? (
                  <div className="assessmentCloseStatus">
                    <span>{t("GENERAL.ASSESSMENT_CLOSED")}</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="skillsNames">
              <div className="d-flex align-items-center">
                <div className="link_wrap">
                  <p className="acc_style04">
                    {capitalizeFirstLetter(assessmentDetail?.visibility)}
                  </p>
                </div>
                <span>{assessmentDetail?.assessmentType}</span>
              </div>
              <div className="bulletSec">
                {assessmentDetail?.jobSkills?.map((item: any, ind: number) => {
                  return (
                    <div key={ind}>
                      <Bullets
                        skillName={capitalizeFirstLetter(item?.skillName)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {children && <div className="custom-children-section">{children}</div>}{" "}
        <Footer />
      </div>
    </>
  );
};

export default DetailsPageWrapper;
