import "./ComingSoon.scss";

const ComingSoon = ({ text }: any) => {
  return (
    <div className="comingSoon">
      <span>{text}</span>
    </div>
  );
};

export default ComingSoon;
