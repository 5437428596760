import { useEffect, useState } from "react";
import "./PackageDetailsTable.scss";
import { getSessionStorageItem } from "../../../../../utils/SessionStorageMethod/getSessionStorageItem";
// import { AssessmentButton } from "ui-components";
import {
  countCreditCheck,
  finalDiscountCredit,
} from "../../../../../utils/Common/Constant";
import CreditCoin from "../../../../../utils/Images/CreditCoin";
import { useTranslation } from "react-i18next";
// import { systemPreferenceContext } from "../../../../../context/SystemPreference/SystemPreference";

const PackageDetailsTable = ({
  // totalTableCredit,
  assessmentQuestionSet,
}: any) => {
  // const { preferenceData } = useContext(systemPreferenceContext);
  const [newData, setNewData] = useState<any>([]);
  const [totalQuestion, setTotalQuestion] = useState<any>(0);
  const [grossTotal, setGrossTotal] = useState<any>(0);
  const { t } = useTranslation();
  const [isPriceCal, setIsPriceCal] = useState<any>(false);
  const evaluationAiChecks = getSessionStorageItem("evaluation") ?? [];
  const UpdateEvaluation = evaluationAiChecks
    .filter((item: any) => item.type === "smartAi")
    .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {
      type: "smartAi",
    });
  const paymentDiscountPackage =
    getSessionStorageItem("paymentData")?.discountPacakgeDetails ?? {};
  const renderHeader = () => {
    const headerElement = [
      "Type",
      "Per Question Credits",
      "Total Questions",
      "Total",
    ];
    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const customMCQ = {
        type: "Custom",
        unit: 0,
        totalQuestion: 0,
        credit: 0,
      };
      const {
        videoType,
        codingType,
        mcqEssayType,
        singleCustomType,
        multipleCustomType,
        essayCustomType,
        videoCustomType,
      } = countCreditCheck(
        assessmentQuestionSet,
        getSessionStorageItem("paymentData") ?? [],
      );

      let newData = [
        videoType,
        codingType,
        mcqEssayType,
        singleCustomType,
        multipleCustomType,
        essayCustomType,
        videoCustomType,
      ]
        .map((item: any) => {
          if (["Single", "Multiple", "Essay"].includes(item.type)) {
            customMCQ.unit = item?.unit;
            customMCQ.totalQuestion += item?.totalQuestion;
            customMCQ.credit += item.credit;
            return null;
          }
          return item;
        })
        .filter((item: any) => item !== null);

      newData.push(customMCQ);
      newData = newData.filter((item: any) => item.totalQuestion !== 0);
      // Calculate total number of questions and cross total
      const totalQuestions = newData.reduce(
        (acc: number, item: any) => acc + item?.totalQuestion,
        0,
      );
      const crossTotal = newData.reduce(
        (acc: number, item: any) => acc + item?.credit,
        0,
      );
      setNewData(newData);
      setTotalQuestion(totalQuestions);
      setGrossTotal(crossTotal);
    }, 1000); // 1000 ms interval = 1 second

    return () => clearInterval(interval); // Clear the interval when the component is unmounted or dependencies change
  }, []);

  const checkAiEnable = (aiData: any) => {
    const paymentPackage =
      getSessionStorageItem("paymentData")?.packageDetails ?? {};

    const { aiAnalysis, visualAnalysis, audioAnalysis } = aiData;
    if (aiAnalysis && visualAnalysis && audioAnalysis) {
      const videoCredit =
        paymentPackage?.creditForAIAnalysisVideoQuestion +
        paymentPackage?.creditForManualAnalysisVideoQuestion +
        paymentPackage?.creditForVisualAnalysisVideoQuestion +
        paymentPackage?.creditForVoiceAnalysisVideoQuestion;
      return {
        creditUnit: videoCredit,
        creditText: "(Manual+AI+Visual+Voice)",
        creditUnitText: `${paymentPackage?.creditForManualAnalysisVideoQuestion} + ${paymentPackage?.creditForAIAnalysisVideoQuestion} + ${paymentPackage?.creditForVisualAnalysisVideoQuestion} + ${paymentPackage?.creditForVoiceAnalysisVideoQuestion} = ${videoCredit}`,
      };
    } else if (aiAnalysis && visualAnalysis) {
      const videoCredit =
        paymentPackage?.creditForAIAnalysisVideoQuestion +
        paymentPackage?.creditForManualAnalysisVideoQuestion +
        paymentPackage?.creditForVisualAnalysisVideoQuestion;
      return {
        creditUnit: videoCredit,
        creditText: "(Manual+AI+Visual)",
        creditUnitText: `${paymentPackage?.creditForManualAnalysisVideoQuestion} + ${paymentPackage?.creditForAIAnalysisVideoQuestion} + ${paymentPackage?.creditForVisualAnalysisVideoQuestion} = ${videoCredit}`,
      };
    } else if (aiAnalysis && audioAnalysis) {
      const videoCredit =
        paymentPackage?.creditForAIAnalysisVideoQuestion +
        paymentPackage?.creditForManualAnalysisVideoQuestion +
        paymentPackage?.creditForVoiceAnalysisVideoQuestion;
      return {
        creditUnit: videoCredit,
        creditText: "(Manual+AI+Voice)",
        creditUnitText: `${paymentPackage?.creditForManualAnalysisVideoQuestion} + ${paymentPackage?.creditForAIAnalysisVideoQuestion} + ${paymentPackage?.creditForVoiceAnalysisVideoQuestion} = ${videoCredit}`,
      };
    } else if (aiAnalysis) {
      const videoCredit =
        paymentPackage?.creditForAIAnalysisVideoQuestion +
        paymentPackage?.creditForManualAnalysisVideoQuestion;
      return {
        creditUnit: videoCredit,
        creditText: "(Manual+AI)",
        creditUnitText: `${paymentPackage?.creditForManualAnalysisVideoQuestion} + ${paymentPackage?.creditForAIAnalysisVideoQuestion} = ${videoCredit}`,
      };
    } else {
      const videoCredit = paymentPackage?.creditForManualAnalysisVideoQuestion;
      return {
        creditUnit: videoCredit,
        creditText: "(Manual)",
        creditUnitText: `${paymentPackage?.creditForManualAnalysisVideoQuestion}`,
      };
    }
  };

  const renderBody = () => {
    return (
      <>
        {newData?.map((item: any, index: number) => {
          return (
            <tr key={index}>
              {item?.type === "Video" ? (
                <td className="videoAi">
                  <span>{item?.type}</span>
                  <span>
                    {checkAiEnable(
                      UpdateEvaluation ?? {
                        aiAnalysis: false,
                        visualAnalysis: false,
                        audioAnalysis: false,
                      },
                    )?.creditText ?? ""}
                  </span>
                </td>
              ) : item?.type === "McqEssay" ? (
                <td>{t("CREATED_ASSESSMENT_MCQ")}</td>
              ) : item?.type === "CustomVideo" ? (
                <td>{t("CREATED_ASSESSMENT_CUSTOM_VIDEO")}</td>
              ) : (
                <td>{item?.type}</td>
              )}

              {item?.type === "Video" ? (
                <td>
                  {checkAiEnable(
                    UpdateEvaluation ?? {
                      aiAnalysis: false,
                      visualAnalysis: false,
                      audioAnalysis: false,
                    },
                  )?.creditUnitText
                    ? checkAiEnable(
                        UpdateEvaluation ?? {
                          aiAnalysis: false,
                          visualAnalysis: false,
                          audioAnalysis: false,
                        },
                      )?.creditUnitText
                    : 0}
                </td>
              ) : (
                <td>{item?.unit}</td>
              )}
              <td>{item?.totalQuestion}</td>

              {item?.type === "Video" ? (
                <td>{item?.credit}</td>
              ) : (
                <td>{item?.unit * item?.totalQuestion}</td>
              )}
            </tr>
          );
        })}
        <tr className="finalCalculationCredits">
          <td colSpan={1}></td>
          <td className="text-end" colSpan={1}>
            <strong>{t("CREATED_ASSESSMENT_TOTAL")}</strong>
          </td>
          <td colSpan={1}>
            <strong>{totalQuestion}</strong>
          </td>
          <td colSpan={1}>
            <strong>{grossTotal}</strong>
          </td>
        </tr>
        {/* <tr>
          <td colSpan={1}></td>
          <td colSpan={1} className="text-end">
            <strong>{t("CREATED_ASSESSMENT_LIMIT_RESPONSES")}</strong>
          </td>
          <td colSpan={1}>
            <strong>
              {getSessionStorageItem("maximumResponse") ||
                preferenceData?.createAssessment?.limitResponses}
            </strong>
          </td>
          <td colSpan={1}>
            <strong>{getSessionStorageItem("totalCountedCredit")}</strong>
          </td>
        </tr> */}
        {paymentDiscountPackage?.percentage > 0 && (
          <tr>
            <td colSpan={1}></td>
            <td colSpan={1} className="text-end">
              <strong>{t("CREATED_ASSESSMENT_DISCOUNT")}</strong>
            </td>
            <td colSpan={1}></td>
            <td colSpan={1}>
              <strong>{paymentDiscountPackage?.percentage}%</strong>
            </td>
          </tr>
        )}
        <tr>
          <td colSpan={1}></td>
          <td colSpan={1} className="text-end">
            <strong>{t("CREATED_ASSESSMENT_GRAND_TOTAL")}</strong>
          </td>
          <td colSpan={1}></td>
          <td colSpan={1}>
            <strong>
              {finalDiscountCredit() === 0
                ? getSessionStorageItem("totalCountedCredit")
                : finalDiscountCredit()}
            </strong>
          </td>
        </tr>
      </>
    );
  };

  return (
    <div>
      <div className="priceCal">
        <h6
          onClick={() => setIsPriceCal(!isPriceCal)}
          className="cursorPointer"
        >
          {t("CREATED_ASSESSMENT_PRICE_CALCULATION")}
        </h6>
        <div className="d-flex flex-column gap-1 mb-3">
          <span className="showCredit">
            <div className="discountWithShowCredit">
              <CreditCoin width={28} height={28} />
              <ins>
                {finalDiscountCredit() === 0
                  ? getSessionStorageItem("totalCountedCredit")
                  : finalDiscountCredit()}
              </ins>
              <del>
                {finalDiscountCredit() === 0
                  ? null
                  : getSessionStorageItem("totalCountedCredit")}
              </del>
            </div>
          </span>
          <span
            onClick={() => setIsPriceCal(!isPriceCal)}
            className="showDetails"
          >
            {isPriceCal
              ? t("CREATED_ASSESSMENT_HIDE_DETAILS")
              : t("CREATED_ASSESSMENT_SHOW_DETAILS")}
          </span>
        </div>
      </div>
      {isPriceCal && (
        <table id="employee">
          <thead>
            <tr>{renderHeader()}</tr>
          </thead>
          <tbody>{renderBody()}</tbody>
        </table>
      )}
    </div>
  );
};

export default PackageDetailsTable;
