const WarningTriangle = () => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50061 2.5C4.77675 2.5 5.00061 2.72386 5.00061 3V5C5.00061 5.27614 4.77675 5.5 4.50061 5.5C4.22447 5.5 4.00061 5.27614 4.00061 5V3C4.00061 2.72386 4.22447 2.5 4.50061 2.5Z"
        fill="#FF9E12"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50061 6C4.77675 6 5.00061 6.22386 5.00061 6.5C5.00061 6.77614 4.77675 7 4.50061 7C4.22447 7 4.00061 6.77614 4.00061 6.5C4.00061 6.22386 4.22447 6 4.50061 6Z"
        fill="#FF9E12"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.13162 1.22817L1.04539 7.4025C0.908079 7.6772 1.10788 8 1.41437 8H7.58687C7.89338 8 8.09318 7.6772 7.95587 7.4025L4.86964 1.22817C4.71755 0.923962 4.2837 0.923964 4.13162 1.22817ZM3.23715 0.781034C3.75773 -0.260351 5.24353 -0.260339 5.7641 0.781033L8.85035 6.9554C9.31981 7.89458 8.63716 9 7.58687 9H1.41437C0.364087 9 -0.318545 7.89458 0.15091 6.9554L3.23715 0.781034Z"
        fill="#FF9E12"
      />
    </svg>
  );
};

export default WarningTriangle;
