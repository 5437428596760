import React from "react";

const CvIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9 11.9474H12.3667M8.9 15.7368H14.1M12.4285 2.02773V6.10527C12.4285 7.1517 13.2046 8.00001 14.1619 8.00001H17.8695M12.4285 2.02773C12.1333 2.009 11.824 2 11.5 2C6.52942 2 5 4.11764 5 11C5 17.8823 6.52942 20 11.5 20C16.4706 20 18 17.8823 18 11C18 9.87699 17.9593 8.8808 17.8695 8.00001M12.4285 2.02773L17.8695 8.00001"
        stroke="#4D4D4D"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CvIcon;
