import { AssessmentCheckboxRadio } from "ui-components";
import "./VisibilityType.scss";
import { useTranslation } from "react-i18next";
interface VisibilityType {
  setJobType: any;
  jobType: any;
}
const VisibilityType: React.FC<VisibilityType> = ({ setJobType, jobType }) => {
  const { t } = useTranslation();
  return (
    <div className="visibilityType">
      <span>{t("RESUME.SCANNING_VISIBILITY")}</span>
      <div className="visibilityTypeRow mt-3">
        <div
          className="d-flex visibilityTypeCursorPointer rtlGap"
          onClick={() => {
            setJobType((prev: any) => {
              const updatedData = { ...prev, visibility: "public" };
              sessionStorage.setItem(
                "advanceSettingData",
                JSON.stringify(updatedData),
              );
              return updatedData;
            });
          }}
        >
          <AssessmentCheckboxRadio
            name="public"
            type="radio"
            checked={jobType?.visibility == "public"}
          />
          <p className="radioCheckLabel">{t("GENERAL.PUBLIC")}</p>
        </div>
        <div
          className="d-flex visibilityTypeCursorPointer rtlGap"
          onClick={() => {
            setJobType((prev: any) => {
              const updatedData = { ...prev, visibility: "private" };
              sessionStorage.setItem(
                "advanceSettingData",
                JSON.stringify(updatedData),
              );
              return updatedData;
            });
          }}
        >
          <AssessmentCheckboxRadio
            name="private"
            type="radio"
            checked={jobType?.visibility == "private"}
          />
          <p className="radioCheckLabel">{t("GENERAL.PRIVATE")}</p>
        </div>
      </div>
    </div>
  );
};

export default VisibilityType;
