import { getEntireCustomUrl } from "smartassess-api-lib";
import apiClient from "../../../../../../../service/Connection/Connection";

const CLIENT_ID = process.env.REACT_APP_GMAIL_CLIENT_ID || "";
const REDIRECT_URI = "https://company.evluate.com/jobs/mailbox";
const CLIENT_SECRET = process.env.REACT_APP_GMAIL_CLIENT_SECRET || "";

const exchangeCodeForToken = async (code: string) => {
  try {
    const response = await apiClient.post(
      "https://oauth2.googleapis.com/token",
      {
        code,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        redirect_uri: REDIRECT_URI,
        grant_type: "authorization_code",
      },
    );
    const data = response?.data;
    if (data?.access_token) {
      try {
        const userInfo = await getEntireCustomUrl(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${data?.access_token}`,
          10000,
          {
            headers: {
              Authorization: `Bearer ${data?.access_token}`,
              Accept: "application/json",
            },
          },
        );
        return { userInfo, tokenData: data };
      } catch (err) {
        console.error("Error fetching user info:", err);
        throw err;
      }
    }
    return data;
  } catch (error) {
    console.error("Error exchanging code for token:", error);
    throw error;
  }
};

const getRefreshToken = () => {
  return new Promise((resolve, reject) => {
    try {
      const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://mail.google.com/&access_type=offline&prompt=consent`;
      const newWindow = window.open(authUrl, "_blank", "width=500,height=600");
      if (!newWindow)
        throw new Error("Popup blocked. Please allow popups for this website.");
      const pollTimer = setInterval(async () => {
        try {
          if (!newWindow || newWindow.closed) {
            clearInterval(pollTimer);
            throw new Error("Popup closed by user.");
          }
          const url = newWindow.location.href;
          if (url.startsWith(REDIRECT_URI)) {
            const params = new URL(url).searchParams;
            const code = params.get("code");
            clearInterval(pollTimer);
            newWindow.close();
            if (code) {
              const response = await exchangeCodeForToken(code);
              resolve(response); // Resolve the promise with the response data
            } else {
              reject(new Error("No code found in URL"));
            }
          }
        } catch (error: any) {
          if (error.name !== "SecurityError") {
            console.error(error.message);
          }
        }
      }, 500);
    } catch (error) {
      reject(error);
    }
  });
};

export default getRefreshToken;
