import { useEffect, useState } from "react";
import CustomModal from "../../../components/CustomModal/CustomModal";
import "./UploadFilePopup.scss";
import {
  AssessmentButton,
  CheckboxRadio,
  showToast,
  Tooltip,
} from "ui-components";
import { convertDate, formatBytes } from "../../../utils/Common/Constant";
import { useTranslation } from "react-i18next";
import { confirmCVupload } from "../../../service/CvScanning/CvScanning";
import Loader from "../../../components/Loader/Loader";
type FileProgress = {
  file: File;
  progress: number;
  status: "uploading" | "completed" | "failed";
};

interface UploadFilePopup {
  jobId: string | any;
  fileUploadData: any;
  setCvScanData: any;
  fileProgressList: FileProgress[];
  setIsConfirmScanCvs: any;
}
const UploadFilePopup: React.FC<UploadFilePopup> = ({
  jobId,
  fileUploadData,
  setCvScanData,
  fileProgressList,
  setIsConfirmScanCvs,
}) => {
  const [uploadFilePopup, SetUploadFilePopup] = useState(true);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const { t } = useTranslation();
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      const selectedIds = fileUploadData
        ?.filter((item: any) => item?.cvId != null) // Filters out null or undefined cvIds
        .map((item: any) => item?.cvId); // Map to get the cvId
      setSelectedItems(selectedIds);
    } else {
      setSelectedItems([]);
    }
  };
  // Initialize selectedItems based on conditions
  useEffect(() => {
    const defaultSelectedItems = fileUploadData
      ?.filter(
        (item: any) =>
          item?.status === "success" && item?.alreadyUploaded === false,
      )
      ?.map((item: any) => item?.cvId);
    setSelectedItems(defaultSelectedItems || []);
  }, [fileUploadData]);

  const handleItemChange = (id: number) => {
    if (id === undefined) {
      return; // Exit the function if ID is undefined
    }
    if (selectedItems?.includes(id)) {
      setSelectedItems(selectedItems?.filter((itemId) => itemId !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleConfirm = async () => {
    setBtnLoading(true);
    const result = fileUploadData
      .filter((item: any) => selectedItems?.includes(item?.cvId)) // Filter items where cvId matches any id in data2
      .map(
        ({
          cvId,
          alreadyUploaded,
          aiScannedText,
          fileName,
          filePath,
          fileSize,
          fileType,
        }: any) => ({
          cvId,
          alreadyUploaded,
          aiScannedText,
          fileName,
          filePath,
          fileSize,
          fileType,
        }),
      ); // Map to extract only required fields
    const apiData = {
      jobId: jobId,
      scannedCVs: result,
    };
    const confirmCVuploadRes = await confirmCVupload(apiData);
    if (confirmCVuploadRes?.status === 200) {
      setIsConfirmScanCvs(true);
      showToast(confirmCVuploadRes?.customMessage, "success");
      SetUploadFilePopup(false);
      setBtnLoading(false);
      setCvScanData([]);
    } else {
      setBtnLoading(false);
    }
  };
  const isAllSelected =
    fileUploadData?.filter((item: any) => item?.status === "success").length >
      0 &&
    selectedItems.length ===
      fileUploadData?.filter((item: any) => item?.status === "success").length;
  return (
    <div>
      <CustomModal
        show={uploadFilePopup}
        isCrossBtn={true}
        isHeaderVisible={false}
        handleClose={() => {
          SetUploadFilePopup(false);
          setCvScanData([]);
        }}
        className=""
        size="lg"
        fullscreen="md-down"
        isFooterVisible={false}
      >
        <div className="uploadCvs">
          {fileUploadData?.length ? (
            <>
              <div className="heading">
                <h3>{t("RESUME.ASSESSMENT_UPLOAD_CVS")}</h3>
              </div>
              <div className="selectAllPopup">
                <div className="selectAll">
                  <CheckboxRadio
                    name="fileUploadrt"
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={(e: any) => handleSelectAll(e)}
                  />
                  <label className="selectAllLabel">
                    {t("RESUME.ASSESSMENT_SELECT_ALL")} ({selectedItems?.length}
                    )
                  </label>
                </div>
                {/* <div className="horizontalLine"></div> */}

                <div className="selectItem">
                  {fileUploadData?.map((item: any) => (
                    <>
                      <div className="filesData">
                        <div key={item?.cvId} className="d-flex gap-3">
                          <CheckboxRadio
                            name="fileUpload"
                            type="checkbox"
                            checked={
                              item?.cvId !== undefined &&
                              selectedItems.includes(item.cvId)
                            }
                            onChange={() => handleItemChange(item?.cvId)}
                          />
                          <div className="extraItem">
                            <span className="fileName">{item?.fileName}</span>
                            <span className="fileSize">
                              {formatBytes(item?.fileSize)}
                              {/* {t("RESUME.ASSESSMENT_MB")} */}
                            </span>
                          </div>
                        </div>
                        {item?.status === "failed" && (
                          <div className="statusFailed">
                            <span>{item?.messages}</span>
                          </div>
                        )}
                        {item?.alreadyUploaded && (
                          <div className="alreadyExits">
                            <div className="textWithDate">
                              <span className=" text text-end">
                                {t("RESUME.UPLOADED PREVIOUSLY")}
                              </span>
                              <span className="updateDate">
                                {convertDate(item?.uploadedAt, false, true)}
                              </span>
                            </div>
                            <div className="iIcon">
                              <Tooltip
                                classname="tooltipOverCss"
                                text={t("GENERAL.CV_ALREADY_UPLOADED")}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="horizontalLine"></div>
                    </>
                  ))}
                </div>
              </div>

              <div className="confirmBtn">
                <AssessmentButton
                  onClick={() => handleConfirm()}
                  disabled={btnLoading}
                >
                  <div className="btn-loader">
                    {btnLoading ? (
                      <>
                        <span>{t("GENERAL.PROCESSING")}</span>
                        <Loader />
                      </>
                    ) : (
                      <span>{t("RESUME.ASSESSMENT_CONFIRM")}</span>
                    )}
                  </div>
                </AssessmentButton>
              </div>
            </>
          ) : (
            <div className="progress-list">
              {fileProgressList?.length > 0 ? (
                fileProgressList.every(
                  ({ status }) => status === "completed",
                ) ? (
                  <div className="loader-container">
                    <p>{t("GENERAL.PROCESSING")}</p>
                    <Loader />
                  </div>
                ) : (
                  fileProgressList.map(({ file, progress, status }) => (
                    <div key={file.name} className="progress-item">
                      <p>{file.name}</p>
                      <div className="progress-bar-container">
                        <div
                          className="progress-bar"
                          style={{ width: `${progress}%` }}
                        />
                        <p>
                          {progress}% {status}
                        </p>
                      </div>
                    </div>
                  ))
                )
              ) : (
                <p>{t("GENERAL.NO_FILES_UPLOADING")}</p>
              )}
            </div>
          )}
        </div>
      </CustomModal>
    </div>
  );
};

export default UploadFilePopup;
