import { getSessionStorageItem } from "../../utils/SessionStorageMethod/getSessionStorageItem";
export function assessmentDraftJson(tempUniqueId?: string | undefined) {
  return {
    template: {
      assessmentType: "d5bbf5b9-7ddd-4141-9b1e-04a624056651",
      // assessmentType: "f0faa9fc-b483-4c2f-8e08-cc42a0c4d959",// Id use for local
      title: getSessionStorageItem("titleHeadContent"),
      existingPreview: getSessionStorageItem("existingPreview") || [],
      existingData: getSessionStorageItem("existingData") || [],
    },
    build: {
      assessmentSkill: getSessionStorageItem("ownPreview") || [],
    },
    custom: {
      AssessmentCustomQuestions:
        getSessionStorageItem("createQuestionData") || [],
    },
    setting: {
      // Comment for future use
      // minimumExperience: getSessionStorageItem("minimumExperience") || 0,
      // maximumExperience: getSessionStorageItem("maximumExperience") || 0,
      // noOfPositions: getSessionStorageItem("noOfPositions") || 0,
      // maximumResponse: getSessionStorageItem("maximumResponse") || 0,
      cutOff: getSessionStorageItem("cutOff") || 0,
      description: getSessionStorageItem("description") || "",
      instructions: getSessionStorageItem("instruction") || "",
      // advanceSettingData: getSessionStorageItem("advanceSettingData") || {},
    },
    review: {
      evaluationTypes: getSessionStorageItem("evaluation") || [
        { type: "manual", attributes: true },
      ],
    },
    isTemp: true,
    tempAssessmentUId:
      tempUniqueId ?? localStorage.getItem("tempUniqueId") ?? "",
    userId: localStorage.getItem("companyId") ?? "",
    branchId: localStorage.getItem("branchId") ?? "",
    currentStep: getSessionStorageItem("currentStep") || 1,
    companyId: localStorage.getItem("companyId") ?? "",
    totalTime: getSessionStorageItem("totalQuestionTime") || 0,
    totalQuestions: getSessionStorageItem("totalQuestionCount") || 0,
    totalCountedCredit: getSessionStorageItem("totalCountedCredit") || 0,
    isTemplate: getSessionStorageItem("createTemplate"),
    isStoreEditedData: getSessionStorageItem("isStoreEditedData"),
  };
}
