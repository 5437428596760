const EmailIcon = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.98225 1.88321C1.6416 1.64755 1.17442 1.73266 0.938762 2.07331C0.703107 2.41395 0.788218 2.88114 1.12886 3.11679L1.98225 1.88321ZM9 7.65L8.57328 8.26683L8.58041 8.27164L9 7.65ZM13 7.65L13.4196 8.27168L13.4267 8.26679L13 7.65ZM20.8711 3.11679C21.2118 2.88114 21.2969 2.41395 21.0612 2.07331C20.8256 1.73266 20.3584 1.64755 20.0178 1.88321L20.8711 3.11679ZM1.12886 3.11679L8.57331 8.26679L9.42669 7.03321L1.98225 1.88321L1.12886 3.11679ZM8.58041 8.27164C10.0192 9.24279 11.9808 9.24279 13.4196 8.27164L12.5804 7.02836C11.6487 7.65721 10.3513 7.65721 9.4196 7.02836L8.58041 8.27164ZM13.4267 8.26679L20.8711 3.11679L20.0178 1.88321L12.5733 7.03321L13.4267 8.26679ZM3.22222 1.75H18.7778V0.25H3.22222V1.75ZM18.7778 1.75C19.6671 1.75 20.25 2.38202 20.25 3H21.75C21.75 1.40884 20.343 0.25 18.7778 0.25V1.75ZM20.25 3V13H21.75V3H20.25ZM20.25 13C20.25 13.618 19.6671 14.25 18.7778 14.25V15.75C20.343 15.75 21.75 14.5912 21.75 13H20.25ZM18.7778 14.25H3.22222V15.75H18.7778V14.25ZM3.22222 14.25C2.33285 14.25 1.75 13.618 1.75 13H0.25C0.25 14.5912 1.65699 15.75 3.22222 15.75V14.25ZM1.75 13V3H0.25V13H1.75ZM1.75 3C1.75 2.38202 2.33285 1.75 3.22222 1.75V0.25C1.65699 0.25 0.25 1.40884 0.25 3H1.75Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default EmailIcon;
