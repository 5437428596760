import { InputField, SearchTypeSelect, showToast } from "ui-components";
import "./AddressField.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useDebounce from "../../../../../utils/Hooks/useDebounce";
import {
  getCountry,
  getState,
} from "../../../../../service/CvScanning/CvScanning";
import usePlacesAutocomplete from "../../../../../utils/Hooks/usePlacesAutocomplete";
import AutoCompleteSearch from "../../../../../components/GoogleLocationComponents/AutoCompleteSearch/AutoCompleteSearch";
import MapComponent from "../../../../../components/GoogleLocationComponents/MapComponent/MapComponent";
import {
  capitalizeFirstLetter,
  getLocationComponents,
} from "../../../../../utils/Common/Constant";
import { setSessionStorageItem } from "../../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { getSessionStorageItem } from "../../../../../utils/SessionStorageMethod/getSessionStorageItem";
import useCurrentLocation from "../../../../../utils/Hooks/useCurrentLocation";
import { removeSessionStorageItem } from "../../../../../utils/SessionStorageMethod/removeSessionStorageItem";
const AddressField = ({ addressField, handleAddressField }: any) => {
  const { t } = useTranslation();
  const defaultSelectCountry = { value: "", label: "" };
  const defaultSelectState = { value: "", label: "" };
  const [getCountryList, setGetCountryList] = useState<any>([]);
  const [getStateList, setGetStateList] = useState<any>([]);
  const [skillIdFromCountry, setSkillIdFromCountry] = useState<any>(
    getSessionStorageItem("selectedJobCountry") || defaultSelectCountry,
  );
  const [skillIdFromState, setSkillIdFromState] = useState<any>(
    getSessionStorageItem("selectedJobState") || defaultSelectState,
  );
  const debounceCountrySearchValue = useDebounce(
    skillIdFromCountry?.label?.trim(),
    400,
  );
  const debounceStateSearchValue = useDebounce(
    skillIdFromState?.label?.trim(),
    400,
  );
  async function getCountryData(country?: any, state?: any) {
    if (country || state) {
      const countryRes = await getCountry(country);
      if (countryRes?.status) {
        const filterCountryData = countryRes?.data?.data.map((item: any) => {
          return { label: item?.name, value: item?.countryId };
        });
        if (filterCountryData?.length) {
          if (filterCountryData[0]?.value) {
            const stateRes = await getState(filterCountryData[0]?.value, state);
            const filterStateData = stateRes?.data?.data?.map((item: any) => {
              return { label: item?.name, value: item?.stateId };
            });
            setGetStateList(filterStateData);
            setSkillIdFromState({
              value: filterStateData[0]?.value || "",
              label: filterStateData[0]?.label || "",
            });
            handleAddressField(filterStateData[0]?.value, "state");
          }

          setGetCountryList(filterCountryData);
          setSkillIdFromCountry({
            value: filterCountryData[0]?.value,
            label: filterCountryData[0]?.label,
          });
          handleAddressField(filterCountryData[0]?.value, "country");
        } else {
          setSkillIdFromCountry({
            value: "",
            label: "",
          });
          handleAddressField("", "country");
          setSkillIdFromState({
            value: "",
            label: "",
          });
          handleAddressField("", "state");
        }
      }
    } else {
      const countryRes = await getCountry(debounceCountrySearchValue);
      if (countryRes?.status) {
        const filterCountryData = countryRes?.data?.data.map((item: any) => {
          return { label: item?.name, value: item?.countryId };
        });
        if (skillIdFromCountry?.value) {
          const stateRes = await getState(
            skillIdFromCountry?.value,
            debounceStateSearchValue,
          );
          const filterStateData = stateRes?.data?.data?.map((item: any) => {
            return { label: item?.name, value: item?.stateId };
          });
          setGetStateList(filterStateData);
        }
        setGetCountryList(filterCountryData);
        return filterCountryData;
      }
    }
  }
  useEffect(() => {
    if (!skillIdFromCountry?.label) {
      setSkillIdFromState(defaultSelectState);
    }
  }, [skillIdFromCountry]);
  const onCrossPress = () => {
    setGetCountryList([]);
    setGetStateList([]);
    setSkillIdFromCountry(defaultSelectCountry);
    setSkillIdFromState(defaultSelectState);
  };
  useEffect(() => {
    // to get skills
    getCountryData();
  }, [debounceCountrySearchValue, debounceStateSearchValue]);
  const {
    inputValue,
    setInputValue,
    suggestions,
    loading,
    error,
    selectedPlace,
    handleSelectPlace,
    setSuggestions,
    setSelectedPlace,
  } = usePlacesAutocomplete();
  const [placeSelected, setPlaceSelected] = useState(false);
  const [center, setCenter] = useState<any>(null);
  const locationData: any = useCurrentLocation();
  const { coords } = locationData;
  const [currentCoords, setCurrentCoords] = useState<any>(null);
  useEffect(() => {
    if (!center) {
      setCurrentCoords(coords);
      setSessionStorageItem("jobCoords", coords);
    }
  }, [coords]);
  useEffect(() => {
    const jobCoords = getSessionStorageItem("jobCoords");
    const newCenter = selectedPlace?.location || {
      lat: jobCoords?.latitude,
      lng: jobCoords?.longitude,
    };
    if (newCenter?.latitude && newCenter?.longitude) {
      setCurrentCoords(null);
      setCenter(newCenter);
      setSessionStorageItem("jobCoords", newCenter);
    }
  }, [selectedPlace]);
  useEffect(() => {
    if (center?.lat && center?.lng) {
      setSessionStorageItem("jobCoords", center);
    }
  }, [center]);
  const onPlaceSelected = () => {
    const country =
      getLocationComponents(selectedPlace?.addressComponents, "country") || "";
    const state =
      getLocationComponents(
        selectedPlace?.addressComponents,
        "administrative_area_level_1",
      ) || "";
    const city =
      getLocationComponents(selectedPlace?.addressComponents, "locality") || "";
    const zipcode =
      getLocationComponents(selectedPlace?.addressComponents, "postal_code") ||
      "";
    const searchedAddress = selectedPlace?.formattedAddress;
    if (country || state || city || zipcode || searchedAddress) {
      if (country || state) {
        getCountryData(country, state);
      }
      handleAddressField(searchedAddress, "addressLine1");
      handleAddressField(city, "city");
      handleAddressField(zipcode, "zipcode");
    }
  };
  useEffect(() => {
    if (selectedPlace) {
      const countryTitle = getLocationComponents(
        selectedPlace?.addressComponents,
        "country",
      );
      if (countryTitle !== "India" && countryTitle !== "United States") {
        showToast(t("GENERAL.OPERATE_INDIA_USA"));
      } else {
        onPlaceSelected();
      }
    }
  }, [selectedPlace]);

  // this useEffect will set the location in session storage
  useEffect(() => {
    if (skillIdFromState?.value && skillIdFromState?.label) {
      setSessionStorageItem("selectedJobState", skillIdFromState);
    } else {
      removeSessionStorageItem("selectedJobState");
    }
  }, [skillIdFromState]);
  useEffect(() => {
    if (skillIdFromCountry?.value && skillIdFromCountry?.label) {
      setSessionStorageItem("selectedJobCountry", skillIdFromCountry);
    } else {
      removeSessionStorageItem("selectedJobCountry");
    }
  }, [skillIdFromCountry]);
  useEffect(() => {
    setSessionStorageItem("jobAddress1", addressField?.addressLine1);
    setSessionStorageItem("jobAddress2", addressField?.addressLine2);
    setSessionStorageItem("jobCity", addressField?.city);
    setSessionStorageItem("jobZipcode", addressField?.zipcode);
  }, [addressField]);
  useEffect(() => {
    if (center?.lat && center?.lng) {
      setSessionStorageItem("jobCoords", center);
    }
  }, [center]);
  return (
    <>
      <div className="addressFieldForm row mt-5">
        <span className="location">Location</span>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="addressSearch mb-3">
            <AutoCompleteSearch
              inputValue={inputValue}
              setInputValue={setInputValue}
              suggestions={suggestions}
              loading={loading}
              error={error}
              selectedPlace={selectedPlace}
              handleSelectPlace={handleSelectPlace}
              setSuggestions={setSuggestions}
              setSelectedPlace={setSelectedPlace}
              placeSelected={placeSelected}
              setPlaceSelected={setPlaceSelected}
              onCrossPress={() => onCrossPress()}
            />
          </div>

          <div className="addressField">
            <div className="address-one field">
              <p>{t("RESUME.SCANNING_ADDRESS_1")}</p>
              <InputField
                value={capitalizeFirstLetter(addressField?.addressLine1)}
                onChange={(e: any, name: any) => {
                  handleAddressField(e, name);
                }}
                placeholder={t("RESUME.SCANNING_ADDRESS_1")}
                additionalProps={{
                  className: "textInput",
                  name: "addressLine1",
                }}
              />
            </div>
            <div className="address-two field">
              <p>{t("RESUME.SCANNING_ADDRESS_2")}</p>
              <InputField
                value={capitalizeFirstLetter(addressField?.addressLine2)}
                onChange={(e: any, name: any) => handleAddressField(e, name)}
                placeholder={t("RESUME.SCANNING_ADDRESS_2")}
                additionalProps={{
                  className: "textInput",
                  name: "addressLine2",
                }}
              />
            </div>
          </div>
          <div className="countryField">
            <div className="country field">
              <p>{t("RESUME.SCANNING_COUNTRY")}</p>

              <SearchTypeSelect
                options={getCountryList}
                placeholder={t("RESUME.SCANNING_COUNTRY")}
                value={skillIdFromCountry}
                onChange={(getValue: any) => {
                  setSkillIdFromCountry(getValue);
                  handleAddressField(getValue?.value, "country");
                }}
                loadingText={t("GENERAL.LOADING")}
              />
            </div>
            <div className="state field">
              <p>{t("RESUME.SCANNING_STATE")}</p>
              <SearchTypeSelect
                options={getStateList}
                placeholder={t("RESUME.SCANNING_STATE")}
                value={skillIdFromState}
                onChange={(getValue: any) => {
                  setSkillIdFromState(getValue);
                  handleAddressField(getValue?.value, "state");
                }}
                loadingText={t("GENERAL.LOADING")}
              />
            </div>
            <div className="city field">
              <p>{t("RESUME.SCANNING_CITY")}</p>
              <InputField
                value={
                  addressField?.city &&
                  capitalizeFirstLetter(addressField?.city)
                }
                onChange={(e: any, name: any) => handleAddressField(e, name)}
                placeholder={t("RESUME.SCANNING_CITY")}
                additionalProps={{
                  className: "textInput",
                  name: "city",
                }}
              />
            </div>
            <div className="zipcode field">
              <p>{t("RESUME.ZIPCODE")}</p>
              <InputField
                value={
                  addressField?.zipcode &&
                  capitalizeFirstLetter(addressField?.zipcode)
                }
                onChange={(e: any, name: any) => handleAddressField(e, name)}
                placeholder={t("RESUME.ZIPCODE")}
                additionalProps={{
                  className: "textInput",
                  name: "zipcode",
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
          {currentCoords || center ? (
            <MapComponent
              lat={currentCoords?.lat || center?.latitude}
              lng={currentCoords?.lng || center?.longitude}
            />
          ) : (
            <img
              src={require("../../../../../utils/Images/Map.png")}
              alt=""
              className="map-image"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AddressField;
