import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";
// to get companyGetJobList
export const companyGetJobList = async (
  status: string,
  search: string,
  currentPage: number,
  pageSize: number,
) => {
  try {
    return (
      await apiClient.get(
        `jobs/companyGetJobList?status=${status}&search=${search}&currentPage=${currentPage}&pageSize=${pageSize}`,
      )
    )?.data;
  } catch (error) {
    handleError(error);
  }
};

// scan Job
export const scanJob = async (data: any) => {
  try {
    const res = await apiClient.post("jobs/scanJob", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get companyGetJobList
export const companyGetJobDetails = async (jobId: string | any) => {
  try {
    return (await apiClient.get(`jobs/companyGetJobDetails?jobId=${jobId}`))
      ?.data;
  } catch (error) {
    handleError(error);
  }
};

//create password api
export const updateJob = async (data: any) => {
  try {
    const res = await apiClient.post("jobs/updateJob", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get companyGetJobList
export const getCountry = async (search: string) => {
  try {
    return (
      await apiClient.get(
        `country/getCountryList?search=${search}&currentPage=1&pageSize=100`,
      )
    )?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get companyGetJobList
export const getState = async (countryId: string, search: string) => {
  try {
    return (
      await apiClient.get(
        `state/getStateList?countryId=${countryId}&search=${search}&currentPage=1&pageSize=100`,
      )
    )?.data;
  } catch (error) {
    handleError(error);
  }
};

// scanCVs
export const scanCVs = async (data: any) => {
  try {
    const res = await apiClient.post("jobs/scanCVs", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

// confirm CV upload
export const confirmCVupload = async (data: any) => {
  try {
    const res = await apiClient.post("jobs/confirmCVupload", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get companyGetJobList
export const jobAssessmentList = async (jobId: string) => {
  try {
    return (await apiClient.get(`jobs/assessmentList?jobId=${jobId}`))?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get companyGetJobList
export const cvList = async (
  jobId: string,
  sortColumn: string,
  sortOrder: string,
  search: string,
  currentPage: number,
  pageSize: number,
  sourceType: any,
  isScanned?: boolean,
  isInvited?: boolean,
  sourceId?: any,
) => {
  try {
    return (
      await apiClient.get(
        `jobs/cvList?jobId=${jobId}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&search=${search}&currentPage=${currentPage}&pageSize=${pageSize}&sourceType=${sourceType}&isScanned=${isScanned}${isInvited ? `&isInvited=${isInvited}` : ""}${sourceId ? `&sourceId=${sourceId}` : ""}`,
      )
    )?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get cvCompleteScan
export const cvCompleteScan = async (
  jobId: string,
  cvCount: any,
  cvId?: string,
) => {
  try {
    return (
      await apiClient.get(
        `jobs/cvCompleteScan?jobId=${jobId}&cvCount=${cvCount}&cvId=${cvId}`,
      )
    )?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get systemCVRefreshStatus
export const systemCVRefreshStatus = async (jobId: string) => {
  try {
    return (await apiClient.get(`jobs/systemCVRefreshStatus?jobId=${jobId}`))
      ?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get refreshSystemCVs
export const refreshSystemCVs = async (jobId: string) => {
  try {
    return (await apiClient.get(`jobs/refreshSystemCVs?jobId=${jobId}`))?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get uploadedCVCount
export const uploadedCVCount = async (jobId: any) => {
  try {
    return (await apiClient.get(`jobs/uploadedCVCount?jobId=${jobId}`))?.data;
  } catch (error) {
    handleError(error);
  }
};

//  update Job Status
export const updateJobStatus = async (data: any) => {
  try {
    const res = await apiClient.put("/jobs/updateJobStatus", data);
    return res;
  } catch (error) {
    handleError(error);
  }
};

//  update Job Status
export const addCVCredits = async (data: any) => {
  try {
    const res = await apiClient.post("/jobs/addCVCredits", data);
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const getSourcesList = async (jobId: any) => {
  try {
    const res = await apiClient.get(`jobs/sourceList?jobId=${jobId}`);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

export const addSource = async (data: any) => {
  try {
    const res = await apiClient.post("/jobs/addSource", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

export const deleteSource = async (jobId: any, jobIntegrationId: any) => {
  try {
    const res = await apiClient.delete(
      `jobs/deleteJobSource?jobId=${jobId}&jobIntegrationId=${jobIntegrationId}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

export const refreshJobIntegration = async (
  jobId: any,
  jobIntegrationId: any,
) => {
  try {
    const res = await apiClient.get(
      `jobs/refreshJobIntegrations?jobId=${jobId}&jobIntegrationId=${jobIntegrationId}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
