const EvluateShortIcon = () => {
  return (
    <svg
      width="33"
      height="24"
      viewBox="0 0 33 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.335 12C24.3529 12.3337 23.9248 12.6685 23.034 12.9554C18.0229 14.5728 14.6626 17.7851 13.0455 22.7405C12.7351 23.5936 12.4551 24.004 12.1677 24C11.8803 24.004 11.6003 23.5936 11.29 22.7405C9.67279 17.7851 6.31259 14.5728 1.30157 12.9554C0.410741 12.6685 -0.0175558 12.3337 0.000550674 12C-0.0175556 11.6663 0.410741 11.3315 1.30157 11.0445C6.31257 9.42714 9.67278 6.21486 11.29 1.25947C11.6003 0.40639 11.8803 -0.0040227 12.1677 2.97135e-05C12.4551 -0.00402251 12.7351 0.40639 13.0455 1.25947C14.6626 6.21486 18.0229 9.42716 23.034 11.0445C23.9248 11.3315 24.3529 11.6663 24.335 12Z"
        fill="#2EBEC6"
      />
      <path
        d="M17.8968 3.1991C17.4697 3.1991 17.123 3.5403 17.123 3.96109C17.123 4.38171 17.4697 4.72307 17.8968 4.72307H31.6055C32.0328 4.72307 32.3793 4.38171 32.3793 3.96109C32.3793 3.5403 32.0328 3.1991 31.6055 3.1991H17.8968ZM17.8968 19.2766C17.4697 19.2766 17.123 19.6178 17.123 20.0386C17.123 20.4592 17.4697 20.8006 17.8968 20.8006H31.6055C32.0328 20.8006 32.3793 20.4592 32.3793 20.0386C32.3793 19.6178 32.0328 19.2766 31.6055 19.2766H17.8968Z"
        fill="#2EBEC6"
      />
    </svg>
  );
};

export default EvluateShortIcon;
