import "./ChipWithCount.scss";
interface ChipWithCount {
  noOfCount: number;
}

const ChipWithCount: React.FC<ChipWithCount> = ({ noOfCount }) => {
  return (
    <div className="chipWithCount">
      <span className="noCount">+{noOfCount}</span>
    </div>
  );
};

export default ChipWithCount;
