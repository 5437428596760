import { useState } from "react";
import ChipWithCount from "../../../../../../components/ChipWithCount/ChipWithCount";
import { AssessmentButton, Bullets } from "ui-components";
import { useTranslation } from "react-i18next";
import Loader from "../../../../../../components/Loader/Loader";
import { capitalizeFirstLetter } from "../../../../../../utils/Common/Constant";
import AiStarIcon from "../../../../../../utils/Images/AiStarIcon";

const Content = ({
  item,
  handleScanCV,
  setInvitedEmail,
  setIsInvited,
  jobId,
  loading,
  tabActive,
}: any) => {
  const { t } = useTranslation();
  return (
    <div className="content mt-3" key={jobId}>
      <div className="w-100">
        <div className="w-100 mb-4 ">
          <p className="common">{t("RESUME.SCANNING_EXPERIENCE")}</p>
          <span className="words">
            {item?.relevantExp ? `${item?.relevantExp} Years` : <h5>-</h5>}
          </span>
        </div>
        <div>
          <p className="common">
            {t("RESUME.SCANNING_KEYWORDS")}
            <span className="commonBold">
              &nbsp;
              {item?.keywordsRelevancy !== null
                ? item.keywordsRelevancy + "%"
                : ""}
            </span>
          </p>
          <div className="words">
            <SkillList item={item?.relevantKeywords} />
          </div>
        </div>
      </div>

      <div className="w-100">
        <div className="w-100 mb-4">
          <p className="common">
            {t("GENERAL.SKILLS")}
            <span className="commonBold">
              &nbsp;
              {tabActive === "unscannedcv"
                ? ""
                : item?.skillRelevancy !== null
                  ? item.skillRelevancy + "%"
                  : ""}
            </span>
          </p>
          {tabActive === "unscannedcv" ? (
            <h5>-</h5>
          ) : (
            <SkillList item={item?.relevantSkills} />
          )}
        </div>
        <div>
          <p className="common">
            {t("RESUME.SCANNING_PROJECTS")}
            <span className="commonBold">
              &nbsp;
              {item?.projectsRelevancy !== null
                ? item.projectsRelevancy + "%"
                : ""}
            </span>
          </p>
          <SkillList item={item?.projectRelevantSkills} />
        </div>
      </div>

      <div className="w-100 d-flex flex-column">
        <div className="w-100 mb-4 hide"> </div>
        <div>
          <p className="common relevantProject">
            {t("RESUME.SCANNING_RELEVANT_PROJECTS")}{" "}
            <span className="commonBold">&nbsp;</span>
          </p>
          <span className="words">
            {item?.relevantProjectsCount ?? <h5>-</h5>}
          </span>
        </div>
      </div>

      <div className="w-100 overAll">
        <p className="">{t("RESUME.ASSESSMENT_OVERALL")}</p>
        <span className="overAllScore">
          {item?.overAllRelevancy !== null ? (
            `${item?.overAllRelevancy}%`
          ) : (
            <h5>-</h5>
          )}
        </span>
        <div className="d-flex gap-3 bottomBtns">
          {!item?.isScanned && (
            <div className="confirmBtn">
              <div className="scanBtn">
                <AiStarIcon />
                <AssessmentButton
                  className={!loading && "whiteBtn"}
                  onClick={() => handleScanCV(jobId, "", item?.cvId)}
                  disabled={loading === item?.cvId}
                >
                  {loading === item?.cvId ? (
                    <div className="btn-loader">
                      <span className="scanText">
                        {t("RESUME.ASSESSMENT_SCANNING_CV")}
                      </span>
                      <Loader />
                    </div>
                  ) : (
                    <span>{t("RESUME.SCANNING_SCAN_CV")}</span>
                  )}
                </AssessmentButton>
              </div>
            </div>
          )}
          <AssessmentButton
            disabled={item?.isInvited}
            onClick={() => {
              setInvitedEmail({
                email: item?.cv?.email,
                firstName: item?.cv?.firstName,
                lastName: item?.cv?.lastName,
              });
              setIsInvited(true);
            }}
            className="whiteBtn"
          >
            {item?.isInvited ? t("GENERAL.INVITED") : t("GENERAL.INVITE")}
          </AssessmentButton>
        </div>
      </div>
    </div>
  );
};

export default Content;

const SkillList = ({ item }: { item: any }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleToggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  return (
    <div className="skillList">
      {item?.length > 0 ? (
        <>
          {item
            ?.split(",")
            .slice(0, 3)
            .map((skill: any, ind: any) => (
              <div key={ind} className="skillName">
                <Bullets skillName={capitalizeFirstLetter(skill)} />
              </div>
            ))}
          {item?.split(",").length > 3 && (
            <div className="dropdownWrapper">
              <div className="showMore" onClick={handleToggleDropdown}>
                <ChipWithCount noOfCount={item?.split(",").length - 3} />
              </div>
              {showDropdown && (
                <div className="dropdownMenu">
                  {item
                    ?.split(",")
                    .slice(3)
                    .map((skill: any, ind: any) => (
                      <div key={ind} className="dropdownItem">
                        <Bullets skillName={capitalizeFirstLetter(skill)} />
                      </div>
                    ))}
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <h5>-</h5>
      )}
    </div>
  );
};
