const CvAssessmentTabIcon = ({ color }: any) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 8.9375H10.3125M8.25 11H13.75M8.25 13.75H13.75M8.25 16.5H13.75M14.4375 4.8125V1.375C14.4375 0.994813 14.1302 0.6875 13.75 0.6875H1.375C0.994813 0.6875 0.6875 0.994813 0.6875 1.375V16.5C0.6875 16.5 0.6875 17.1875 1.375 17.1875H2.0625M17.875 18.5625H20.625C21.0052 18.5625 21.3125 18.2552 21.3125 17.875V2.75C21.3125 2.36981 21.0052 2.0625 20.625 2.0625H16.5M17.875 20.625C17.875 21.0052 17.5677 21.3125 17.1875 21.3125H4.8125C4.43231 21.3125 4.125 21.0052 4.125 20.625V5.5C4.125 5.11981 4.43231 4.8125 4.8125 4.8125H17.1875C17.5677 4.8125 17.875 5.11981 17.875 5.5V20.625Z"
        stroke={color ? color : "#4D4D4D"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CvAssessmentTabIcon;
