const RefreshIconBorderLess = ({ isDisable }: any) => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.02708 4.9375C1.00921 4.79417 1 4.64818 1 4.5C1 2.567 2.567 1 4.5 1C5.59401 1 6.57077 1.50193 7.21259 2.28807M7.21259 2.28807V1M7.21259 2.28807V2.31247L5.90009 2.3125M7.97292 4.0625C7.99081 4.20583 8 4.35182 8 4.5C8 6.43301 6.43301 8 4.5 8C3.45464 8 2.51633 7.54172 1.875 6.81507M1.875 6.81507V6.6875H3.1875M1.875 6.81507V8"
        stroke={isDisable ? isDisable : "#35D4AE"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshIconBorderLess;
