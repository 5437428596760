import React from "react";
import { TemplateSearch } from "ui-components";
import "./AutoCompleteSearch.scss";
import CircleCrossIcon from "../../../utils/Images/CircleCrossIcon";

interface AutoCompleteSearchProps {
  inputValue: string;
  setInputValue: (value: string) => void;
  suggestions: any[];
  loading: boolean;
  error: string | null;
  selectedPlace: any;
  handleSelectPlace: (place: any) => void;
  setSuggestions: (suggestions: any[]) => void;
  setSelectedPlace: (place: any) => void;
  placeSelected: boolean;
  setPlaceSelected: (selected: boolean) => void;
  onCrossPress?: any;
}

const AutoCompleteSearch: React.FC<AutoCompleteSearchProps> = ({
  inputValue,
  setInputValue,
  suggestions,
  loading,
  error,
  handleSelectPlace,
  setSuggestions,
  setSelectedPlace,
  placeSelected,
  setPlaceSelected,
  onCrossPress,
}) => {
  return (
    <div className="locationSearchField">
      <div className="searchLocationInput">
        <div className="templateSearchInput">
          <TemplateSearch
            onChange={(newValue: string) => {
              setInputValue(newValue);
              setPlaceSelected(false);
            }}
            iconName="searchList"
            value={inputValue}
            placeholder="Search Location"
          />
          {inputValue && (
            <div
              className="crossIcon"
              onClick={() => {
                setInputValue("");
                setSuggestions([]);
                setPlaceSelected(false);
                setSelectedPlace(null);
                onCrossPress();
              }}
            >
              <CircleCrossIcon />
            </div>
          )}
        </div>
        {loading && ""}
        {error && <p className="errorText">{error}</p>}
        {suggestions.length > 0 && !placeSelected && (
          <div className="locationDropdown">
            {suggestions.map((suggestion, index) => (
              <div key={suggestion.place_id} className="list-item">
                <span
                  onClick={() => {
                    handleSelectPlace(suggestion);
                    setPlaceSelected(true);
                  }}
                  className="list-text"
                >
                  {suggestion.placePrediction.text.text}
                </span>
                {index !== suggestions.length - 1 && (
                  <div className="divider"></div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoCompleteSearch;
