import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import "./header.scss";
import { useTranslation } from "react-i18next";
import { AssessmentButton, useOnlineStatus } from "ui-components";
import { assessmentDraftJson } from "../../../context/CreateAssessmentDraftJson/CreateAssessmentDraftJson";
import {
  checkActive,
  foundUniqueId,
  removeJobDetailsData,
} from "../../../utils/Common/Constant";
import ProfileSettings from "./ProfileSettings";
import { getCompanyProfileDetails } from "../../../service/Scope/Scope";
import { useEffect, useRef, useState } from "react";
import Banner from "../../Banner/Banner";
import LogoTagline from "../../../utils/Images/LogoTagline";
const Header = () => {
  const finalData = assessmentDraftJson();
  const { tempAssessmentUId } = finalData;
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { pathname } = useLocation();
  const { tempUniqueId } = useParams();
  const email = localStorage.getItem("email");
  const companyUserId = localStorage.getItem("companyUserId");
  const [profileData, setProfileData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const isOnline = useOnlineStatus();
  const navigate = useNavigate();
  const [showSubmenu, setShowSubmenu] = useState(false);
  const menuRef = useRef<any>(null);
  const createBtnRef = useRef<any>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        createBtnRef.current &&
        !createBtnRef.current.contains(event.target)
      ) {
        setShowSubmenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const getProfileDetails = async () => {
    try {
      const res = await getCompanyProfileDetails(companyUserId);
      if (res?.status === 200) {
        setProfileData(res?.data?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getProfileDetails();
  }, [isOnline]);

  const toggleSubmenu = () => {
    setShowSubmenu((prev) => !prev);
  };
  return (
    <>
      <div className="internet-banner">
        <div className={`banner ${!isOnline ? "show zIndexTop" : ""}`}>
          {t("GENERAL.INTERNET_CONNECTION")}
        </div>
      </div>
      <nav
        className={`navbar navbar-expand-lg assessmentHeaderParent ${!isOnline && "main-content"}`}
      >
        <div className="container-fluid logo-link">
          <div
            className="logo"
            onClick={() =>
              navigate(`/${currentLanguage}/jobs`, { replace: true })
            }
          >
            <LogoTagline />
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 link">
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link addBottomBorder" : "nav-link"
                  }
                  to={`/${currentLanguage}/jobs`}
                >
                  {t("RESUME.SCANNING_JOBS")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link addBottomBorder" : "nav-link"
                  }
                  to={`/${currentLanguage}/assessment/list`}
                >
                  {t("CREATED_ASSESSMENT_LIST_AND_REVIEW.ASSESSMENT")}
                </NavLink>
              </li>
              <li className="nav-item " style={{ position: "relative" }}>
                <div ref={createBtnRef}>
                  <AssessmentButton
                    onClick={toggleSubmenu}
                    className={`lightBtn ${checkActive(pathname, tempUniqueId, currentLanguage) ? "activeCreateBtn" : ""}`}
                  >
                    {t("GENERAL.CREATE")}
                  </AssessmentButton>
                </div>
                <ul
                  className={`submenu ${showSubmenu ? "open" : "closed"}`}
                  ref={menuRef}
                >
                  <li
                    className="submenu-item"
                    onClick={() =>
                      navigate(`/${currentLanguage}/job/create-job`)
                    }
                  >
                    <NavLink
                      className="nav-link"
                      to={`/${currentLanguage}/job/create-job`}
                      onClick={() => {
                        toggleSubmenu();
                        removeJobDetailsData();
                        window.location.href = `/${currentLanguage}/job/create-job`;
                      }}
                    >
                      {t("GENERAL.JOB")}
                    </NavLink>
                  </li>
                  <li
                    className="submenu-item"
                    onClick={() =>
                      navigate(
                        `/${currentLanguage}/assessment/template/${foundUniqueId(tempAssessmentUId)}`,
                      )
                    }
                  >
                    <NavLink
                      className="nav-link"
                      to={`/${currentLanguage}/assessment/template/${foundUniqueId(tempAssessmentUId)}`}
                      onClick={toggleSubmenu}
                    >
                      {t("GENERAL.ASSESSMENT")}
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
            <ProfileSettings email={email} profileData={profileData} />
          </div>
        </div>
      </nav>
      {!loading && isOnline && !profileData?.isActive && <Banner />}
      <div className="borderBottomLine"></div>
    </>
  );
};

export default Header;
