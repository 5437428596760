import ReviewDescription from "./ReviewDescription/ReviewDescription";
import ReviewEvalution from "./ReviewEvalution/ReviewEvalution";
import ReviewQuestions from "./ReviewQuestions/ReviewQuestions";
import ReviewSummary from "./ReviewSummary/ReviewSummary";
import { useTranslation } from "react-i18next";
import { AssessmentButton, showToast } from "ui-components";
import "./index.scss";
import { AssessmentJsonContext } from "../../../../context/JsonContext/JsonContext";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Head from "../AssessmentScope/Head/Head";
import ComponentIds from "../../../../utils/Json/componentIds.json";
import { setSessionStorageItem } from "../../../../utils/SessionStorageMethod/setSessionStorageItem";
import {
  getAssessmentDetailsByAssessementId,
  getRoleCentricRender,
} from "../../../../service/Scope/Scope";
import { checkKeysInSessionStorage } from "../../../../utils/Common/checkKeysInSessionStorage";
import SetDraftJsonInSession from "../../AssessmentDraftJson/SetDraftJsonInSession";
import { assessmentDraftJson } from "../../../../context/CreateAssessmentDraftJson/CreateAssessmentDraftJson";
import { Form, Formik } from "formik";
import {
  determinePageType,
  drafteditedformData,
  editedExistingformData,
  editedformData,
  getAssessmentPath,
  initialReviewData,
  transformAnswerSet,
  addDisableCss,
  findValuesForKey,
  isDisable,
  isHide,
  checkUniqueIdMatch,
  skillIdExistsInAnother,
  countCreditCheck,
} from "../../../../utils/Common/Constant";
import { getSessionStorageItem } from "../../../../utils/SessionStorageMethod/getSessionStorageItem";
import {
  createAssessmentDraftJson,
  getCreateAssessmentDraftJson,
} from "../../../../service/DraftJson/DraftJson";
import * as Yup from "yup";
import PackageDetailsTable from "./PackageDetailsTable/PackageDetailsTable";
import { systemPreferenceContext } from "../../../../context/SystemPreference/SystemPreference";

const AssessmentReview: React.FunctionComponent = () => {
  const { tempUniqueId } = useParams();
  const { preferenceData } = useContext(systemPreferenceContext);
  const tempUniqueIdBySession = localStorage.getItem("tempUniqueId") ?? null;
  const { t, i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const location = useLocation();
  const editedParams = new URLSearchParams(location.search).get("edited");
  const jobId = new URLSearchParams(location.search).get("jobId");
  const renderRouteName = "review";
  const { pathname } = useLocation();
  const activeStep = determinePageType(pathname);
  const navigate = useNavigate();
  // const [isPublic, setIsPublic] = useState(true);
  // const [paymentTableData, setPaymentTableData] = useState<any>([]);

  const [isPublish, setIsPublish] = useState(false);
  // const [isPublishDisable, setIsPublishDisable] = useState(false);
  const [isQuestionData, setIsQuestionData] = useState(0);
  const userId = localStorage.getItem("companyId") ?? "";
  const { state, dispatch } = useContext(AssessmentJsonContext) ?? {
    state: {
      renderJson: {},
      count: 0,
    },
    dispatch: () => {},
  };
  //validation
  const reviewValidationSchema = Yup.object({
    titleHeadContent: Yup.string().trim().required(t("ERROR.TITLE_REQUIRED")),
  });

  {
    /* // Comment for future use */
  }
  // useEffect(() => {
  //   const assessmentQuestionSetResult = getPublishSupport();
  //   const checkSkillPresent = skillIdExists(assessmentQuestionSetResult);
  //   // setIsPublishDisable(checkSkillPresent ? false : true);
  //   setIsPublic(checkSkillPresent ? true : false);
  // }, []);

  useEffect(() => {
    (async () => {
      const getJsonData = await getRoleCentricRender(renderRouteName);
      dispatch({
        type: "UPDATE_CENTRIC_RENDER_JSON",
        payload: getJsonData,
      });
    })();
    const isKeyPresent = checkKeysInSessionStorage(
      ["createQuestionData", "ownPreview", "Type", "titleHeadContent"],
      "any",
    );
    if (
      (!isKeyPresent && !editedParams) ||
      checkUniqueIdMatch(tempUniqueId, tempUniqueIdBySession)
    ) {
      SetDraftJsonInSession(userId, tempUniqueId, pathname);
    }
  }, []);

  // render when edited assessment
  useEffect(() => {
    (async () => {
      if (editedParams) {
        const draftRes = await getCreateAssessmentDraftJson(
          userId,
          tempUniqueId,
        );
        if (
          Object.keys(draftRes?.data?.data).length > 0 &&
          (draftRes?.data?.data?.createAssessmentJson?.build?.assessmentSkill
            ?.length ||
            draftRes?.data?.data?.createAssessmentJson?.template
              ?.existingPreview?.length ||
            draftRes?.data?.data?.createAssessmentJson?.custom
              ?.AssessmentCustomQuestions)
          // &&draftRes?.data?.data?.createAssessmentJson?.setting?.minimumExperience
        ) {
          setSessionStorageItem(
            "evaluation",
            draftRes?.data?.data?.createAssessmentJson?.review
              ?.evaluationTypes || [],
          );
          drafteditedformData(draftRes?.data?.data, "review");
        } else {
          const editedData = await getAssessmentDetailsByAssessementId(
            tempUniqueId ?? "",
          );
          if (editedData?.data?.templateId) {
            const existingPreviewData = editedExistingformData(editedData);
            setSessionStorageItem("existingPreview", existingPreviewData);
            editedformData(editedData, "review");
          } else {
            const skills = editedData?.data?.assessmentSkills?.map(
              (item: any) => {
                return { ...item, id: Math.random() };
              },
            );
            setSessionStorageItem("ownPreview", skills);
            editedformData(editedData, "review");
          }
        }
      }
    })();
  }, []);

  // to move to previous page
  const handlePrevious = (count: number) => {
    if (count != null && count != undefined && typeof count === "number") {
      navigate(
        getAssessmentPath(
          count,
          tempUniqueId,
          currentLanguage,
          editedParams,
          jobId,
        ),
      );
      setSessionStorageItem("currentStep", count);
    } else {
      const prevStep = activeStep > 1 ? activeStep - 1 : activeStep;
      setSessionStorageItem("currentStep", prevStep);
      navigate(
        getAssessmentPath(
          prevStep,
          tempUniqueId,
          currentLanguage,
          editedParams,
          jobId,
        ),
      );
    }
  };

  useEffect(() => {
    const finalData = assessmentDraftJson();
    const { build, custom, template } = finalData;

    const hasData =
      build?.assessmentSkill?.length ||
      template?.existingPreview?.subjectFieldSet?.length ||
      custom?.AssessmentCustomQuestions?.length;

    setIsQuestionData(hasData);
  }, []);

  // handle change to public/private handle
  // const toggleSwitch = () => {
  //   setIsPublic((prevState) => !prevState);
  // };

  // support funtion for publish final call
  function getPublishSupport() {
    const finalData = assessmentDraftJson();
    const { build, custom, template } = finalData;
    const draggedItem = getSessionStorageItem("draggedItems");

    const processItems = (items: any[]) => {
      return items?.flatMap((item) => {
        const updatedItem = {
          skillName: item?.title ?? item?.skillName,
          skillIcon: item?.icon ?? item?.skillIcon,
          type: "template",
          ...item,
        };

        if (item?.selectedDifficultyLevels) {
          return Object.entries(item?.selectedDifficultyLevels)?.map(
            ([level, count]) => ({
              ...updatedItem,
              difficultyLevel: level,
              totalQuestions: count,
            }),
          );
        } else {
          return updatedItem;
        }
      });
    };

    let assessmentQuestionSet;
    if (draggedItem?.length) {
      const updateDraData = draggedItem?.map((item: any) => {
        return { ...item, difficultyLevel: item?.skillLevel };
      });
      assessmentQuestionSet = transformAnswerSet(updateDraData);
    } else {
      // const assessmentSkill = build?.assessmentSkill ?? [];
      const assessmentSkill = build?.assessmentSkill?.length
        ? (build?.assessmentSkill ?? [])
        : (template?.existingPreview?.subjectFieldSet ?? []);
      const mergeQuestion = [
        ...processItems(assessmentSkill),
        ...(custom?.AssessmentCustomQuestions ?? []),
      ];
      assessmentQuestionSet = transformAnswerSet(mergeQuestion);
    }
    return assessmentQuestionSet;
  }

  // const skillIdExists = (assessmentQuestionSet: any) => {
  //   return assessmentQuestionSet.some((question: any) => question.skillId);
  // };

  const handlePublish = async () => {
    const finalData = assessmentDraftJson(tempUniqueId);
    const {
      template,
      setting,
      review,
      userId,
      branchId,
      companyId,
      totalTime,
      totalQuestions,
      tempAssessmentUId,
    } = finalData;
    const { assessmentType, title } = template;
    const assessmentQuestionSet = getPublishSupport();
    // transform evaluation types
    const transformedData = (review?.evaluationTypes ?? []).reduce(
      (acc: any, obj: any) => {
        if (obj.type === "smartAi") {
          if (!acc.smartAi) {
            acc.smartAi = { type: "smartAi", attributes: {} };
          }
          const { ...attributes } = obj; // Extract `type` and the rest as attributes
          acc.smartAi.attributes = { ...acc.smartAi.attributes, ...attributes };
        } else if (obj.type === "manual") {
          acc.manual = obj;
        }
        return acc;
      },
      {},
    );
    const transformEvaluationResult: any = Object.values(transformedData);
    // Delete the `type` attribute from `smartAi` attributes if `smartAi` exists
    if (transformEvaluationResult[0]?.type === "smartAi") {
      delete transformEvaluationResult[0].attributes.type;
    }
    // transform setting data
    const {
      // Comment for future use
      // minimumExperience,
      // maximumResponse,
      // maximumExperience,
      instructions,
      description,
      cutOff,
      // Comment for future use
      // noOfPositions,
      // advanceSettingData,
    } = setting;

    const prepareSetttingData = {
      // Comment for future use
      // minimumExperience:
      //   minimumExperience ||
      //   Number(preferenceData?.createAssessment?.minimumExperience),
      // maxApplications:
      //   maximumResponse ||
      //   Number(preferenceData?.createAssessment?.limitResponses),
      // maximumExperience:
      //   maximumExperience ||
      //   Number(preferenceData?.createAssessment?.maximumExperience),
      instructions,
      description,
      cutOff: cutOff || Number(preferenceData?.createAssessment?.cutOff),
      // Comment for future use
      // noOfPositions:
      //   noOfPositions ||
      //   Number(preferenceData?.createAssessment?.noOfPositions),
      // ...(advanceSettingData && {
      //   jobLocation: advanceSettingData.location,
      //   jobType: advanceSettingData.jobType,
      //   engagementType: advanceSettingData.engagementType,
      // }),
    };

    // const defaultEvaluation = review;
    const data: any = {
      ...prepareSetttingData,
      evaluationTypes: transformEvaluationResult?.length
        ? transformEvaluationResult
        : review?.evaluationTypes,
      isTemp: false,
      hiringType: assessmentType,
      title,
      userId,
      tempAssessmentUId,
      branchId,
      companyId,
      totalTime,
      jobId: jobId ? jobId : null,
      totalQuestions,
      // isPublic: skillIdExists(assessmentQuestionSet) ? isPublic : false,
      assessmentQuestionSet,
      status: isPublish ? "Active" : "Draft",
      assessmentId: editedParams ? tempUniqueId : "",
    };
    if (getSessionStorageItem("existingPreview")?.subjectFieldSet?.length) {
      // if existing template present
      const isExistingTemplate = getSessionStorageItem("existingPreview") ?? [];
      const isOwnPreview = getSessionStorageItem("ownPreview");
      const skillIdExists = skillIdExistsInAnother(
        isExistingTemplate,
        isOwnPreview,
      );
      if (skillIdExists) {
        data.templateId = isExistingTemplate?.assessmentId;
      }
    }
    if (getSessionStorageItem("existingPreview")?.subjectFieldSet?.length) {
      // // if existing template present
      const isExistingTemplate = getSessionStorageItem("existingPreview") ?? [];
      {
        /* // Comment for future use */
      }
      // const isOwnPreview = getSessionStorageItem("ownPreview");
      // const skillIdExists = skillIdExistsInAnother(
      //   isExistingTemplate,
      //   isOwnPreview,
      // );
      // if (skillIdExists) {
      data.templateId = isExistingTemplate?.assessmentId;
      // }
    }

    const paymentDetailsData = getSessionStorageItem("paymentData");
    const countCreditForQuestion = (questionData: any, paymentDetails: any) => {
      const {
        videoType,
        codingType,
        mcqEssayType,
        singleCustomType,
        multipleCustomType,
        essayCustomType,
        videoCustomType,
      } = countCreditCheck(assessmentQuestionSet, paymentDetailsData);
      if (getSessionStorageItem("totalQuestionTime") < 30 && isPublish) {
        showToast(t("GENERAL.MINIMUM_30_MINS_REQUIRE"), "error");
        return 0;
      }
      if (videoType?.type) {
        if (paymentDetails?.packageDetails?.creditForAIVideoQuestion !== -1) {
          const videoType = questionData
            ?.filter((ele: any) => ele?.questionType === "Video") // filter for "Video" question type
            .reduce(
              (total: number, ele: any) => total + (ele?.questionNo || 0),
              0,
            );
          let totalVideoCredit = videoType?.credit;
          if (getSessionStorageItem("isAiEvaluation")) {
            const evaluationAiChecks = getSessionStorageItem("evaluation");
            if (evaluationAiChecks[0]?.aiAnalysis) {
              totalVideoCredit =
                totalVideoCredit +
                videoType *
                  paymentDetails?.packageDetails
                    ?.creditForAIAnalysisVideoQuestion;
            }
            if (evaluationAiChecks[0]?.audioAnalysis) {
              totalVideoCredit =
                totalVideoCredit +
                +videoType *
                  paymentDetails?.packageDetails
                    ?.creditForVoiceAnalysisVideoQuestion;
            }
            if (evaluationAiChecks[0]?.visualAnalysis) {
              totalVideoCredit =
                totalVideoCredit +
                +videoType *
                  paymentDetails?.packageDetails
                    ?.creditForVisualAnalysisVideoQuestion;
            }
          }
          if (
            totalVideoCredit >
            paymentDetails?.packageDetails?.creditForAIVideoQuestion
          ) {
            showToast(
              "Credit limit of video AI question should not be greather than creditForAIVideoQuestion",
            );
            return 0;
          }
        }
      }
      if (codingType?.type) {
        if (paymentDetails?.packageDetails?.maxCodingQuestionLimit !== -1) {
          if (
            codingType?.credit >
            paymentDetails?.packageDetails?.maxCodingQuestionLimit
          ) {
            showToast("Credit limit of coding");
            return 0;
          }
        }
      }
      if (mcqEssayType?.type) {
        if (paymentDetails?.packageDetails?.maxMCQLimit !== -1) {
          if (
            mcqEssayType?.credit > paymentDetails?.packageDetails?.maxMCQLimit
          ) {
            showToast("Credit limit of mcqEssayType");
            return 0;
          }
        }
      }
      if (singleCustomType?.type) {
        if (paymentDetails?.packageDetails?.maxCustomQuestionLimit !== -1) {
          if (
            singleCustomType?.credit >
            paymentDetails?.packageDetails?.maxCustomQuestionLimit
          ) {
            showToast("Credit limit of single ");
            return 0;
          }
        }
      }
      if (multipleCustomType?.type) {
        if (paymentDetails?.packageDetails?.maxCustomQuestionLimit !== -1) {
          if (
            multipleCustomType?.credit >
            paymentDetails?.packageDetails?.maxCustomQuestionLimit
          ) {
            showToast("Credit limit of multiple");
            return 0;
          }
        }
      }
      if (essayCustomType?.type) {
        if (paymentDetails?.packageDetails?.maxCustomQuestionLimit !== -1) {
          if (
            essayCustomType?.credit >
            paymentDetails?.packageDetails?.maxCustomQuestionLimit
          ) {
            showToast("Credit limit of essayCustomType");
            return 0;
          }
        }
      }
      if (videoCustomType?.type) {
        if (paymentDetails?.packageDetails?.maxCustomQuestionLimit !== -1) {
          if (
            videoCustomType?.credit >
            paymentDetails?.packageDetails?.maxCustomQuestionLimit
          ) {
            showToast("Credit limit of videoCustomType");
            return 0;
          }
        }
      }
      return 1;
    };
    const paymentError = countCreditForQuestion(
      assessmentQuestionSet,
      paymentDetailsData,
    );
    if (paymentError !== 0) {
      const publishResponse = await createAssessmentDraftJson(data);
      if (publishResponse?.data?.status === 200) {
        if (isPublish) {
          showToast(
            t("CREATE_ASSESSMENT.ASSESSMENT_CREATED_SUCCESSFULLY"),
            "success",
          );
        } else {
          showToast(t("CREATE_ASSESSMENT.ASSESSMENT_SAVE_AS_DRAFT"), "success");
        }
        if (jobId) {
          navigate(`/${currentLanguage}/job/${jobId}`);
        } else {
          navigate(`/${currentLanguage}/assessment/list`);
        }
        sessionStorage.clear();
        localStorage.removeItem("tempUniqueId");
      } else {
        showToast(
          publishResponse?.data?.customMessage ??
            publishResponse?.data?.error?.error,
          "error",
        );
      }
    }
  };
  return (
    <>
      <Formik
        initialValues={initialReviewData}
        validationSchema={reviewValidationSchema}
        onSubmit={() => {
          handlePublish();
        }}
      >
        {({ errors, touched }) => {
          return (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <Head
                    handlePrevious={handlePrevious}
                    currentStep={activeStep}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>
              <div className="totalQuestionSection">
                <div className="container">
                  <div className="row">
                    <div
                      className={`${addDisableCss(
                        state?.renderJson?.review?.questionSection
                          ?.functionBehavior,
                      )} col-md-12`}
                    >
                      <ReviewSummary />
                      <ReviewQuestions />
                      <div className="d-flex flex-row justify-content-between">
                        <ReviewDescription />
                      </div>
                      <ReviewEvalution />
                    </div>
                    <PackageDetailsTable
                      assessmentQuestionSet={getPublishSupport()}
                    />
                    <div className="draftSaveBtn">
                      {/* // Comment for future use  */}
                      {/* <div className="assessTypeInfoSec">
                        <AssessmentCheckboxRadio
                          type={"switch"}
                          checked={isPublic}
                          label={
                            isPublic
                              ? t("GENERAL.PUBLIC")
                              : t("GENERAL.PRIVATE")
                          }
                          onChange={toggleSwitch}
                          additionalProps={{
                            disabled: isPublishDisable,
                          }}
                        />
                        <div className="iIcon">
                          <Tooltip
                            classname="tooltipOverCss"
                            text={t(t("GENERAL.PUBLIC_PRIVATE_TEXT"))}
                          />
                        </div>
                      </div> */}
                      {isHide(
                        findValuesForKey(
                          state?.renderJson,
                          renderRouteName,
                          ComponentIds.review.saveAsDraftBtnKey,
                        ),
                      ) && (
                        <AssessmentButton
                          type="submit"
                          disabled={isDisable(
                            findValuesForKey(
                              state?.renderJson,
                              renderRouteName,
                              ComponentIds.review.saveAsDraftBtnKey,
                            ),
                          )}
                          className="saveDraftButton"
                          onClick={() => {
                            setIsPublish(false);
                          }}
                        >
                          {t("GENERAL.SAVE_AS_DRAFT")}
                        </AssessmentButton>
                      )}
                      {isHide(
                        findValuesForKey(
                          state?.renderJson,
                          renderRouteName,
                          ComponentIds.review.publishBtnKey,
                        ),
                      ) && (
                        <AssessmentButton
                          type="submit"
                          disabled={
                            isDisable(
                              findValuesForKey(
                                state?.renderJson,
                                renderRouteName,
                                ComponentIds.review.publishBtnKey,
                              ),
                            ) || isQuestionData > 0
                              ? false
                              : true
                          }
                          onClick={() => {
                            setIsPublish(true);
                          }}
                        >
                          {t("GENERAL.PUBLISH")}
                        </AssessmentButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AssessmentReview;
