const UserIcon = () => {
  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 9C11.8472 9 13.75 7.20914 13.75 5C13.75 2.79086 11.8472 1 9.5 1C7.15279 1 5.25 2.79086 5.25 5C5.25 7.20914 7.15279 9 9.5 9Z"
        stroke="#4D4D4D"
        strokeWidth="1.5"
      />
      <path
        d="M18 16.5C18 18.9853 18 21 9.5 21C1 21 1 18.9853 1 16.5C1 14.0147 4.80558 12 9.5 12C14.1944 12 18 14.0147 18 16.5Z"
        stroke="#4D4D4D"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default UserIcon;
