import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

// Company flow (submit)
export function PublicCompanyRoute({ children }: any) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const rechargeSlabID = new URLSearchParams(location.search).get("sId");
  const templateID = new URLSearchParams(location.search).get("tempId");
  const token = localStorage.getItem("companyToken");
  // const tempUniqueId = localStorage.getItem("tempUniqueId");
  const isAuthenticated = Boolean(token);
  const currentPath = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);
  //on reset password screen we dont want user go inside the application
  const isResetPassword = currentPath.includes("/reset-password");
  const isCompanySignupByLink =
    currentPath.includes("/company-signup") &&
    searchParams.has("q") &&
    searchParams.has("e");
  if (isCompanySignupByLink) {
    localStorage.removeItem("companyToken");
  }
  return isAuthenticated && !isResetPassword && !isCompanySignupByLink ? (
    rechargeSlabID ? (
      <Navigate
        to={`/${currentLanguage}/account/account-details`}
        state={{ rechrSlab: "true" }}
      />
    ) : templateID ? (
      <Navigate
        to={`/${currentLanguage}/assessment/template/${templateID}`}
        state={{ templateID: templateID }}
      />
    ) : (
      <Navigate to={`/${currentLanguage}/jobs`} />
    )
  ) : (
    <>{children}</>
  );
}

// Company flow (submit)
export function PrivateCompanyRoute({ children }: any) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const token = localStorage.getItem("companyToken");
  const isAuthenticated = Boolean(token);
  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate to={`/${currentLanguage}/company-login`} />
  );
}
