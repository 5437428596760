import {
  AssessmentButton,
  AssessmentCheckboxRadio,
  Bullets,
  Pagination,
  RightSidePanel,
  SearchTypeSelect,
  // SkeletonElement,
  SkeletonList,
  TemplateSearch,
  showToast,
} from "ui-components";
import "./CreatedAssessmentList.scss";
import { useTranslation } from "react-i18next";
import AssesmentListingIcon from "../../../utils/Images/AssesmentListingIcon";
import { useEffect, useRef, useState } from "react";
import AssessmentListingDeleteIcon from "../../../utils/Images/AssessmentListingDeleteIcon";
import AssessmentListingInviteIcon from "../../../utils/Images/AssessmentListingInviteIcon";
import { getCreatedAssessList } from "../../../service/CreatedAssessmentListAndReview/CreatedAssessmentListAndReview";
import {
  convertDate,
  handleKeyPressSearch,
  manageDuplicateSkills,
} from "../../../utils/Common/Constant";
import {
  deleteAssessment,
  getDefaultSkill,
} from "../../../service/Scope/Scope";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ConfirmPopup from "./ConfirmPopup/ConfirmPopup";
import useDebounce from "../../../utils/Hooks/useDebounce";
import AiStarIcon from "../../../utils/Images/AiStarIcon";
import CreditCoin from "../../../utils/Images/CreditCoin";
import Poppers from "../../../components/Poppers/Poppers";
import FloatingWindow from "../../../components/FloatingWindow/FloatingWindow";
import NoAssessListFoundIcon from "../../../utils/Images/NoAssessListFoundIcon";
function CreatedAssessmentList() {
  const { t, i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const location = useLocation();
  const [showPopper, setShowPopper] = useState(false);
  const [showFilter] = useState(true);
  const [createdAssessmentList, setCreatedAssessmentList] = useState([]);
  const [getSkillList, setGetSkillList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [deleteAssessmentTitle, setDeleteAssessmentTitle] = useState(null);
  const [selectAssessmentId, setSelectAssessmentId] = useState(null);
  const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [isResetDisable, setIsResetDisable] = useState(false);
  const createdAssessPaginaitonRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("st") ?? "Active";
  const skillName = searchParams.get("skname");
  const skill = searchParams.get("sk");
  const hasResponse = searchParams.get("hasResponse") ?? "both";
  const hasReview = searchParams.get("hasReview") ?? "both";
  const selected = searchParams.get("selected") ?? "both";
  const searchParam = searchParams.get("search");
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const urlRef = useRef(null);
  const timeoutRef = useRef<any>(null);
  const defaultSelectSkills = { value: skill ?? "", label: skillName ?? "" };
  const [assessmentListLoading, setAssessmentListLoading] = useState(true);
  const [skillIdFromSelect, setSkillIdFromSelect] =
    useState<any>(defaultSelectSkills);
  const debounceSkillSearchValue = useDebounce(
    skillIdFromSelect?.label?.trim(),
    400,
  );
  const industriesSkillId = localStorage.getItem("industryId") ?? "";
  const companyId = localStorage.getItem("companyId") ?? "";
  const [isVisible, setIsVisible] = useState<string | null>(null);
  const FloatingWindowRef = useRef<any>(null);
  const toggleFloatingWindow = (assessmentId: string) => {
    setIsVisible((prev: any) => (prev === assessmentId ? null : assessmentId));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (location?.state?.showPoppers) {
      setShowPopper(true);
    }
    // Set a timer to turn off the popper after 3 seconds
    const timer = setTimeout(() => {
      setShowPopper(false);
    }, 6000);

    // Clear the timer if the component unmounts or if location changes
    return () => clearTimeout(timer);
  }, [location?.state?.showPoppers]);

  useEffect(() => {
    status !== "Active" ||
    skill ||
    hasResponse !== "both" ||
    hasReview !== "both" ||
    selected !== "both" ||
    searchParam?.length
      ? setIsResetDisable(false)
      : setIsResetDisable(true);
  }, [status, skill, hasResponse, hasReview, selected, searchParam]);
  useEffect(() => {
    if (createdAssessPaginaitonRef.current && managePaginationRef != null) {
      createdAssessPaginaitonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [managePaginationRef]);

  useEffect(() => {
    if (industriesSkillId !== "null") {
      getSkills(debounceSkillSearchValue ? true : undefined);
    }
  }, [industriesSkillId, debounceSkillSearchValue]);

  useEffect(() => {
    handleFilter({ type: "skill", value: skillIdFromSelect?.value });
  }, [skillIdFromSelect?.value]);

  useEffect(() => {
    // Function to handle debouncing
    const debounceFunction = () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current as any); // Cast to any
      }
      timeoutRef.current = setTimeout(getAssessmentList, 500);
    };
    // Trigger debounce function when search parameter changes
    debounceFunction();
    // Cleanup function to clear timeout when component unmounts
    return () => clearTimeout(timeoutRef.current);
  }, [location.search, searchParam, pageSize, skill]);

  // to get assessment list
  async function getAssessmentList() {
    const data = {
      companyId: companyId,
      search: searchParam?.trim() ?? "",
      filterOptions: {
        status: status ?? "All",
        skillId: skill ?? "",
        hasResponse: hasResponse,
        reviewPending: hasReview,
        selected: selected,
      },
      currentPage: page ?? 1,
      pageSize,
    };
    const assessmentRes = await getCreatedAssessList(data);
    if (assessmentRes?.data?.status === 200) {
      setCreatedAssessmentList(
        assessmentRes?.data?.data?.data.map((assessment: any) => {
          return { ...assessment, moreOption: false };
        }) ?? [],
      );
      setTotalCount(assessmentRes?.data?.data?.totalCount);
      setAssessmentListLoading(false);
    } else {
      showToast(assessmentRes?.data?.customMessage, "error");
      setAssessmentListLoading(false);
    }
  }
  // to get skills
  async function getSkills(firstLoad: boolean = false) {
    const industriesSkill = await getDefaultSkill(
      industriesSkillId,
      debounceSkillSearchValue,
      1,
      100000,
    );
    const updateData = (industriesSkill?.data || []).map((item: any) => ({
      value: item?.skillId,
      label: `${item?.skillName} - ${item?.questionType}`,
      ...item,
    }));
    setGetSkillList(updateData);
    if (firstLoad) {
      // setTitle(updateData);
    }
  }
  // to set the title
  // const setTitle = async (updatedDataSet: any) => {
  //   const fetchedLabel = await getLabelByValue(
  //     updatedDataSet,
  //     skillIdFromSelect?.value ?? "",
  //   );
  // };

  // to delete assessment
  async function deleteAssessmentById() {
    const deleteAssessmentResponse = await deleteAssessment(selectAssessmentId);
    if (deleteAssessmentResponse?.data?.status === 200) {
      showToast(deleteAssessmentResponse?.data?.customMessage, "success");
      getAssessmentList();
      setSelectAssessmentId(null);
      setShowDelete(false);
      setDeleteAssessmentTitle(null);
    } else {
      showToast(deleteAssessmentResponse?.data?.customMessage, "error");
    }
  }
  // clear all filters
  const clearAllFilter = () => {
    setSkillIdFromSelect({ value: "", label: "" });
    // Constructing the final URL
    const url = `/${currentLanguage}/assessment/list`;
    // Navigating to the final URL
    navigate(url);
  };
  // handle filters
  const handleFilter = ({ type, value }: any) => {
    // Constructing the URL parameters object
    const currentLanguage = i18n.language || "en";
    let params = {
      st: status,
      sk: skill,
      skname: skillName,
      hasResponse: hasResponse,
      hasReview: hasReview,
      selected: selected,
      page: page,
      search: searchParam,
    };
    switch (type) {
      case "status":
        params = { ...params, st: value, page: !isResetDisable ? page : 1 };
        break;
      case "skill":
        params = { ...params, sk: value, page: !isResetDisable ? page : 1 };
        break;
      case "hasResponse":
        params = {
          ...params,
          hasResponse: value,
          page: !isResetDisable ? page : 1,
        };
        break;
      case "hasReview":
        params = {
          ...params,
          hasReview: value,
          page: !isResetDisable ? page : 1,
        };
        break;
      case "selected":
        params = {
          ...params,
          selected: value,
          page: !isResetDisable ? page : 1,
        };
        break;
      case "page":
        params = { ...params, page: value };
        break;
      case "search":
        params = { ...params, search: value, page: !isResetDisable ? page : 1 };
        break;
      case "skillName":
        params = { ...params, skname: value, page: !isResetDisable ? page : 1 };
    }

    // Constructing the URL based on the parameters object
    const urlParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]: any) => {
      if (value) {
        urlParams.append(key, value);
      }
    });

    // Constructing the final URL
    const url = `/${currentLanguage}/assessment/list${urlParams.toString() ? `?${urlParams.toString()}` : ""}`;

    // Navigating to the final URL
    navigate(url);
  };

  return (
    <div className={showPopper ? "hideScroll" : ""}>
      {showPopper && (
        <>
          <Poppers runFun={showPopper} />
        </>
      )}
      <div className="CreatedAssessmentList">
        <div className="CreatedAssessmentListTitle">
          <h2>{t("CREATED_ASSESSMENT_LIST_AND_REVIEW.ASSESSMENT")}</h2>
        </div>
        {showFilter && (
          <div className="searchFilters">
            {/* publish draft close status */}
            <div className="statusFilter">
              <div className="w-75">
                <div className="d-flex responseSecFilter">
                  <div
                    className="d-flex cursorPointer rtlGap"
                    onClick={() => {
                      handleFilter({ type: "status", value: "Active" });
                    }}
                  >
                    <AssessmentCheckboxRadio
                      name="status"
                      type="radio"
                      checked={status == "Active"}
                    />
                    <p className="radioCheckLabel">{t("GENERAL.PUBLISHED")}</p>
                  </div>
                  <div
                    className="d-flex cursorPointer rtlGap"
                    onClick={() => {
                      handleFilter({ type: "status", value: "Draft" });
                    }}
                  >
                    <AssessmentCheckboxRadio
                      name="status"
                      type="radio"
                      checked={status == "Draft"}
                    />{" "}
                    <p className="radioCheckLabel">{t("GENERAL.DRAFT")}</p>
                  </div>
                  <div
                    className="d-flex cursorPointer rtlGap"
                    onClick={() => {
                      handleFilter({ type: "status", value: "Closed" });
                    }}
                  >
                    <AssessmentCheckboxRadio
                      name="status"
                      type="radio"
                      checked={status == "Closed"}
                    />{" "}
                    <p className="radioCheckLabel">{t("GENERAL.CLOSED")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`AssessmentListSearchSec ${showFilter ? "" : "addMargin"}`}
              ref={createdAssessPaginaitonRef}
            >
              <TemplateSearch
                onChange={(e) => {
                  handleFilter({ type: "search", value: e });
                }}
                placeholder={t("GENERAL.SEARCH_ASSESSMENT")}
                handleKeyPress={handleKeyPressSearch}
                iconName="searchList"
                value={searchParam ?? ""}
              />
              <AssessmentButton
                className="lightBtn mainLabelFilter filterBtn"
                onClick={() => clearAllFilter()}
                disabled={isResetDisable}
              >
                {t("GENERAL.RESET")}
              </AssessmentButton>
            </div>
            <div className={`${showFilter && "filterClicked"}`}>
              <div className="w-75">
                <SearchTypeSelect
                  options={getSkillList}
                  placeholder={t("GENERAL.SKILL")}
                  value={skillIdFromSelect}
                  onChange={(getValue: any) => {
                    handleFilter({ type: "skillName", value: getValue?.label });
                    setSkillIdFromSelect(getValue);
                  }}
                  loadingText={t("GENERAL.LOADING")}
                  className="skillAssessList"
                  liIconName="icon"
                  outSideClickCallBack={() => {
                    // setTitle(getSkillList);
                  }}
                />
              </div>
              <div className="w-75">
                <label htmlFor="" className="mainLabelFilter">
                  {t("GENERAL.HAS_RESPONSE")}
                </label>
                <div className="d-flex responseSecFilter">
                  <div
                    className="d-flex cursorPointer rtlGap"
                    onClick={() => {
                      handleFilter({ type: "hasResponse", value: "yes" });
                    }}
                  >
                    <AssessmentCheckboxRadio
                      name="hasResponse"
                      type="radio"
                      checked={hasResponse == "yes"}
                    />
                    <p className="radioCheckLabel">{t("GENERAL.YES")}</p>
                  </div>
                  <div
                    className="d-flex cursorPointer rtlGap"
                    onClick={() => {
                      handleFilter({ type: "hasResponse", value: "no" });
                    }}
                  >
                    <AssessmentCheckboxRadio
                      name="hasResponse"
                      type="radio"
                      checked={hasResponse == "no"}
                    />{" "}
                    <p className="radioCheckLabel">{t("GENERAL.NO")}</p>
                  </div>
                  <div
                    className="d-flex cursorPointer rtlGap"
                    onClick={() => {
                      handleFilter({ type: "hasResponse", value: "both" });
                    }}
                  >
                    <AssessmentCheckboxRadio
                      name="hasResponse"
                      type="radio"
                      checked={hasResponse == "both"}
                    />{" "}
                    <p className="radioCheckLabel">{t("GENERAL.BOTH")}</p>
                  </div>
                </div>
              </div>
              <div className="w-75">
                <label htmlFor="" className="mainLabelFilter">
                  {t("CREATED_ASSESSMENT_LIST_AND_REVIEW.REVIEW_PENDING")}
                </label>
                <div className="d-flex responseSecFilter">
                  <div
                    className="d-flex cursorPointer rtlGap"
                    onClick={() => {
                      handleFilter({ type: "hasReview", value: "yes" });
                    }}
                  >
                    <AssessmentCheckboxRadio
                      type="radio"
                      checked={hasReview == "yes"}
                    />{" "}
                    <p className="radioCheckLabel">{t("GENERAL.YES")}</p>
                  </div>
                  <div
                    className="d-flex cursorPointer rtlGap"
                    onClick={() => {
                      handleFilter({ type: "hasReview", value: "no" });
                    }}
                  >
                    <AssessmentCheckboxRadio
                      type="radio"
                      checked={hasReview == "no"}
                    />{" "}
                    <p className="radioCheckLabel">{t("GENERAL.NO")}</p>
                  </div>
                  <div
                    className="d-flex cursorPointer rtlGap"
                    onClick={() => {
                      handleFilter({ type: "hasReview", value: "both" });
                    }}
                  >
                    <AssessmentCheckboxRadio
                      type="radio"
                      checked={hasReview == "both"}
                    />{" "}
                    <p className="radioCheckLabel">{t("GENERAL.BOTH")}</p>
                  </div>
                </div>
              </div>
              <div className="w-75">
                <div className="manageClearAll">
                  <label htmlFor="" className="mainLabelFilter">
                    {t("CREATED_ASSESSMENT_LIST_AND_REVIEW.SELECTED")}
                  </label>
                </div>
                <div className="d-flex responseSecFilter topMargin">
                  <div
                    className="d-flex cursorPointer rtlGap"
                    onClick={() => {
                      handleFilter({ type: "selected", value: "yes" });
                    }}
                  >
                    <AssessmentCheckboxRadio
                      type="radio"
                      checked={selected == "yes"}
                    />{" "}
                    <p className="radioCheckLabel">{t("GENERAL.YES")}</p>
                  </div>
                  <div
                    className="d-flex cursorPointer rtlGap"
                    onClick={() => {
                      handleFilter({ type: "selected", value: "no" });
                    }}
                  >
                    <AssessmentCheckboxRadio
                      type="radio"
                      checked={selected == "no"}
                    />
                    <p className="radioCheckLabel">{t("GENERAL.NO")}</p>
                  </div>
                  <div
                    className="d-flex cursorPointer rtlGap"
                    onClick={() => {
                      handleFilter({ type: "selected", value: "both" });
                    }}
                  >
                    <AssessmentCheckboxRadio
                      type="radio"
                      checked={selected == "both"}
                    />{" "}
                    <p className="radioCheckLabel">{t("GENERAL.BOTH")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="AssessmentListContentSec">
          <p className="totalContent">
            {t("CREATED_ASSESSMENT_LIST_AND_REVIEW.TOTAL_ASSESSMENTS")} :{" "}
            {totalCount}
          </p>
          {!assessmentListLoading ? (
            createdAssessmentList.length > 0 ? (
              createdAssessmentList.map((list: any, index: any) => {
                const isAIEnabled = list?.evaluationType.some(
                  (item: any) => item.type === "smartAi",
                );
                return (
                  <div
                    className="AssessmentListCardWrapper"
                    key={Math.random() + index}
                  >
                    <div
                      className="AssessmentListCardSec"
                      key={Math.random() + index}
                    >
                      <div
                        className={`AssessmentListCard ${isVisible === list?.assessmentId ? "blurred-card" : ""}`}
                      >
                        <div
                          className="AssessmentListCardImg cursorPointer"
                          onClick={() => {
                            navigate(
                              `/${currentLanguage}/assessment/reviewlist/${list?.assessmentId}`,
                            );
                          }}
                        >
                          <AssesmentListingIcon />
                          <h4>{list?.status ?? "-"}</h4>
                          <h6>
                            {convertDate(list?.createdAt, false, true) ?? "-"}
                          </h6>
                        </div>
                        <div className="AssessmentListCardDetails">
                          <div className="ListCardDetailsTitleSec">
                            <div className="ListCardDetailsTitle">
                              <div className="listItemSec">
                                <div className="ListCardTitleHeading">
                                  <div>
                                    <div className="clipBoard">
                                      <h3
                                        title={list?.title ?? ""}
                                        className="cursorPointer"
                                        ref={urlRef}
                                        onClick={() => {
                                          navigate(
                                            `/${currentLanguage}/assessment/reviewlist/${list?.assessmentId}`,
                                          );
                                        }}
                                      >
                                        {list?.title}{" "}
                                      </h3>
                                    </div>
                                  </div>
                                  {list?.status == "Active" ? (
                                    <div className="d-flex">
                                      <div className="invite-screen-visibliblity-sec">
                                        <div
                                          className="cursorPointer"
                                          title={t("GENERAL.INVITE")}
                                          onClick={() => {
                                            toggleFloatingWindow(
                                              list?.assessmentId,
                                            );
                                          }}
                                        >
                                          <AssessmentListingInviteIcon />
                                        </div>
                                        {isVisible === list?.assessmentId && (
                                          <div ref={FloatingWindowRef}>
                                            <RightSidePanel
                                              show={true}
                                              handleClose={() => {
                                                toggleFloatingWindow(
                                                  list?.assessmentId,
                                                );
                                              }}
                                              title={list?.title}
                                              className="rightSidePanelSection "
                                            >
                                              <FloatingWindow
                                                assessmentId={
                                                  list?.assessmentId
                                                }
                                                credit={
                                                  list?.meta?.credit ||
                                                  list?.credits
                                                }
                                              />
                                            </RightSidePanel>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ) : list?.status == "draft" ||
                                    list?.status == "Draft" ? (
                                    <>
                                      <div className="d-flex">
                                        <div
                                          className="cursorPointer"
                                          title={t("GENERAL.DELETE")}
                                          onClick={() => {
                                            setSelectAssessmentId(
                                              list?.assessmentId,
                                            );
                                            if (list?.assessmentId) {
                                              setShowDelete(true);
                                              setDeleteAssessmentTitle(
                                                list?.title,
                                              );
                                            }
                                          }}
                                        >
                                          <AssessmentListingDeleteIcon />
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="bulletSec">
                                  {(
                                    manageDuplicateSkills(
                                      list?.assessmentSkills,
                                    ) || []
                                  ).map((skillList: any) => (
                                    <Bullets
                                      key={Math.random()}
                                      skillName={skillList?.skillName ?? ""}
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="ListContentSkillsSec">
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-md-10">
                                  <div className="ListContentSkills decreaseGap">
                                    <div className="w-100 detailBlocks">
                                      <div className="w-100">
                                        <p>{t("GENERAL.APPLICANT")}</p>
                                        <h5>
                                          {list?.totalApplicaitons ?? "-"}
                                        </h5>
                                      </div>
                                      <div>
                                        <p>{t("GENERAL.QUESTIONS")}</p>
                                        <h5>{list?.totalQuestions ?? "-"}</h5>
                                      </div>
                                    </div>
                                    <div className="w-100 detailBlocks">
                                      <div className="w-100">
                                        <p>{t("GENERAL.RESPONSES")}</p>
                                        <h5>{list?.totalResponses ?? "-"}</h5>
                                      </div>
                                      <div>
                                        <p>{t("CREATE_ASSESSMENT.DURATION")}</p>
                                        <h5>
                                          {list?.totalTime ?? "-"}{" "}
                                          {t("GENERAL.MINS")}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="w-100 detailBlocks">
                                      <div className="w-100">
                                        <p>{t("GENERAL.REVIEWED")}</p>
                                        <h5>{list?.reviewed ?? "-"}</h5>
                                      </div>
                                      <div>
                                        <p>{t("GENERAL.CREDITS")}</p>
                                        <div className="d-flex">
                                          {list?.meta && (
                                            <div>
                                              <CreditCoin
                                                width={21}
                                                height={21}
                                              />{" "}
                                            </div>
                                          )}
                                          <h5>
                                            {list?.meta
                                              ? list?.meta?.credit ||
                                                list?.credits
                                              : "-"}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-100 detailBlocks">
                                      <div className="w-100">
                                        <p>
                                          {t(
                                            "CREATED_ASSESSMENT_LIST_AND_REVIEW.SELECTED",
                                          )}
                                        </p>
                                        <h5>{list?.totalSelected ?? "-"}</h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 viewBtn">
                                  <div className="RightSideArrowSec">
                                    {isAIEnabled && (
                                      <div className="starLogo">
                                        <AiStarIcon />
                                      </div>
                                    )}
                                    <AssessmentButton
                                      onClick={() => {
                                        list?.status == "Draft"
                                          ? navigate(
                                              `/${currentLanguage}/assessment/template/${list?.assessmentId}?edited=true${list?.jobId ? `&jobId=${list?.jobId}` : ""}`,
                                            )
                                          : navigate(
                                              `/${currentLanguage}/assessment/reviewlist/${list?.assessmentId}`,
                                            );
                                      }}
                                      className="whiteBtn"
                                    >
                                      {list?.status == "Draft"
                                        ? t("GENERAL.EDIT")
                                        : t("GENERAL.VIEW")}
                                    </AssessmentButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="noListFound">
                <div className="text-center">
                  <NoAssessListFoundIcon />
                  {industriesSkillId === "null" ? (
                    <>
                      <h1>{t("GENERAL.NO_ACTIVE_ASSESSMENTS")}</h1>
                      <h6>{t("GENERAL.CLICK_ON_CREATE_ASSESS")}</h6>
                    </>
                  ) : (
                    <h1>{t("INVITE.NO_ASSESSMENT_FOUND")}</h1>
                  )}
                </div>
              </div>
            )
          ) : (
            <>
              {Array.from({ length: 5 }).map((_, index) => (
                <SkeletonList
                  key={`skeleton-${index}`}
                  numberOfTitles={1}
                  numberofTextLines={5}
                  titleSize={18}
                  textSize={12}
                  thumbnailHeight={250}
                  thumbnailWidth={270}
                  itemsGap={20}
                />
              ))}
            </>
          )}
        </div>

        <div className="row">
          <div className="col-md-12">
            <Pagination
              className="pagination-bar"
              currentPage={page ?? 1}
              totalCount={totalCount || 0}
              pageSize={pageSize}
              onPageChange={(page: number) => {
                handleFilter({ type: "page", value: page });
                setManagePaginationRef(Math.random());
              }}
              ofLabel={t("GENERAL.OF")}
              resultLabel={t("GENERAL.RESULTS")}
              setPageSize={setPageSize}
            />
          </div>
        </div>
        {/* confirm popup for delete */}
        {showDelete && (
          <ConfirmPopup
            showDelete={showDelete}
            label={`${t("CREATED_ASSESSMENT_LIST.CONFIRM_DELETE_START")} ${deleteAssessmentTitle}?`}
            yesCallback={() => deleteAssessmentById()}
            noCallback={() => {
              setShowDelete(false);
              setDeleteAssessmentTitle(null);
            }}
            isCrossBtn={true}
            handleClose={() => {
              setShowDelete(false);
              setDeleteAssessmentTitle(null);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default CreatedAssessmentList;
