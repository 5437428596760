const FileUploadIcon = () => {
  return (
    <svg
      width="54"
      height="53"
      viewBox="0 0 54 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.0618 28.3106L54 38.7649L46.342 40.9526L51.8157 50.4263L47.3543 53L41.8807 43.5288L36.1545 49.0623L36.0618 28.3106ZM30.9101 10.2948H36.0618V15.4421H48.941C49.6242 15.4421 50.2794 15.7133 50.7624 16.196C51.2455 16.6786 51.5169 17.3332 51.5169 18.0158V28.3106H46.3652V20.5895H20.6068V46.3264H30.9101V51.4738H18.0309C17.3478 51.4738 16.6926 51.2026 16.2095 50.72C15.7264 50.2373 15.4551 49.5827 15.4551 48.9001V36.0317H10.3034V30.8843H15.4551V18.0158C15.4551 17.3332 15.7264 16.6786 16.2095 16.196C16.6926 15.7133 17.3478 15.4421 18.0309 15.4421H30.9101V10.2948ZM5.15169 30.8843V36.0317H0V30.8843H5.15169ZM5.15169 20.5895V25.7369H0V20.5895H5.15169ZM5.15169 10.2948V15.4421H0V10.2948H5.15169ZM5.15169 0V5.14738H0V0H5.15169ZM15.4551 0V5.14738H10.3034V0H15.4551ZM25.7584 0V5.14738H20.6068V0H25.7584ZM36.0618 0V5.14738H30.9101V0H36.0618Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default FileUploadIcon;
