import { useState, useEffect } from "react";
import { getAddressFromLatLng } from "../../service/Scope/Scope";

const useCurrentLocation = () => {
  const [coords, setCoords] = useState<any>(null);
  const [address, setAddress] = useState<any>({
    country: "",
    state: "",
    city: "",
    postalCode: "",
    fullAddress: "",
    countryCode: "", // Added countryCode state
  });

  useEffect(() => {
    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            setCoords({ lat: latitude, lng: longitude });
            try {
              const response = await getAddressFromLatLng(latitude, longitude);
              const result = response?.data[0];
              if (result) {
                const addressComponents = result.address_components;
                const formattedAddress = {
                  country: "",
                  state: "",
                  city: "",
                  postalCode: "",
                  fullAddress: result.formatted_address || "", // Full address string
                  countryCode: "", // Country code
                };

                addressComponents.forEach((component: any) => {
                  if (component.types.includes("country")) {
                    formattedAddress.country = component.long_name;
                    formattedAddress.countryCode = component.short_name; // Extract country code
                  }
                  if (component.types.includes("administrative_area_level_1")) {
                    formattedAddress.state = component.long_name;
                  }
                  if (component.types.includes("locality")) {
                    formattedAddress.city = component.long_name;
                  }
                  if (component.types.includes("postal_code")) {
                    formattedAddress.postalCode = component.long_name;
                  }
                });

                setAddress(formattedAddress);
              }
            } catch (error) {
              console.error("Error getting address: ", error);
            }
          },
          (error) => {
            console.error("Error getting coords: ", error);
          },
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    };

    getCurrentLocation();
  }, []);

  return { coords, address };
};

export default useCurrentLocation;
