import { useTranslation } from "react-i18next";
import "./JobDetails.scss";
import { AssessmentButton, showToast } from "ui-components";
import {
  convertDate,
  copyToClipboard,
  expandText,
  getTruncatedText,
  typesKeyPair,
} from "../../../../../../utils/Common/Constant";
import { useEffect, useRef, useState } from "react";
import CopyVector from "../../../../../../utils/Images/CopyVector";
import DownArrow from "../../../../../../utils/Images/DownArrow";
import AiStarIcon from "../../../../../../utils/Images/AiStarIcon";
import AddCreditForCv from "../../../../Common/AddCreditForCv/AddCreditForCv";
import { addCVCredits } from "../../../../../../service/CvScanning/CvScanning";
const JobDetails = ({ jobData }: any) => {
  const { t } = useTranslation();
  const urlRef = useRef(null);
  const [jobDetail, setJobDetail] = useState<any>({});
  const [creditHistoryOpen, setCreditHistoryOpen] = useState<any>(false);
  const [showAddMoreCredit, setShowAddMoreCredit] = useState(false);
  const [addedMoreCredit, setAddedMoreCredit] = useState<any>({});
  const addMoreCreditRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const descriptionRef = useRef<any>(null);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds a smooth scroll effect
    });
    setJobDetail(jobData);
  }, [jobData?.jobId]);

  const handleAddCredit = async () => {
    const addCreditData = {
      jobId: jobData?.jobId,
      cvPackage: addedMoreCredit?.label,
    };
    const addCreditDataRes = await addCVCredits(addCreditData);
    if (addCreditDataRes?.data?.status === 200) {
      showToast(addCreditDataRes?.data?.customMessage, "success");
      setShowAddMoreCredit(false);
      window.location.reload(); // Refresh the page
    } else {
      setShowAddMoreCredit(false);
    }
  };
  // Handle clicking outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        addMoreCreditRef.current &&
        !addMoreCreditRef.current.contains(event.target as Node)
      ) {
        setShowAddMoreCredit(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="jobDetails mb-5">
      <div className="joblinkWithCloseJob">
        <span className="jobLink">{t("RESUME.SCANNING_JOB_LINK")}</span>
        <div className="clipboardurl mt-3">
          <div className="URLCopyJoblink">
            <div className="joblinkInput" title={t("RESUME.SCANNING_JOB_LINK")}>
              <p ref={urlRef}>{jobDetail?.applyLink}</p>
              <AssessmentButton
                className="lightBtn"
                onClick={() =>
                  copyToClipboard(
                    urlRef,
                    jobDetail?.applyLink,
                    t("RESUME.JOB_LINK_COPY"),
                  )
                }
              >
                <CopyVector />
              </AssessmentButton>
            </div>
          </div>
        </div>
      </div>

      <div className="relevantProjectExperience decreaseGap">
        <div className="w-100">
          <div className="w-100 mb-4">
            <p>{t("RESUME.SCANNING_EXPERIENCE")}</p>
            <h5>{`${jobDetail?.minimumExperience ?? "3"} - ${jobDetail?.maximumExperience ?? "5"} years`}</h5>
          </div>
          <div>
            <p>{t("GENERAL.ENGAGEMENT")}</p>
            <h5>{typesKeyPair[jobDetail?.jobType] ?? "-"}</h5>
          </div>
        </div>
        <div className="w-100">
          <div className="w-100 mb-4">
            <p>{t("RESUME.SCANNING_OPEN_POSITIONS")}</p>
            <h5>{jobDetail?.noOfPositions ?? 0}</h5>
          </div>
          <div>
            <p>{t("RESUME.SCANNING_UPLOADED_CVS")}</p>
            <h5>{jobDetail?.uploadedCVs ?? "0"}</h5>
          </div>
        </div>
        <div className="w-100">
          <div className="w-100 mb-4">
            <p>{t("GENERAL.JOB_TYPE")}</p>
            <h5>{typesKeyPair[jobDetail?.engagementType] ?? "-"}</h5>
          </div>
          <div>
            <p>{t("GENERAL.CREATED")}</p>
            <h5>
              {jobDetail?.createdAt
                ? convertDate(jobDetail?.createdAt, false, true)
                : "-"}
            </h5>
          </div>
        </div>
      </div>
      <div className="horizontalLine"></div>
      <div className="aiScanStatus">
        <div className="statusCredit">
          <span className="scannedCvText">
            <AiStarIcon />
            &nbsp; {t("RESUME.SCAN_STATUS")}
          </span>
          <span>
            {t("RESUME.ASSESSMENT_SCANNED_CVS")} :{" "}
            <b>{jobDetail?.totalCvScanned}</b>
          </span>
          <span>
            {t("RESUME.REMAINING")} : <b>{jobDetail?.balance}</b>
          </span>

          {/* 1.
          <p>
            {jobDetail?.totalCvScanned}/{jobDetail?.balance}
          </p> */}
        </div>
      </div>
      <div className="horizontalLine"></div>
      <div className="moreCredtAndHistory d-flex gap-5 mb-4 mt-4">
        <div className="addMoreCreditToJob">
          <div
            className="moreCredit"
            onClick={() => setShowAddMoreCredit(!showAddMoreCredit)}
          >
            <span className="creditToJobtitle">{t("RESUME.SCAN_MORE_CV")}</span>
            <p className={showAddMoreCredit ? "upArrow" : "downArrow"}>
              <DownArrow />
            </p>
          </div>
          {showAddMoreCredit && (
            <div className="creditItem" ref={addMoreCreditRef}>
              <AddCreditForCv
                isBtnShow={true}
                handleSelectedCredit={(value: any) => setAddedMoreCredit(value)}
                handleAddCreditFUnc={() => handleAddCredit()}
              />
            </div>
          )}
        </div>
        <div className="creditHistory ">
          <div className="credit">
            <div
              onClick={() => setCreditHistoryOpen(!creditHistoryOpen)}
              className="credithistoryopen"
            >
              <span>{t("RESUME.ASSESSMENT_CREDIT_HISTORY")}</span>
              <p className={creditHistoryOpen ? "upArrow" : "downArrow"}>
                <DownArrow />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="horizontalLine"></div>
      <div className="keywords">
        <p>{t("RESUME.SCANNING_KEYWORDS")}</p>
        <div className="words">
          {jobDetail?.keywords?.length ? (
            jobDetail?.keywords?.split(",")?.map((item: any, ind: number) => {
              return (
                <div key={ind} className="wordsText">
                  <span>{item}</span>
                </div>
              );
            })
          ) : (
            <span className="naText">NA</span>
          )}
        </div>
      </div>
      <div className="location">
        <p>{t("RESUME.SCANNING_LOCATION")}</p>
        <span>{`${jobDetail?.addressLine1} ${jobDetail?.addressLine2}`}</span>
      </div>
      <div className="description">
        <p>{t("RESUME.SCANNING_DESCRIPTION")}</p>
        <div
          ref={descriptionRef}
          dangerouslySetInnerHTML={{
            __html: isExpanded
              ? jobDetail?.description
              : getTruncatedText(jobDetail?.description, 25),
          }}
        />
        {jobDetail?.description?.length > 0 && (
          <span
            onClick={() =>
              expandText(descriptionRef, setIsExpanded, isExpanded)
            }
            className="readMoreBtn"
          >
            {isExpanded ? "Read Less" : "Read More"}
          </span>
        )}
      </div>
    </div>
  );
};

export default JobDetails;
