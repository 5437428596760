import React, { useState, useEffect, useRef } from "react";
import handleApplicantLogin from "../LoginApiCall";
import { useNavigate } from "react-router-dom";
import "./LinkedInCallback.scss";
import i18n from "../../../../i18nConfig";
import { getLinkedInUserInfo } from "../../../../service/Scope/Scope";
import Loader from "../../../../components/Loader/Loader";
const LinkedInCallback = () => {
  const [user] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const code: string | null = new URLSearchParams(window.location.search).get(
    "code",
  );
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  // fetch user profile
  const fetchUserProfile = async (code: string) => {
    setLoading(true);
    try {
      const response: any = await getLinkedInUserInfo(code);
      if (response?.status === 200) {
        handleApplicantLogin(
          response?.data?.data?.email,
          "",
          "Linkedin",
          response?.data?.data?.sub,
          response?.data?.data?.email_verified,
          () => {},
          () => {},
          navigate,
          currentLanguage,
        );
        setLoading(false);
      } else if (response?.status === 400) {
        setLoading(false);
        navigate("/", { replace: true });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code && !loading && !user) {
      // Clear the previous timeout if the code changes rapidly
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      // Set a new timeout to trigger the API call after 500ms
      debounceTimeout.current = setTimeout(() => {
        fetchUserProfile(code);
      }, 500); // Adjust the delay (in ms) as per your requirement
    }
  }, [code]);

  return (
    <div>
      {loading && (
        <div className="loadingSec">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default LinkedInCallback;
