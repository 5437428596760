import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextEditor } from "ui-components";
import "./ExperienceRightSide.scss";
import { setSessionStorageItem } from "../../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { getSessionStorageItem } from "../../../../../utils/SessionStorageMethod/getSessionStorageItem";
import { calculateMinusAmount } from "../../../../../utils/Common/Constant";

export interface ExperienceRightSideProps {
  countManageInsFlag: boolean;
  countManageDesFlag: boolean;
  countTextInInstruction: number;
  countTextInDecription: number;
  textEditorLimit: number;
  setCountManageInsFlag: any;
  setCountTextInInstruction: any;
  setCountManageDesFlag: any;
  setCountTextInDecription: any;
  editorDescription: string;
  setEditorDescription: any;
  editorInstruction: string;
  setEditorInstruction: any;
}

const ExperienceRightSide: React.FunctionComponent<
  ExperienceRightSideProps
> = ({
  textEditorLimit,
  countManageInsFlag,
  countTextInInstruction,
  countTextInDecription,
  setCountManageInsFlag,
  setCountTextInInstruction,
  setCountManageDesFlag,
  setCountTextInDecription,
  countManageDesFlag,
  editorDescription,
  setEditorDescription,
  editorInstruction,
  setEditorInstruction,
}) => {
  useEffect(() => {
    setEditorInstruction(getSessionStorageItem("instruction"));
  }, [getSessionStorageItem("instruction"), editorInstruction]);
  useEffect(() => {
    setEditorDescription(getSessionStorageItem("description"));
  }, [getSessionStorageItem("description"), editorDescription]);
  const { t } = useTranslation();
  const handleDescription = (
    content: any,
    _delta: any,
    _source: any,
    editor: any,
  ) => {
    const currentLength = editor.getLength();
    setCountTextInDecription(currentLength);
    setCountManageDesFlag(true);
    setEditorDescription(content);
    setSessionStorageItem("description", content);
    setSessionStorageItem("countTextInDecription", currentLength);
  };

  const handleInstruction = (
    content: any,
    _delta: any,
    _source: any,
    editor: any,
  ) => {
    const currentLength = editor.getLength();
    setCountTextInInstruction(currentLength);
    setCountManageInsFlag(true);
    setEditorInstruction(content);
    setSessionStorageItem("instruction", content);
    setSessionStorageItem("countTextInInstruction", currentLength);
  };

  return (
    <div className="rangeTextSec row">
      <div className="col-md-6">
        <h6 className="quillHeading">{t("GENERAL.DESCRIPTION")}</h6>
        <div className="settingTextArea">
          <TextEditor
            value={editorDescription}
            placeholder={""}
            onChange={handleDescription}
          />
        </div>
        <div className="textEditorMsg">
          <p className="errorMessage">
            {countTextInDecription > textEditorLimit && (
              <>
                {t("GENERAL.MUST_BE_LESS_THAN")} {textEditorLimit - 1}{" "}
                {t("GENERAL.CHARACTERS")}
              </>
            )}
          </p>
          {(countManageDesFlag ? 1 : textEditorLimit) !=
            countTextInDecription && (
            <p className="limitMsg">
              {calculateMinusAmount(countTextInDecription, textEditorLimit)}{" "}
              {t("CREATE_ASSESSMENT.TEXT_EDITOR_LIMIT")}
            </p>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <h6 className="quillHeading">{t("GENERAL.INSTRUCTIONS")}</h6>
        <div className="settingTextArea">
          <TextEditor
            value={editorInstruction}
            onChange={handleInstruction}
            placeholder={t("")}
          />
        </div>
        <div className="textEditorMsg">
          <p className="errorMessage">
            {countTextInInstruction > textEditorLimit && (
              <>
                {t("GENERAL.MUST_BE_LESS_THAN")} {textEditorLimit - 1}{" "}
                {t("GENERAL.CHARACTERS")}
              </>
            )}
          </p>
          {(countManageInsFlag ? 1 : textEditorLimit) !=
            countTextInInstruction && (
            <p className="limitMsg">
              {calculateMinusAmount(countTextInInstruction, textEditorLimit)}{" "}
              {t("CREATE_ASSESSMENT.TEXT_EDITOR_LIMIT")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExperienceRightSide;
