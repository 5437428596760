import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "./FileUpload.scss"; // Importing styles
import { MultiFileUploader } from "../../../../../../utils/Common/MediaUploader";
import { generateUUID } from "../../../../../../utils/Common/Constant";
import FileUploadIcon from "../../../../../../utils/Images/FileUploadIcon";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { showToast } from "ui-components";
const MAX_FILE_COUNT = 25; // Maximum number of files allowed
type FileUploadProps = {
  onFilesUploaded: (files: File[], fileNames: any) => void;
  fileUploadData: any;
  setFileProgressList: any;
  fileProgressList: any;
  children?: ReactNode;
  isChildrenUsed?: boolean;
};
const MAX_FILE_SIZE_MB = 5; // Maximum file size in MB
const FileUpload: React.FC<FileUploadProps> = ({
  onFilesUploaded,
  fileUploadData,
  setFileProgressList,
  fileProgressList,
  isChildrenUsed,
  children,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    setFileProgressList([]);
    setFiles([]);
  }, [fileUploadData]);

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: any[]) => {
      if (fileRejections.length > 0) {
        let errorMessages = "";
        for (const rejection of fileRejections) {
          for (const e of rejection.errors) {
            if (e.code === "file-invalid-type") {
              errorMessages = t("RESUME.ASSESSMENT_FILE_TYPE");
              break;
            } else {
              errorMessages += `${e.message}; `;
            }
          }
          if (errorMessages) break;
        }
        if (errorMessages) {
          if (isChildrenUsed) {
            showToast(errorMessages, "error");
          }
          setError(errorMessages);
          return;
        }
      }

      const totalFiles = files.length + acceptedFiles.length;
      if (totalFiles > MAX_FILE_COUNT) {
        if (isChildrenUsed) {
          showToast(t("RESUME.ASSESSMENT_MAX_FILE_COUNT"), "error");
        }
        setError(t("RESUME.ASSESSMENT_MAX_FILE_COUNT"));
        return;
      }

      setError(null);

      const newFileProgressList = acceptedFiles.map((file, index) => ({
        id: `${Date.now()}-${index}`, // Unique ID for tracking
        file,
        progress: 0,
        status: "uploading",
      }));

      setFileProgressList((prev: any) => [...prev, ...newFileProgressList]);

      const uploadedUrls: any[] = Array(acceptedFiles.length).fill(null); // Maintain order by index

      const uploadFile = async (file: File, index: number) => {
        try {
          const updateProgress = (percentage: number) => {
            setFileProgressList((prevList: any) =>
              prevList.map((item: any) =>
                item.file === file ? { ...item, progress: percentage } : item,
              ),
            );
          };

          for (let i = 0; i <= 100; i += 10) {
            await new Promise((resolve) => setTimeout(resolve, 100));
            updateProgress(i);
          }

          const imageData = await MultiFileUploader(
            file,
            `multi/file/${generateUUID()}`,
          );

          if (imageData?.ImageUrl) {
            uploadedUrls[index] = imageData; // Store in correct index

            setFileProgressList((prevList: any) =>
              prevList.map((item: any) =>
                item.file === file
                  ? { ...item, progress: 100, status: "completed" }
                  : item,
              ),
            );
          }
        } catch (error) {
          console.error("Upload failed for file:", file.name, error);
          setFileProgressList((prevList: any) =>
            prevList.map((item: any) =>
              item.file === file ? { ...item, status: "failed" } : item,
            ),
          );
        }
      };

      await Promise.all(
        acceptedFiles.map((file, index) => uploadFile(file, index)),
      );

      setFiles((prevFiles) => [
        ...prevFiles,
        ...uploadedUrls.filter((url) => url),
      ]);
      onFilesUploaded(
        [...files, ...uploadedUrls.filter((url) => url)],
        acceptedFiles,
      );
    },
    [onFilesUploaded, fileProgressList, files, fileUploadData],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc", ".docx"],
    },
    maxSize: MAX_FILE_SIZE_MB * 1024 * 1024,
  });

  return (
    <div className="uploadContainer">
      {!isChildrenUsed ? (
        <div
          {...getRootProps({
            className: `dropZone ${isDragActive ? "dragging" : ""}`,
          })}
        >
          <input {...getInputProps()} />
          <label className="uploadLabel">
            <div className="icon">
              <FileUploadIcon />
            </div>
            <p className="text">
              {t("RESUME.ASSESSMENT_DRAG_AND_DROP")}
              <br />
              {t("RESUME.ASSESSMENT_OR")}
              <br />
              <span>{t("RESUME.ASSESSMENT_CLICK_TO_SELECT")}</span>
            </p>
            <p className="supportFiles">
              {t("RESUME.ASSESSMENT_SUPPORT_FILE_TYPES")} (max{" "}
              {MAX_FILE_SIZE_MB} {t("RESUME.ASSESSMENT_MB")}) <br />
              {t("RESUME.ASSESSMENT_TYPES_PDF_DOC_DOCX")}
            </p>
            {error && <p className="errorMessage">{error}</p>}
          </label>
          <div className="dropHere">
            <label className="uploadLabel-2">
              <div className="icon">
                <FileUploadIcon />
              </div>
              <h1>{t("RESUME.ASSESSMENT_DROP_HERE")}</h1>
              <p className="supportFiles">
                {t("RESUME.ASSESSMENT_SUPPORT_FILE_TYPES")} (max{" "}
                {MAX_FILE_SIZE_MB} {t("RESUME.ASSESSMENT_MB")}) <br />
                {t("RESUME.ASSESSMENT_TYPES_PDF_DOC_DOCX")}
              </p>
              {error && <p className="errorMessage">{error}</p>}
            </label>
          </div>
        </div>
      ) : (
        <div>
          {children && (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {children}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
