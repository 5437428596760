import { useState, useEffect } from "react";

// Custom Hook for Debouncing
const useDebounce = (value: string, delay: number): string => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

const GOOGLE_PLACES_API_URL =
  "https://places.googleapis.com/v1/places:autocomplete";
const GOOGLE_PLACE_DETAILS_URL = "https://places.googleapis.com/v1/places/";
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""; // Replace with your actual API key
const usePlacesAutocomplete = () => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedPlace, setSelectedPlace] = useState<any | null>(null); // To store selected place details
  const debouncedInputValue = useDebounce(inputValue, 300);
  // Fetch autocomplete suggestions
  useEffect(() => {
    if (!debouncedInputValue?.trim()) {
      setSuggestions([]);
      return;
    }

    const fetchSuggestions = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(GOOGLE_PLACES_API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Goog-Api-Key": GOOGLE_API_KEY,
          },
          body: JSON.stringify({
            input: debouncedInputValue,
          }),
        });

        if (!response.ok) {
          throw new Error(
            `Error: ${response.status} - Failed to fetch autocomplete suggestions`,
          );
        }
        const data = await response.json();
        setSuggestions(data.suggestions || []);
      } catch (err: any) {
        setError(err.message || "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchSuggestions();
  }, [debouncedInputValue]);

  // Fetch place details (lat, lng) using place_id
  const fetchPlaceDetails = async (placeName: string) => {
    try {
      const response = await fetch(
        `${GOOGLE_PLACE_DETAILS_URL}${placeName}?fields=addressComponents,formattedAddress,location&key=${GOOGLE_API_KEY}`,
      );
      const data = await response.json();
      setSelectedPlace(data); // Store the selected place details
    } catch (err: any) {
      setError(err.message || "Failed to fetch place details");
    }
  };
  const handleSelectPlace = (place: any) => {
    setInputValue(place?.placePrediction?.text?.text); // Set the input value to the selected place description
    fetchPlaceDetails(place?.placePrediction?.placeId); // Fetch place details using place_id
  };

  return {
    inputValue,
    setInputValue,
    suggestions,
    loading,
    error,
    selectedPlace,
    handleSelectPlace,
    setSuggestions,
    setSelectedPlace,
  };
};

export default usePlacesAutocomplete;
