import { useTranslation } from "react-i18next";
import "./ReviewDescription.scss";
import { getSessionStorageItem } from "../../../../../utils/SessionStorageMethod/getSessionStorageItem";

const ReviewDescription: React.FunctionComponent = () => {
  const { t } = useTranslation();
  // const companyAddress = localStorage.getItem("companyAddress") || "";
  const showFields = (key: string) => {
    const fieldValue = getSessionStorageItem(key);
    return fieldValue !== "<p><br></p>" && fieldValue !== null
      ? fieldValue
      : false;
  };
  return (
    <>
      <div className="descriptionSection">
        {getSessionStorageItem("advanceSettingData") !== null && (
          <>
            {/* // Comment for future use */}
            {/* <div className="introductionSec">
              <h6>{t("CREATE_ASSESSMENT.LOCATION")}</h6>
              {getSessionStorageItem("advanceSettingData")?.location ||
                companyAddress ? (
                <p className="questionSpan wordBreak">
                  {getSessionStorageItem("advanceSettingData")?.location ||
                    companyAddress}
                </p>
              ) : (
                <div className="notAvailable">
                  {t("CREATE_ASSESSMENT.INSTRUCTION_NOT_AVAILABLE")}
                </div>
              )}
            </div> */}
            {/* <div className="introductionSec">
              <h6>{t("GENERAL.ENGAGEMENT")}</h6>
              {getSessionStorageItem("advanceSettingData")?.jobType ? (
                <p className="questionSpan wordBreak">
                  {getSessionStorageItem("advanceSettingData")?.jobType}
                </p>
              ) : (
                <div className="notAvailable">
                  {t("CREATE_ASSESSMENT.INSTRUCTION_NOT_AVAILABLE")}
                </div>
              )}
            </div> */}
            {/* <div className="introductionSec">
              <h6>{t("GENERAL.JOB_TYPE")}</h6>
              {getSessionStorageItem("advanceSettingData")?.engagementType ? (
                <p className="questionSpan wordBreak">
                  {getSessionStorageItem("advanceSettingData")?.engagementType}
                </p>
              ) : (
                <div className="notAvailable">
                  {t("CREATE_ASSESSMENT.INSTRUCTION_NOT_AVAILABLE")}
                </div>
              )}
            </div> */}
          </>
        )}
        <div
          className={
            getSessionStorageItem("advanceSettingData") !== null
              ? "introductionSec"
              : ""
          }
        >
          <h6>{t("GENERAL.DESCRIPTION")}</h6>
          {showFields("description") ? (
            <p
              className="questionSpan wordBreak"
              dangerouslySetInnerHTML={{
                __html: `${showFields("description")}`,
              }}
            />
          ) : (
            <div className="notAvailable">
              {t("CREATE_ASSESSMENT.DESCRIPTION_NOT_AVAILABLE")}
            </div>
          )}
        </div>
        <div className="introductionSec">
          <h6>{t("GENERAL.INSTRUCTIONS")}</h6>
          {showFields("instruction") ? (
            <p
              className="questionSpan wordBreak"
              dangerouslySetInnerHTML={{
                __html: `${showFields("instruction")}`,
              }}
            />
          ) : (
            <div className="notAvailable">
              {t("CREATE_ASSESSMENT.INSTRUCTION_NOT_AVAILABLE")}
            </div>
          )}
        </div>
        {/* <div className="horizontalLine"></div> */}
      </div>
    </>
  );
};

export default ReviewDescription;
